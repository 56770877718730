import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core'
import Drawer from 'src/components/ui/Drawer'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { FormPacote, IPacoteForm } from '../pacote/formPacote'

type Props = {
  etapa: number
  pacote: IPacoteForm
  open: boolean
  handleOnClose: () => void
  handleSalvarAjuste?: (dados: IPacoteForm) => void
  // handleSubmit?: (dados) => void
}

function DrawerAjustarPacote(props: Props) {
  const theme = useTheme()
  const { perfilAtual } = useAuth()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  // useEffect(() => {
  //   if (!props.id) {
  //     return
  //   }
  //   setStatusSelecionado(props.status)
  //   popularStatus()
  // }, [props.open])

  // async function handleSalvar() {
  //   try {
  //     setLoadingSalvar(true)
  //     var command: AlterarStatusSolicitacaoOcamentoCommand = {
  //       id: props.id,
  //       statusId: statusSelecionado.id
  //     }
  //     await alterarStatusSolicitacaoOrcamento(command)
  //     setLoadingSalvar(false)
  //     props.handleOnClose()
  //   } catch (e) {
  //     setLoadingSalvar(false)
  //     showNotificationErrorAPI(e)
  //     props.handleOnClose()
  //   }
  // }
  // console.log('DrawerAjustarPacote', props.pacote)
  return (
    <Drawer
      anchor="right"
      open={props.open}
      noPadding={ehXS}
      titulo="Ajustar pacote para o paciente"
      handleOnClose={props.handleOnClose}
    >
      <Box mt={3}>
        <FormPacote
          pacote={props.pacote}
          etapaAjuste={props.etapa}
          esconderHonorario={
            perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
            perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
          }
          handleSalvarAjuste={props.handleSalvarAjuste}
          // handleSubmit={props.handleSubmit}
        />
      </Box>
      {/* <Button onClick={props.handleSubmit}>teste</Button> */}
    </Drawer>
  )
}

export default DrawerAjustarPacote
