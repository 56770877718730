import {
  Badge,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import {
  useTheme,
  withStyles
} from '@material-ui/core/styles'
import React, { useState } from 'react'
import { FcInfo } from 'react-icons/fc'
import { GiLightBulb } from 'react-icons/gi'
import { formatarDiferencaData } from 'src/core/formatacoes/formatarData'
import useAuth from 'src/hooks/useAuth'
import { INotificacao } from 'src/signalR/Notificacao/notificacao'
import UBox from '../ui/Ubox'
import DrawerNotificacoes from './drawer'
import ResumoNotificacoes, { StyledMenuItem } from './resumo'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))
const StyledBadge = withStyles(theme => ({
  badge: {
    right: 3,
    top: 17,
    border: `3px solid ${theme.palette.background.paper}`,
    background: theme.palette.warning.main,
    padding: '4px',
    borderRadius: '50%'
  }
}))(Badge)
export interface IResumoNotificacao {
  qtdTodas: number
  qtdNovas: number
  qtdVisualizadas: number
  todas: INotificacao[]
  visualizadas: INotificacao[]
  novas: INotificacao[]
}
export default function MenuNotificacao() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const theme = useTheme()
  const ehMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { resumoNotificacoes } = useAuth()

  const [mostrarDrawer, setMostrarDrawer] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMostrarDrawer(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMostrarDrawer(false)
  }

  return (
    <div>
      <Tooltip
        // title={
        //   solicitacao.qtdOcorrenciasOperacional > 0
        //     ? `${solicitacao.qtdOcorrenciasOperacional} ocorrências`
        //     : 'Nenhuma ocorrência'
        // }
        title="Notificações"
      >
        <IconButton
          style={{ fontSize: '22px', marginLeft: '12px' }}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {resumoNotificacoes?.qtdNovas ? (
            <StyledBadge color="secondary" variant="dot">
              {/* <FaRegLightbulb /> */}
              <GiLightBulb style={{ fontSize: '24px' }} />
              {/* <BsBell /> */}
              {/* style={{ color: '#3bd1b6' }} */}
            </StyledBadge>
          ) : (
            <GiLightBulb style={{ fontSize: '24px' }} />
          )}
        </IconButton>
      </Tooltip>
      {ehMobile ? (
        mostrarDrawer && <DrawerNotificacoes open handleOnClose={handleClose} />
      ) : (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        // style={{ width: '460px' }}
        >
          {mostrarDrawer && <ResumoNotificacoes />}
        </StyledMenu>
      )}
    </div>
  )
}

export interface IItemMenuProps {
  notificacao: INotificacao
  showBackground?: boolean
  ehMobile?: boolean
  marcarComoLida: () => void
}

export function NotificacaoMenuItem({
  marcarComoLida,
  notificacao,
  ehMobile = false,
  showBackground = false
}: IItemMenuProps) {
  return (
    <StyledMenuItem
      onClick={marcarComoLida}
      style={{
        background:
          notificacao.visualizado && showBackground ? '#fff' : '#fff3b5',
        width: ehMobile ? '100%' : '420px'
      }}
    >
      <UBox display="flex">
        <UBox width={36}>
          <FcInfo />
        </UBox>
        <UBox>
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
              whiteSpace: 'normal'
            }}
          >
            {/* command: {JSON.stringify(notificacao)} */}

            {notificacao.titulo}
          </Typography>
          {!!notificacao.texto && (
            <Typography variant="body2" style={{ whiteSpace: 'normal' }}>
              {notificacao.texto}
            </Typography>
          )}
          <Typography
            variant="caption"
            style={{
              fontWeight:
                notificacao.visualizado && showBackground ? 'normal' : 600,
              color:
                notificacao.visualizado && showBackground ? '#777' : '#2196f3'
              //   color: n.visualizado ? '#777' : '#1cc3a5'
            }}
          >
            há {formatarDiferencaData(notificacao.criado)}
          </Typography>
        </UBox>
      </UBox>
    </StyledMenuItem>
  )
}
