import { Box, Grid } from '@material-ui/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { alterarDadosBancario, IDadosBancario, obterDadosBancario, salvarDadosBancario, SalvarDadosBancarioRequest } from 'src/api/dados-bancario'
import { salvarMembroEquipe, SalvarMembroEquipeCommand } from 'src/api/membroEquipe'
import Button from 'src/components/ui/Button'
import Ubox from 'src/components/ui/Ubox'
import tipoDadosBancario from 'src/core/consts/tipoDadosBancario'
import { showNotification, showNotificationErrorAPI } from 'src/core/helpers/notification'
import FormPF from './formFisica/formPF'
import FormPJ from './formJuridica/formPJ'
import { IFormsCadastro } from './interface'

type Props = {
  dadosBancarios?: (data: IFormsCadastro) => void
  ignorarSubmit?: boolean
  handleNext?: () => void
  handleBack?: () => void
  activeTab?: number
  verBotao?: boolean
  dadosBancarioId?: string
  id?: string
  tipoConta?: number
  handleAfterSubmit?: (atualizou, id?, tipoConta?) => void
  ehMembroEquipe?: boolean
}

export default function CadastroDadosBancarios({
  dadosBancarios,
  handleNext,
  handleBack,
  ignorarSubmit,
  verBotao,
  dadosBancarioId,
  id,
  tipoConta,
  handleAfterSubmit,
  ehMembroEquipe
}: Props) {
  const [selectPf, setSelectPf] = useState(false)
  const [selectPj, setSelectPj] = useState(false)

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [editarDadosBancarios, setEditarDadosBancarios] = useState<IDadosBancario>()

  const handleActivePF = () => {
    setSelectPf(true)
    setSelectPj(false)
  }
  const handleActivePJ = () => {
    setSelectPj(true)
    setSelectPf(false)
  }
  const handleCloseModal = () => {
    setSelectPf(false)
    setSelectPj(false)
  }

  const handleNextWrapper = () => {
    handleNext()
  }

  const handleBackWrapper = () => {
    handleBack()
  }

  function handleSubmit(data: IFormsCadastro) {
    if (!ignorarSubmit) {
      onSubmit(data)
    } else { dadosBancarios(data) }
  }

  const onSubmit = async (data: IFormsCadastro) => {
    try {
      var dataNascimento = moment(
        data.birthdate,
        'DD/MM/YYYY'
      ).toISOString()

      const payloadDadosBancarios = {
        nomeBanco: data.nomeBanco,
        agencia: data.agencia,
        digitoAgencia: data.digitoAgencia,
        numeroConta: data.numeroConta,
        digitoConta: data.digitoConta,
        codigoBanco: data.codigoBanco,
        tipoConta: data.tipoConta,
        cpfCnpj: data.cpfCnpj,
        ...(!!data.companyName && {
          companyName: data.companyName,
          tradingName: data.nomeBanco,
          companyEmail: data.companyEmail,
          companyDocument: data.companyDocument,
          annualRevenue: data.annualRevenue,
          companyPhoneNumbers: data.companyPhoneNumbers
        }),
        name: !!data.companyName ? data.name : data.nomeBanco,
        email: data.email,
        document: data.document,
        birthdate: dataNascimento,
        monthlyIncome: data.monthlyIncome,
        professionalOccupation: data.professionalOccupation,
        phoneNumbers: data.phoneNumbers,
        selfDeclaredLegalRepresentative: data.selfDeclaredLegalRepresentative,
        address: {
          cep: data.cep,
          city: data.city,
          complementary: data.complementary,
          neighborhood: data.neighborhood,
          streetNumber: data.streetNumber,
          referencePoint: data.referencePoint,
          state: data.state,
          street: data.street
        },
        ...(!!data.companyCep && {
          mainAddress: {
            cep: data.companyCep,
            city: data.companyCity,
            complementary: data.companyComplementary,
            neighborhood: data.companyNeighborhood,
            streetNumber: data.companyStreetNumber,
            referencePoint: data.companyReferencePoint,
            state: data.companyState,
            street: data.companyStreet
          }
        })
      }
      const request = payloadDadosBancarios as SalvarDadosBancarioRequest
      const requestMenbro = payloadDadosBancarios as SalvarMembroEquipeCommand
      if (tipoConta === tipoDadosBancario.Medico) {
        request.medicoId = id
      }
      if (tipoConta === tipoDadosBancario.Parceiro) {
        request.parceiroId = id
      }
      setSubmitting(true)
      if (dadosBancarioId) {
        request.id = dadosBancarioId
        if (ehMembroEquipe) {
          await salvarMembroEquipe(requestMenbro)
        } else {
          await alterarDadosBancario(request)
        }
      } else {
        if (ehMembroEquipe) {
          await salvarMembroEquipe(requestMenbro)
        } else {
          await salvarDadosBancario(request)
        }
      }
      showNotification(
        'Parabéns',
        'Dados bancários configurados com sucesso.',
        'success',
        true
      )
      setTimeout(() => handleAfterSubmit(true, id, tipoConta), 300)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      showNotificationErrorAPI(error)
    }
  }

  async function obterDadosBancarioPorId() {
    try {
      if (dadosBancarioId) {
        setLoading(true)
        const { data } = await obterDadosBancario(dadosBancarioId)
        setEditarDadosBancarios(data)
        setLoading(false)
      }
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  useEffect(() => {
    if (!loading) obterDadosBancarioPorId()
  }, [])

  useEffect(() => {
    if (editarDadosBancarios) {
      if (editarDadosBancarios.pessoaJuridica === true) {
        setSelectPj(true)
        setSelectPf(false)
      } else {
        setSelectPf(true)
        setSelectPj(false)
      }
    }
  }, [tipoConta, loading])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '25px 0px 25px 0px' }}>
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
          <span>Selecione a opção desejada</span>
          <div style={{ display: 'flex', gap: '12px' }}>

            <Button variant="outlined" onClick={handleActivePF} color={selectPf ? 'secondary' : 'primary'} disabled={!!editarDadosBancarios}>Pessoa Fisica</Button>
            <Button variant="outlined" onClick={handleActivePJ} color={selectPj ? 'secondary' : 'primary'} disabled={!!editarDadosBancarios}>Pessoa Juridica</Button>
          </div>
        </Box>
      </div>
      <Ubox render={selectPf === true}>
        {(!dadosBancarioId || (!!dadosBancarioId && !!editarDadosBancarios)) && (
          <FormPF
            open={selectPf}
            onClose={handleCloseModal}
            dadosBancariosUsuario={handleSubmit}
            ignorarSubmit={ignorarSubmit}
            showNext={handleNextWrapper}
            showBack={handleBackWrapper}
            editarDadosBancarios={editarDadosBancarios}
          />
        )}

      </Ubox>
      <Ubox render={selectPj === true}>
        {(!dadosBancarioId || (!!dadosBancarioId && !!editarDadosBancarios)) && (
          <FormPJ
            open={selectPj}
            onClose={handleCloseModal}
            dadosBancariosUsuario={handleSubmit}
            ignorarSubmit={ignorarSubmit}
            showNext={handleNextWrapper}
            showBack={handleBackWrapper}
            editarDadosBancarios={editarDadosBancarios}
          />
        )}
      </Ubox>
      {verBotao && (
        <Ubox render={!selectPf && !selectPj} >
          <Grid item xs={12} sm={2}>
            <Button type="button" fullWidth variant="contained" color="default" onClick={handleBackWrapper}>
              Voltar
            </Button>
          </Grid>
        </Ubox>
      )}
    </>
  )
}
