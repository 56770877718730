import { AxiosResponse } from 'axios'
import api from 'src/services/api'

export interface DesabilitarResponse {
  pacoteInteligenteId: string
  cpfMedico: string
  desabilitado: boolean
}

export interface DesabilitarCommand {
  pacoteInteligenteId: string
  cpfMedico: string
  desabilitado: boolean
}

export const desabilitarPacote: (
  command: DesabilitarCommand,
) => Promise<AxiosResponse<DesabilitarResponse>> = command => {
  try {
    return api.post('/pacoteInteligenteMedicoDesabilitado', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
