import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField as TextFieldMU,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { ObterCep } from 'src/api/cep/cep'
import {
  adicionarLocalAtendimento,
  existeCNPJ,
  obterEspecialidades,
  obterLocaisAtendimento,
  obterSetores
} from 'src/api/dominio'
import { buscarParceiroPorId, salvarParceiro } from 'src/api/parceiro'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import Button from 'src/components/ui/Button'
import { NumberFormatPorcentagem } from 'src/components/ui/PorcentagemField'
import { TextField } from 'src/components/ui/TextField'
import UBox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import estados from 'src/core/consts/estados'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import {
  onlyNumber,
  verificarCNPJ,
  verificarCPF
} from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { useQuery } from 'src/hooks/useQuery'
import {
  IParceiro,
  IParceiroFuncionario,
  ObjetoDescricaoGenerico
} from 'src/types'
import Swal from 'sweetalert2'
import Resumo from './resumo'

const useStyles = makeStyles(theme => ({
  container: {
    // padding: '30px 20px 40px ',
    // margin: '20px auto',
    // borderRadius: '8px'
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formControlUf: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  margenButton: {
    marginLeft: theme.spacing(2)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

export const FormParceiros = () => {
  const query = useQuery()
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [contatos, setContatos] = useState<IParceiroFuncionario[]>([])
  const [addContato, setAddContato] = useState(false)

  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false)

  const [especialidades, setEspecialidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [setores, setSetores] = useState<ObjetoDescricaoGenerico[]>([])
  const [loadingEspecialidades, setLoadingEspecialidades] = useState<boolean>(
    true
  )
  const [loadingCep, setLoadingCep] = useState<boolean>(false)

  const [listaHospitais, setListaHospitais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingHospitais, setLoadingHospitais] = useState<boolean>(true)
  const [parceiro, setParceiro] = useState<IParceiro>()
  const [edicao, setEdicao] = useState(false)
  const [cnpjValidado, setCnpjValidado] = useState(undefined)

  //#region Modal
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)
  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }

  //#endregion
  const schema = yup.object().shape({
    id: yup.string(),
    razaoSocial: yup.string().required().label('Razão Social'),
    localAtendimento: yup
      .object()
      .nullable()
      .when('tipo', {
        is: tipo => tipo === SynTipoParceiro.HOSPITAL_CLINICA.id,
        then: yup.object().nullable().required().label('Local de atendimento')
      }),
    uf: yup.string().nullable().required().label('UF'),
    cep: yup
      .string()
      .nullable()
      .when('tipo', {
        is: tipo => tipo === SynTipoParceiro.OPME.id,
        then: yup
          .string()
          .required()
          .test('cep-teste', 'CEP inválido', value => {
            if (!value) return false

            let cepNumeros = value.replace(/\D/g, '')
            return cepNumeros.length === 8
          })
          .label('CEP')
      }),
    observacao: yup.string().label('Observação'),
    logradouro: yup
      .string()
      .nullable()
      .when('tipo', {
        is: tipo => tipo === SynTipoParceiro.OPME.id,
        then: yup.string().nullable().required().label('Logradouro')
      }),
    cidade: yup
      .string()
      .nullable()
      .when('tipo', {
        is: tipo => tipo === SynTipoParceiro.OPME.id,
        then: yup.string().nullable().required().label('Cidade')
      }),
    bairro: yup
      .string()
      .nullable()
      .when('tipo', {
        is: tipo => tipo === SynTipoParceiro.OPME.id,
        then: yup.string().nullable().required().label('Bairro')
      }),
    numero: yup
      .string()
      .nullable()
      .when('tipo', {
        is: tipo => tipo === SynTipoParceiro.OPME.id,
        then: yup.string().nullable().required().label('Numero')
      }),
    taxaOPME: yup.string().when('tipo', {
      is: tipo => tipo === SynTipoParceiro.HOSPITAL_CLINICA.id,
      then: yup.string().required().label('Taxa OPME')
    }),
    permiteEquipeAnestesiaExterna: yup.bool().nullable(),
    nomeFantasia: yup.string().required().label('Nome Fantasia'),
    cnpj: yup
      .string()
      .test('cnpj-teste', 'CNPJ inválido', value => {
        if (!value) return false
        return verificarCNPJ(value)
      })
      .test('cnpj-existe', 'CNPJ já existe', async (value, context) => {
        if (!value || onlyNumber(value).length < 14) return false
        //Se for edição não valida
        if (!!parceiro?.id) return true

        if (cnpjValidado === onlyNumber(value)) return true

        try {
          let response = await existeCNPJ(
            onlyNumber(value),
            context.parent['id']
          )
          setCnpjValidado(onlyNumber(value))
          return !response.data
        } catch (erro) {
          showNotificationErrorAPI(erro)
        }
      })
      .required()
      .label('CNPJ'),
    tipo: yup.string().required().label('Tipo'),
    telefoneNF: yup
      .string()
      .nullable()
      .test('telefone-teste', 'Telefone informado é inválido', value => {
        if (!value || value.length === 0) return true

        var numeroTelefone = onlyNumber(value)
        return numeroTelefone.length >= 10
      })
      .label('Telefone'),
    emailNF: yup.string().nullable().email().label('E-mail')
  })

  const contactSchema = yup.object().shape({
    id: yup.string().nullable(),
    nome: yup.string().required().label('Nome do responsável'),
    celular: yup.string().required().min(11).label('Telefone'),
    email: yup.string().email().required().label('E-mail'),
    cpf: yup
      .string()
      .test('cpf-teste', 'CPF inválido', value => {
        if (!value) return false

        return verificarCPF(onlyNumber(value))
      })
      // .test('cpf-existe', 'CPF já cadastrado', async (value, context) => {
      //   if (contactWatch.id) return true
      //   const existeLocalmente = contatos.find(c => c.cpf === value)
      //   let existeServer = true
      //   try {
      //     let response = await existeCPF(
      //       onlyNumber(value),
      //       context.parent['id']
      //     )
      //     existeServer = response.data
      //   } catch (erro) {
      //     showNotificationErrorAPI(erro)
      //   }

      //   if (existeServer) return false
      //   return edicao ? true : !existeLocalmente
      // })
      .label('CPF')
  })

  async function carregarEspecialidades() {
    return obterEspecialidades()
      .then(response => setEspecialidades(response.data))
      .finally(() => {
        setLoadingEspecialidades(false)
      })
  }

  async function carregarSetores() {
    return obterSetores()
      .then(response => setSetores(response.data))
      .finally(() => {
        setLoadingEspecialidades(false)
      })
  }

  //#region Formulário
  const defaultValuesParceiro: IParceiro = {
    cnpj: '',
    nomeFantasia: '',
    razaoSocial: '',
    tipo: SynTipoParceiro.OPME.id,
    funcionarios: [],
    localAtendimento: null,
    taxaOPME: '',
    uf: '',
    logradouro: '',
    cep: '',
    cidade: '',
    bairro: '',
    numero: '',
    complemento: '',
    permiteEquipeAnestesiaExterna: true,
    observacao: '',
    perfilAtual: ''
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit,
    trigger
  } = useForm<IParceiro>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValuesParceiro
  })
  const { user, perfilAtual } = useAuth()

  const command = watch()

  const defaultValuesContato: IParceiroFuncionario = {
    nome: '',
    celular: '',
    email: '',
    especialidades: [],
    cpf: ''
  }

  const contactForm = useForm<IParceiroFuncionario>({
    mode: 'onChange',
    resolver: yupResolver(contactSchema),
    defaultValues: defaultValuesContato
  })

  const contactWatch = contactForm.watch()
  const contactControl = contactForm.control

  useEffect(() => {
    register('localAtendimento')
    register('id')
    register('tipo')
    register('taxaOPME')
    register('permiteEquipeAnestesiaExterna')
    register('uf')
    contactForm.register('especialidades')
    contactForm.register('id')
  }, [register, contactForm.register])

  useEffect(() => {
    mapearParceiro(parceiro, true)
  }, [command.tipo])

  useEffect(() => {
    const id = query.get('id')
    carregarEspecialidades()
    carregarSetores()
    carregarHospitais()
    setLoading(true)

    if (id) {
      buscarParceiroPorId(id)
        .then(response => {
          const parceiro = response.data
          setParceiro(parceiro)

          mapearParceiro(parceiro)
          setLoading(false)
          setContatos(parceiro.funcionarios)
        })
        .catch(err => {
          showNotificationErrorAPI(err)
          history.goBack()
        })
    } else {
      setAddContato(true)
      setLoading(false)
    }
  }, [])

  function mapearParceiro(parceiro, ignorarTipo = false) {
    for (const key in parceiro) {
      if (
        ['tipo', 'nomeFantasia', 'razaoSocial', 'funcionarios'].includes(key) &&
        ignorarTipo
      ) {
        continue
      }

      if (key === 'permiteEquipeAnestesiaExterna') {
        setValue(key, !parceiro[key] ? false : parceiro[key], {
          shouldValidate: false
        })
      } else if (key === 'taxaOPME') {
        setValue(key, !parceiro[key] ? '0' : parceiro[key].toString(), {
          shouldValidate: false
        })
      } else {
        setValue(key, parceiro[key], { shouldValidate: false })
      }
    }
  }
  // VINCULAR MUDANÇA DE VALOR
  const handleRadios = e => {
    setValue('tipo', e.target.value)
  }

  const handleNovoValor = (item: any) => {
    carregarHospitais()
    setValue('localAtendimento', item, {
      shouldValidate: true
    })
  }
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const carregarHospitais = async () => {
    return obterLocaisAtendimento()
      .then(response => setListaHospitais(response.data))
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    contactForm.setValue(name, objetos, { shouldValidate: true })
  }

  async function handleAddContact() {
    const result = await contactForm.trigger()

    if (!result) {
      return
    }
    var especialidades = []
    especialidades = especialidades.concat(contactWatch.especialidades)

    const contato: IParceiroFuncionario = {
      id: contactWatch.id || null,
      nome: contactWatch.nome,
      email: contactWatch.email,
      celular: contactWatch.celular,
      especialidades,
      cpf: contactWatch.cpf
    }

    if (edicao) {
      const newContatos = contatos.filter(c => c.id !== contato.id)
      setContatos([...newContatos, contato])
    } else {
      setContatos([...contatos, contato])
    }
    setAddContato(false)
    setEdicao(false)
    for (const key in contactWatch) {
      contactForm.setValue(key, '', {
        shouldValidate: false
      })
    }
    contactForm.setValue('especialidades', [], { shouldValidate: true })
  }

  function handleCloseAddContact() {
    setAddContato(false)
    setEdicao(false)
    for (const key in contactWatch) {
      contactForm.setValue(key, '', {
        shouldValidate: false
      })
    }
    contactForm.setValue('especialidades', [], { shouldValidate: true })
  }

  async function handleEditFuncionario(item: IParceiroFuncionario) {
    setEdicao(true)
    try {
      await setAddContato(true)
    } catch (err) {}
    for (const key in item) {
      let param = item[key]
      contactForm.setValue(key, param, {
        shouldValidate: false
      })
    }
  }

  function handleRemoveContact(cpf: string) {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja apagar este responsável?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Apagar'
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const contatosAtualizados = contatos.filter(
          contato => contato.cpf !== cpf
        )

        setContatos(contatosAtualizados)
      }
    })
  }

  useEffect(() => {
    if (!loading) {
      obterDadosCep(command.cep)
    }
  }, [command.cep])

  async function obterDadosCep(cep: string) {
    let cepNumeros = command?.cep?.replace(/\D/g, '')

    if (cepNumeros?.length === 8) {
      setLoadingCep(true)
      try {
        var response = await ObterCep(cepNumeros)
        var endereco = response.data
        setValue('logradouro', endereco.logradouro, { shouldValidate: true })
        setLoadingCep(false)
        setValue('cidade', endereco.localidade)
        setLoadingCep(false)
        setValue('bairro', endereco.bairro)
        setLoadingCep(false)
        setValue('uf', endereco.uf)
      } catch (error) {
        Swal.fire('erro', null, 'error')
      }
      setLoadingCep(false)
    }
  }

  const onSubmit = async (data: IParceiro) => {
    setLoadingSubmitButton(true)
    const {
      nomeFantasia,
      razaoSocial,
      cnpj,
      tipo,
      id,
      localAtendimento,
      taxaOPME,
      uf,
      logradouro,
      cep,
      bairro,
      cidade,
      complemento,
      numero,
      permiteEquipeAnestesiaExterna,
      observacao,
      telefoneNF,
      emailNF
    } = data
    const newCnpj = onlyNumber(cnpj)

    const funcionarios = contatos.map(contato => {
      const { celular, cpf } = contato

      const newCelular = onlyNumber(celular)
      const newCpf = onlyNumber(cpf)

      return { ...contato, celular: newCelular, cpf: newCpf }
    })
    const parceiro: IParceiro = {
      id: id || null,
      nomeFantasia,
      razaoSocial,
      cnpj: newCnpj,
      tipo,
      funcionarios,
      localAtendimento,
      taxaOPME,
      uf,
      logradouro,
      cep,
      bairro,
      cidade,
      complemento,
      numero,
      permiteEquipeAnestesiaExterna,
      observacao,
      perfilAtual: perfilAtual,
      telefoneNF,
      emailNF
    }

    salvarParceiro(parceiro)
      .then(() => {
        Swal.fire({
          title: 'Parabéns!',
          text: 'O parceiro foi salvo com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar'
        }).then(() => {
          history.replace(SynRoutes.listagemParceirosOperacional)
          setLoadingSubmitButton(false)
        })
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingSubmitButton(false)
      })
  }

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper
  const [activeStep, setActiveStep] = React.useState(0)
  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  const lastStep = 3
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const handleNext = async () => {
    const result = await trigger([
      'nomeFantasia',
      'cnpj',
      'razaoSocial',
      'tipo',
      'localAtendimento',
      'taxaOPME',
      'uf',
      'cep',
      'logradouro',
      'cidade',
      'bairro',
      'numero',
      'observacao',
      'telefoneNF',
      'emailNF'
    ])

    if (!result) {
      console.log(errors)
      return
    }

    if (activeStep === 1) {
      if (contatos.length === 0) {
        return
      }

      if (
        contatos.find(c => !c.especialidades || c.especialidades.length === 0)
      ) {
        return Swal.fire({
          icon: 'error',
          title: 'Preencha todos os campos do responsável',
          confirmButtonText: 'Ok'
        })
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    if (contatos.length === 0) {
      for (const key in contactWatch) {
        contactForm.setValue(key, '', {
          shouldValidate: false
        })
      }
      contactForm.setValue('especialidades', [], { shouldValidate: true })
    } else {
      handleCloseAddContact()
    }

    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  return (
    <>
      {loading && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}
      <Box
        component="main"
        hidden={loading}
        className={`fadeInTransition ${classes.container}`}
      >
        {/* {JSON.stringify(command)} */}
        <CssBaseline />
        {ehXS && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        <Grid container>
          {!ehXS && (
            <Grid item xs={12} sm={1}>
              <Stepper
                connector={<StepConnectorSyn />}
                activeStep={activeStep}
                style={{ margin: 0, padding: 0, background: 'none' }}
                orientation="vertical"
              >
                {getSteps(lastStep)}
              </Stepper>
            </Grid>
          )}

          <Grid item xs={12} sm={10}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
              noValidate={true}
            >
              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 0}
                hideBack
                showFinish={activeStep === 2}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={'Informe os dados do parceiro'}
              >
                <TextField
                  erro={errors}
                  name="nomeFantasia"
                  value={command.nomeFantasia}
                  placeholder="Digite o nome fantasia..."
                  label="Nome fantasia"
                  inputRef={register}
                  InputProps={{
                    autoComplete: 'off'
                  }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      erro={errors}
                      name="razaoSocial"
                      value={command.razaoSocial}
                      placeholder="Digite a razão social..."
                      label="Razão Social"
                      control={control}
                      inputRef={register}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      erro={errors}
                      name="cnpj"
                      value={command.cnpj}
                      placeholder="Digite o cnpj..."
                      label="CNPJ"
                      disabled={!!parceiro?.id}
                      mask="99.999.999/9999-99"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <UBox
                  render={
                    ![
                      SynPerfis.ADMIN_HOSPITAL,
                      SynPerfis.OPERACIONAL_HOSPITAL
                    ].includes(perfilAtual)
                  }
                >
                  <Box mt={1}>
                    <Typography variant="subtitle1" color="primary">
                      <b>Informe o tipo de parceiro</b>
                    </Typography>
                  </Box>
                  <RadioGroup
                    name="tipo"
                    onChange={handleRadios}
                    value={command.tipo}
                  >
                    <FormControlLabel
                      value={SynTipoParceiro.OPME.id}
                      control={<Radio color="primary" />}
                      label="OPME"
                    />
                    <FormControlLabel
                      value={SynTipoParceiro.HOSPITAL_CLINICA.id}
                      control={<Radio color="primary" />}
                      label="Hospital/Clínica"
                    />
                  </RadioGroup>
                </UBox>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <Typography variant="subtitle1" color="primary">
                        <b>Contato para notificação de notas fiscais</b>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      maskTelCel
                      style={{ marginTop: 0 }}
                      control={control}
                      erro={errors}
                      name="telefoneNF"
                      placeholder="Telefone..."
                      label="Telefone"
                      InputProps={{
                        autoComplete: 'new-password'
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ marginTop: 0 }}
                      erro={errors}
                      name="emailNF"
                      placeholder="E-mail..."
                      label="E-mail"
                      inputRef={register}
                      value={command.emailNF}
                      control={control}
                      InputProps={{
                        autoComplete: 'new-password'
                      }}
                    />
                  </Grid>
                </Grid>

                {command.tipo === SynTipoParceiro.HOSPITAL_CLINICA.id && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mt={1}>
                        <Typography variant="subtitle1" color="primary">
                          <b>Local de atendimento</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        erro={errors}
                        name="localAtendimento"
                        label="Local de atendimento"
                        loading={loadingHospitais}
                        value={command.localAtendimento}
                        options={listaHospitais}
                        getOptionLabel={option => `${option.descricao}`}
                        onChange={(event, newValue) => {
                          handleItemValue(
                            'localAtendimento',
                            newValue as ObjetoDescricaoGenerico
                          )
                        }}
                        showAddButton
                        addButtonProps={{
                          text: 'Adicionar novo'
                        }}
                        getOptionSelected={option =>
                          !!command.localAtendimento.id === option.id
                        }
                        onClickAddButton={texto =>
                          handleShowModal(texto, 'localAtendimento')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControlUf}
                        fullWidth
                      >
                        <Select
                          error={!!errors.uf}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label-uf"
                          placeholder="UF Local de atendimento"
                          name="uf"
                          inputRef={register}
                          onChange={(e, v) => {
                            handleItemValue('uf', e.target.value)
                          }}
                          value={command.uf || ''}
                          defaultValue={estados.find(
                            i => i.Initials === command.uf
                          )}
                        >
                          <MenuItem value="">
                            <em>UF</em>
                          </MenuItem>
                          {estados.map((estado, i) => (
                            <MenuItem key={i} value={estado.Initials}>
                              {estado.Initials}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText error={!!errors.uf}>
                          {errors.uf?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextFieldMU
                        error={!!errors.taxaOPME}
                        helperText={errors.taxaOPME?.message}
                        variant="outlined"
                        margin="normal"
                        label="Taxa OPME (%)"
                        name="taxaOPME"
                        placeholder="Taxa em %"
                        value={command.taxaOPME}
                        InputProps={{
                          autoComplete: 'off',
                          inputComponent: NumberFormatPorcentagem as any
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value
                          handleItemValue('taxaOPME', value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!command.permiteEquipeAnestesiaExterna}
                              onChange={(e, value) =>
                                handleItemValue(
                                  'permiteEquipeAnestesiaExterna',
                                  value
                                )
                              }
                              value={command.permiteEquipeAnestesiaExterna}
                            />
                          }
                          label="Permite equipe anestesia externa"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                )}

                {command.tipo === SynTipoParceiro.OPME.id && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mt={1}>
                        <Typography variant="subtitle1" color="primary">
                          <b>Endereço</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        erro={errors}
                        name="cep"
                        mask="99999-999"
                        value={command.cep}
                        placeholder="00000-000"
                        label="CEP"
                        control={control}
                        defaultValue={parceiro?.cep}
                        loading={loadingCep}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControlUf}
                        fullWidth
                        style={{ marginLeft: '0' }}
                      >
                        <Select
                          error={!!errors.uf}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label-uf"
                          placeholder="UF Local de atendimento"
                          name="uf"
                          inputRef={register}
                          onChange={(e, v) => {
                            handleItemValue('uf', e.target.value)
                          }}
                          value={command.uf || ''}
                          defaultValue={estados.find(
                            i => i.Initials === command.uf
                          )}
                        >
                          <MenuItem value="">
                            <em>UF</em>
                          </MenuItem>
                          {estados.map((estado, i) => (
                            <MenuItem key={i} value={estado.Initials}>
                              {estado.Initials}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText error={!!errors.uf}>
                          {errors.uf?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        erro={errors}
                        name="cidade"
                        value={command.cidade}
                        placeholder="Informe a cidade"
                        label="Cidade"
                        defaultValue={parceiro?.cidade}
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        erro={errors}
                        name="bairro"
                        value={command.bairro}
                        placeholder="Informe o bairro"
                        label="Bairro"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        erro={errors}
                        name="logradouro"
                        value={command.logradouro}
                        placeholder="Informe o logradouro"
                        label="Logradouro"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        erro={errors}
                        name="numero"
                        value={command.numero}
                        placeholder="Informe o número"
                        label="Número"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        erro={errors}
                        name="complemento"
                        value={command.complemento}
                        placeholder="Informe o complemento"
                        label="Complemento"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>{' '}
                    <Grid item></Grid>
                  </Grid>
                )}
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      erro={errors}
                      multiline={true}
                      rows={4}
                      value={command.observacao}
                      placeholder="Digite a obsevação do parceiro"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true
                      }}
                      name="observacao"
                      label="Observações"
                    />
                  </Grid>
                </Grid>
                {/* <p>{JSON.stringify(parceiro)}</p> */}
                {/* <p>{JSON.stringify(command)}</p> */}
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 1}
                showFinish={activeStep === 2}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  command.tipo !== SynTipoParceiro.HOSPITAL_CLINICA.id
                    ? 'Responsável por área'
                    : 'Responsáveis'
                }
                large={!addContato}
              >
                {!addContato && (
                  <Grid item xs={12}>
                    <Box textAlign="left">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setAddContato(true)}
                        startIcon={<AddIcon />}
                        style={{ marginBottom: '20px' }}
                      >
                        Adicionar responsável
                      </Button>
                    </Box>
                  </Grid>
                )}

                {!contatos.length && !addContato && (
                  <div>
                    O parceiro ainda não tem responsáveis{' '}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setAddContato(true)}
                    >
                      adicione agora
                    </Link>
                  </div>
                )}

                {contatos.length !== 0 && !addContato && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell style={{ minWidth: '150px' }}>
                            Telefone
                          </TableCell>
                          <TableCell>E-mail</TableCell>
                          <TableCell style={{ minWidth: '150px' }}>
                            CPF
                          </TableCell>
                          <TableCell>
                            {command.tipo !==
                            SynTipoParceiro.HOSPITAL_CLINICA.id
                              ? 'Especialidade'
                              : 'Setor'}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {contatos.map(item => (
                          <TableRow key={item.id}>
                            <TableCell>{item.nome}</TableCell>
                            <TableCell>
                              {formatarTelefone(item.celular)}
                            </TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{formatarCPF(item.cpf)}</TableCell>
                            <TableCell>
                              {item?.especialidades?.map((especialidade, i) => {
                                const lastItem = item.especialidades.length

                                if (lastItem === i + 1) {
                                  return ` ${especialidade.descricao}`
                                }

                                return ` ${especialidade.descricao} -`
                              })}
                            </TableCell>
                            <TableCell>
                              {/* ---------------------------------------- */}
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                              >
                                {popupState => (
                                  <React.Fragment>
                                    <IconButton
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      aria-label="mais"
                                      {...bindTrigger(popupState)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                      anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                      }}
                                      {...bindMenu(popupState)}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          popupState.close()
                                          handleEditFuncionario(item)
                                        }}
                                      >
                                        Editar
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          popupState.close()
                                          handleRemoveContact(item.cpf)
                                        }}
                                        className="danger"
                                      >
                                        Excluir
                                      </MenuItem>
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {addContato && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        erro={contactForm.errors}
                        name="nome"
                        placeholder="Nome..."
                        label="Nome"
                        inputRef={contactForm.register}
                        control={contactControl}
                        value={contactWatch.nome}
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        maskTelCel
                        style={{ marginTop: 0 }}
                        control={contactControl}
                        erro={contactForm.errors}
                        name="celular"
                        placeholder="Telefone..."
                        label="Telefone"
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        style={{ marginTop: 0 }}
                        erro={contactForm.errors}
                        name="cpf"
                        placeholder="CPF..."
                        label="CPF"
                        mask="999.999.999-99"
                        disabled={!!contactWatch.id || edicao}
                        control={contactControl}
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ marginTop: 0 }}
                        erro={contactForm.errors}
                        name="email"
                        placeholder="E-mail..."
                        label="E-mail"
                        inputRef={contactForm.register}
                        value={contactWatch.email}
                        control={contactControl}
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        erro={errors}
                        name="especialidades"
                        label={
                          command.tipo === SynTipoParceiro.HOSPITAL_CLINICA.id
                            ? 'Setor'
                            : 'Especialidades'
                        }
                        value={contactWatch.especialidades}
                        getOptionSelected={option =>
                          !!contactWatch.especialidades.find(
                            l => l.id === option.id
                          )
                        }
                        options={
                          command.tipo === SynTipoParceiro.HOSPITAL_CLINICA.id
                            ? setores
                            : especialidades
                        }
                        loading={loadingEspecialidades}
                        showCheck
                        closeOnSelect
                        getOptionLabel={option => option.descricao}
                        onChange={(
                          event,
                          newValue: ObjetoDescricaoGenerico[]
                        ) => {
                          handleRegisterObjeto(
                            'especialidades',
                            newValue as ObjetoDescricaoGenerico[]
                          )
                        }}
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                      >
                        <Button
                          className={classes.margenButton}
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddContact()}
                        >
                          Salvar
                        </Button>

                        <Button
                          className={classes.margenButton}
                          variant="contained"
                          color="default"
                          onClick={() => handleCloseAddContact()}
                        >
                          Cancelar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 2}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleFinish={handleSubmit(onSubmit, e => console.log(e))}
                loading={loadingSubmitButton}
                textoTitulo={
                  'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                }
                showFinish
              >
                <Box mb={3}>
                  <Typography variant="subtitle1">
                    Caso precise alterar alguma informação, clique no botão
                    'Voltar' até chegar na etapa desejada.
                  </Typography>
                </Box>

                <Resumo parceiro={command} contatos={contatos} />
              </StepContentSyn>
            </form>
          </Grid>
        </Grid>
      </Box>
      {!!showModal && (
        <ModalAddGenerico
          open={true}
          titulo="Adicionar local"
          label="Nome Fantasia"
          saveSubmit={adicionarLocalAtendimento}
          handleValue={valor => {
            handleNovoValor(valor)
          }}
          descricao=""
          handleClose={() => toggleModal(null)}
        />
      )}
    </>
  )
}
