import { AxiosResponse } from 'axios'
import {
  IPacoteInteligenteForm,
  ItemMaterialInteligente,
  ItemOpmeInteligente
} from 'src/components/pacoteInteligente/FormPacoteInteligente'
import api from 'src/services/api'
import {
  ListagemQueryPai,
  ListagemResponse,
  ObjetoDescricaoGenerico,
  ObjetoDescricaoGenericoValor
} from 'src/types'

//#region TIPOS
export interface SalvarPacoteInteligenteCommand {
  id?: string
  fluxoConfiguracaoSolicitacao?: boolean
  medicoCpf?: string
  descricao: string
  origemPacote: string
  hospitalId: string
  especialidades: string[]
  pacotePublico: boolean
  listaMedico: string[]
  procedimentos: string[]
  possuiOpmeTerceiro: boolean
  taxaOpme: number
  opmes: ItemOpmeInteligente[]
  opmesInclusos: ItemOpmeInteligente[]
  tiposAnestesia: ObjetoDescricaoGenericoValor[]
  responsavelAnestesia: string
  equipamentos: ObjetoDescricaoGenericoValor[]
  materiais: ItemMaterialInteligente[]
  valoresInternacao: ObjetoDescricaoGenericoValor[]
  dataExpiracao: string
  possuiSangue: boolean
  valorTaxaReservaSangue: number
  valorBase: number
  valorUnidadeConcentradoHemacias: number
  examesDuranteInternacao: ItemMaterialInteligente[]
  membros: ObjetoDescricaoGenericoValor[]
  medicoInformaOpme: boolean
  pqa?: boolean
  dayClinic?: boolean
  diariasInternacao?: number
  taxaSynPersonalizada?: number
  diariasUti?: number
  honorarioEquipeMedica?: number
  mediaTempoCirurgico?: string
  planoDeSaude?: boolean
  propostaFinalDeSemana?: boolean
  propostaCentroCirurgicoAmbulatorial?: boolean
  descontoFinalDeSemana?: number
  descontoCentroCirurgicoAmbulatorial?: number
}

export interface ListagemPacoteInteligenteQuery extends ListagemQueryPai {
  // termoPesquisa?: string
  // status?: string
  locaisAtendimento?: string[]
  hospitalId?: string
  perfilAtual?: string
  medicos?: string[]
  especialidades?: string[]
  uf?: string
  mostrarLiberado?: boolean
  mostrarAguardandoLiberacao?: boolean
}

export interface ListagemPacoteUnificadoQuery extends ListagemQueryPai {
  // termoPesquisa?: string
  // status?: string
  locaisAtendimento?: string[]
  perfilAtual?: string
  medicos?: string[]
  especialidades?: string[]

  termoPesquisa?: string
  status?: string
  tag?: string
  statusProposta?: string
  medicoId?: string
  medicoCpf?: string
}

export interface ItemListagemPacoteInteligente {
  id: string
  descricao: string
  codigo: string
  pacotePublico: string
  especialidades: string[]
  medicos: string[]
  hospital: string
  adicionado?: boolean
  configurado?: boolean
  status?: number
  dataExpiracao?: string
  valorBase?: number
  honorarioEquipeMedica?: number
  pacoteVinculadoId: string
}

export interface ItemListagemPacoteUnificado {
  id: string
  descricao: string
  codigo: string
  pacotePublico: string
  especialidades: string[]
  medicos: string[]
  hospital: string
  adicionado?: boolean
  configurado?: boolean
  status?: number
  dataExpiracao?: string
  valorBase?: number
  honorarioEquipeMedica?: number
  pacoteVinculadoId: string

  //cotacao
  tipo: 'inteligente' | 'cotacao'
  desabilitado?: boolean
  criado: string
  especialidade: string
  nomeMedico: string
  revisarDados: boolean
  medicoId: string
  nomeParceiro: string
  mediaTempoCirurgico: string
}
export interface IPacoteInteligenteDetalhadoResponse {}
export interface AddMeuPacoteCommand {
  cpf: string
  pacoteInteligenteId: string
}

//#endregion
export const salvarPacoteInteligente: (
  command: SalvarPacoteInteligenteCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/pacoteInteligente', command)
}

export const configurarPacoteInteligente: (
  command: SalvarPacoteInteligenteCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/pacoteInteligente/configuracao', command)
}

export const obterLocaisAtendimentoInteligente: (
  cpf: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = (cpf: string) => {
  return api.get(`/pacoteInteligente/${cpf}/locaisDisponiveis`)
}

export const addMeuPacoteInteligente: (
  command: AddMeuPacoteCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/pacoteInteligente/AdicionarPacote', command)
}

export const liberarPacoteInteligente: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.post(`/pacoteInteligente/${id}/liberar`)
}

export const bloquearPacoteInteligente: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.post(`/pacoteInteligente/${id}/desativar`)
}

export const deletarPacoteInteligente: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.delete(`/pacoteInteligente/${id}`)
}

export const marcarNaoRealiza: (
  command: AddMeuPacoteCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/pacoteInteligente/MarcarNaoRealiza', command)
}

export const listagemPacoteInteligente: (
  query: ListagemPacoteInteligenteQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemPacoteInteligente>>
> = query => {
  const { especialidades, medicos, locaisAtendimento, ...rest } = query

  var params = new URLSearchParams(rest)

  especialidades?.forEach(element => {
    params.append('especialidades', element)
  })

  medicos?.forEach(element => {
    params.append('medicos', element)
  })

  locaisAtendimento?.forEach(element => {
    params.append('medicos', element)
  })

  return api.get(`/pacoteInteligente/listagem?${params}`)
}

export const listagemPacotesUnificados: (
  cpf: string,
  query: ListagemPacoteUnificadoQuery,
  mostrarCotacao: boolean,
  mostrarInteligente: boolean
) => Promise<AxiosResponse<ListagemResponse<ItemListagemPacoteUnificado>>> = (
  cpf = '',
  query,
  mostrarCotacao,
  mostrarInteligente
) => {
  const { especialidades, ...rest } = query

  var params = new URLSearchParams(rest)

  especialidades?.forEach(element => {
    params.append('especialidades', element)
  })

  return api.get(
    `/pacoteProcedimento/ListagemUnificado?${new URLSearchParams(
      query
    )}&incluirCompartilhados=true&mostrarCotacao=${mostrarCotacao}&mostrarInteligente=${mostrarInteligente}`
  )
  // return api.get(`/pacoteInteligente/${cpf}/ListagemMeusPacotes?${params}`)
}

export const listagemMeusPacotesInteligentes: (
  cpf: string,
  query: ListagemPacoteInteligenteQuery
) => Promise<AxiosResponse<ListagemResponse<ItemListagemPacoteInteligente>>> = (
  cpf,
  query
) => {
  const { especialidades, ...rest } = query

  var params = new URLSearchParams(rest)

  especialidades?.forEach(element => {
    params.append('especialidades', element)
  })

  return api.get(`/pacoteInteligente/${cpf}/ListagemMeusPacotes?${params}`)
}

export const listagemPacotesInteligentesSugeridos: (
  cpf: string,
  query: ListagemPacoteInteligenteQuery
) => Promise<AxiosResponse<ListagemResponse<ItemListagemPacoteInteligente>>> = (
  cpf,
  query
) => {
  const { locaisAtendimento, ...rest } = query

  var params = new URLSearchParams(rest)

  locaisAtendimento?.forEach(element => {
    params.append('locaisAtendimento', element)
  })

  return api.get(`/pacoteInteligente/${cpf}/listagem?${params}`)
}

export const obterPacoteInteligentePorId: (
  id: string
) => Promise<AxiosResponse<IPacoteInteligenteForm>> = id => {
  return api.get(`/pacoteInteligente/${id}`)
}

export const obterTemplatePacoteInteligentePorId: (
  id: string,
  cpfMedico: string
) => Promise<AxiosResponse<IPacoteInteligenteForm>> = (cpfMedico, id) => {
  return api.get(`/pacoteInteligente/${cpfMedico}/${id}`)
}

export const excluirPacoteInteligente: (
  id: string
) => Promise<boolean> = async id => {
  await api.delete(`/pacoteInteligente/${id}`)
  return true
}
