import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'
import { useContext, useEffect, useState } from 'react'
import { FiFilter } from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import {
  alterarStatusProcedimentoRealizado,
  listagemOrcamentoParceiro,
  obterStatusSolicitacaoOrcamento
} from 'src/api/solicitacao-orcamento/index'
import DrawerVisualizarOcorrencias from 'src/components/drawers/medico/DrawerVisualizarOcorrencias'
import CardListagem from 'src/components/solicitacao/CardListagem'
import DrawerComprovanteDestinatario from 'src/components/solicitacao/drawerComprovanteDestinatario'
import ButtonUI, { Button } from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import SetoresLocalAtendimento from 'src/core/consts/setorLocalAtendimento'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import { SynRoutes } from 'src/core/consts/synRoutes'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import TipoAnexo from 'src/core/consts/tipoAnexo'
import {
  formatarData
} from 'src/core/formatacoes/formatarData'
import useAuth from 'src/hooks/useAuth'
import useListagem from 'src/hooks/useListagem'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'
import { ItemListagemSolicitacaoOrcamentoResponse } from 'src/types'
import DrawerVisualizarDetalhesSolicitacao from './drawerVerDadosOrcamento'
import DrawerVerOrcamentoEnviado from './drawerVerDadosOrcamentoEnviado'
import DrawerVisualizarDetalhes from './hospital/components/drawerVisualizarDetalhes'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))

const data = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

function listagemPacotes() {
  const history = useHistory()
  const { user, perfilAtual } = useAuth()

  const style = useStyles()
  const [mostrarOrcamento, setMostrarOrcamento] = useState(false)
  const [mostrarDetalhes, setMostrarDetalhes] = useState(false)
  const [mostrarSolicitacao, setMostrarSolicitacao] = useState(false)
  const [solicitacaoId, setSolicitacaoId] = useState<string>()
  const [
    solicitacaoSelecionada,
    setSolicitacaoSelecionada
  ] = useState<ItemListagemSolicitacaoOrcamentoResponse>(null)
  const [mostrarComprovantes, setMostrarComprovantes] = useState<boolean>(false)
  const [statusSelecionado, setStatusSelecionado] = useState('')

  const handleVisualizarComprovantes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setSolicitacaoSelecionada(item)
    setMostrarComprovantes(true)
  }

  const handleFecharComprovantes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setSolicitacaoSelecionada(null)
    setMostrarComprovantes(false)
  }

  const handleDetalhes = (item: ItemListagemSolicitacaoOrcamentoResponse) => {
    setSolicitacaoId(item.id)
    setMostrarDetalhes(true)

    // toast.error('Funcionando' + solicitacaoId)
    // setSolicitacaoSelecionada(item)
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhes(false)
  }

  const handleMostrarOrcamento = solicitacaoId => {
    setMostrarOrcamento(true)
    setSolicitacaoId(solicitacaoId)
  }

  const handleMostrarSolicitacao = solicitacaoId => {
    setMostrarSolicitacao(true)
    setSolicitacaoId(solicitacaoId)
  }

  const handleFecharOrcamento = () => {
    setMostrarOrcamento(false)
    setSolicitacaoId(undefined)
  }

  const handleMostrarDetalhes = (solicitacaoId, parceiroId) => {
    setSolicitacaoId(solicitacaoId)
    history.push(
      `${SynRoutes.dadosTesourariaParceiroHospital}?id=${solicitacaoId}&pid=${parceiroId}`
    )
  }

  const [showFiltros, setShowFiltros] = useState(false)
  const [mostrarCotacao, setMostrarCotacao] = useState(true)
  const [mostrarInteligente, setMostrarInteligente] = useState(true)

  const { setUltimaSolicitacao, ultimaSolicitacaoVista } = useContext(
    UltimaSolicitacaoContext
  )

  const [visualizarOcorrencias, setVisualizarOcorrencias] = useState({
    show: false,
    id: ''
  })

  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const [dataInicioDirty, setDataInicioDirty] = useState(false)
  const [dataFimDirty, setDataFimDirty] = useState(false)
  const [diasFiltro, setDiasFiltro] = useState('periodo')

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDados,
    qtdAllDados,
    handleProxPagina,
    allDadosCarregados,
    handleFiltroStatus,
    loadingFiltroStatus,
    filtroStatus,
    loadingPagina,
    listaStatus,
    handleFiltroData,
    handleLimparFiltros,
    handleSetTermoPesquisar,
    handlePesquisarDados,
    carregarListagem
  } = useListagem(9, listagem, obterStatusSolicitacaoOrcamento)

  async function listagem(query) {
    query.tipoParceiro = user.tipoParceiro
    if (user?.setorFuncionario) {
      query.perfilFuncionarioAtual = user?.setorFuncionario
    }
    return await listagemOrcamentoParceiro(
      query,
      mostrarInteligente,
      mostrarCotacao
    )
  }

  useWindowInfiniteScroll({
    data: allDados,
    loadMoreFunc: handleProxPagina,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  useEffect(() => {
    handleFiltroPorData()
  }, [diasFiltro])

  useEffect(() => {
    carregarListagem({ resetarPaginacao: true })
  }, [mostrarCotacao, mostrarInteligente])

  function handleAbrirSolicitacao(item) {
    if (
      item.status?.id === statusOrcamento.REALIZADO.Id ||
      item.status?.id === statusOrcamento.EM_AGENDAMENTO.Id ||
      item.status?.id === statusOrcamento.PROCESSO_CANCELADO.Id
    ) {
      return
    }

    if (user.tipoParceiro === SynTipoParceiro.OPME.id) {
      history.push(`${SynRoutes.dadosSolicitacaoParceiroOPME}?id=${item.id}`)
    } else if (user.tipoParceiro === SynTipoParceiro.HOSPITAL_CLINICA.id) {
      history.push(
        `${SynRoutes.dadosSolicitacaoParceiroHospital}?id=${item.id}`
      )
    }
  }

  const handleFiltroPorData = () => {
    if (diasFiltro === 'periodo') {
      limparFiltroData()
    } else if (diasFiltro === 'hoje') {
      CarregarDataAtualFiltro()
    } else if (diasFiltro === '3Dias') {
      CarregarDataPodDiaFiltro(3)
    } else if (diasFiltro === '7Dias') {
      CarregarDataPodDiaFiltro(7)
    } else if (diasFiltro === '15Dias') {
      CarregarDataPodDiaFiltro(15)
    } else if (diasFiltro === '30Dias') {
      CarregarDataPodDiaFiltro(30)
    }
  }

  const CarregarDataPodDiaFiltro = dias => {
    var dataFiltro = new Date()
    var day = dataFiltro.getDate().toString().padStart(2, '0')
    var year = dataFiltro.getFullYear()
    var month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    setDataFim(year + '-' + month + '-' + day)

    dataFiltro.setDate(dataFiltro.getDate() - dias)
    year = dataFiltro.getFullYear()
    month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    var dayModificado = dataFiltro.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + dayModificado)
  }

  const CarregarDataAtualFiltro = () => {
    var dt = new Date()
    var year = dt.getFullYear()
    var month = (dt.getMonth() + 1).toString().padStart(2, '0')
    var day = dt.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + day)
    setDataFim(year + '-' + month + '-' + day)
    setDiasFiltro('hoje')
  }

  const limparFiltroData = () => {
    setDiasFiltro('periodo')
    setDataInicio('')
    setDataFim('')
    setStatusSelecionado('')
    handleLimparFiltros()
  }

  function handleVisualizarOcorrencias(solicitacaoId: string) {
    setVisualizarOcorrencias({
      show: true,
      id: solicitacaoId
    })
  }

  function setClassToHighlight(item) {
    if (item?.id === solicitacaoId) return 'highlight'

    return null
  }

  const handleSetDiaFiltro = e => {
    var valor = e.target.value
    setDiasFiltro(valor)
  }

  function obterListaStatusPorPerfil() {
    var lista = listaStatus

    if (user?.setorFuncionario === SetoresLocalAtendimento.TESOURARIA?.Id) {
      var statusTesouraria = [
        statusOrcamento.EM_AGENDAMENTO.Id,
        statusOrcamento.REALIZADO.Id,
        statusOrcamento.AGENDADO.Id
      ]

      return lista.filter(i => statusTesouraria.includes(i.id))
    }

    if (
      user?.setorFuncionario === SetoresLocalAtendimento.COTACAO?.Id ||
      user.tipoParceiro === SynTipoParceiro.OPME.id
    ) {
      var statusCotacao = [
        statusOrcamento.EM_ANDAMENTO.Id,
        statusOrcamento.EM_AGENDAMENTO.Id,
        statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id,
        statusOrcamento.REALIZADO.Id,
        statusOrcamento.AGENDADO.Id
      ]
      return lista.filter(i => statusCotacao.includes(i.id))
    }
    return lista
  }

  async function handleAlterarStatusProcedimentoRealizado(
    idSolicitacao: string
  ) {
    await alterarStatusProcedimentoRealizado(idSolicitacao, perfilAtual)

    carregarListagem({
      resetarPaginacao: true,
      dataInicio: dataInicio,
      dataFim: dataFim
    })
  }

  function handleKeyDownPesquisar(e) {
    if (e.key === 'Enter' && termoPesquisa) {
      handlePesquisarDados()
    }
  }

  const classes = data()

  return (
    <Box>
      <Box mb={2}>
        {/* Tesouraria / Cotação*/}
        <Typography variant="h5">Solicitações de orçamentos</Typography>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              onKeyDown={e => {
                handleKeyDownPesquisar(e)
              }}
              fullWidth
              placeholder="Pesquise pelo nome do pacote, do paciente ou do médico..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={termoPesquisa}
              onChange={e => {
                if (!e.target.value) {
                  handleSetTermoPesquisar(e as any, true)
                } else {
                  handleSetTermoPesquisar(e as any, false)
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              color="default"
              variant="text"
              onClick={() => setShowFiltros(!showFiltros)}
              startIcon={<FiFilter />}
            >
              {showFiltros ? 'Esconder' : 'Mostrar'} filtros
            </Button>
          </Grid>

          {showFiltros && (
            <Grid item xs={12} className={style.layoutFiltro}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={statusSelecionado || filtroStatus}
                      onChange={e => {
                        setStatusSelecionado(e.target.value as string)
                      }}
                      disabled={loadingFiltroStatus}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginRight: '16px' }}
                        >
                          {loadingFiltroStatus ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      }
                    >
                      <MenuItem value=" ">Todos os status</MenuItem>
                      {obterListaStatusPorPerfil().map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={diasFiltro}
                      onChange={e => {
                        handleSetDiaFiltro(e)
                      }}
                    >
                      <MenuItem value="periodo">Período</MenuItem>
                      <MenuItem value="hoje">Hoje</MenuItem>
                      <MenuItem key="2" value="3Dias">
                        Últimos 3 dias
                      </MenuItem>
                      <MenuItem key="3" value="7Dias">
                        Últimos 7 dias
                      </MenuItem>
                      <MenuItem key="4" value="15Dias">
                        Últimos 15 dias
                      </MenuItem>
                      <MenuItem key="5" value="30Dias">
                        Últimos 30 dias
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField
                    id="dataInicio"
                    error={dataInicioDirty && !formatarData(dataInicio)}
                    helperText={
                      dataInicioDirty && !formatarData(dataInicio)
                        ? 'Data inválida'
                        : ''
                    }
                    label="De"
                    type="date"
                    defaultValue={dataInicio}
                    value={dataInicio}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      setDataInicioDirty(true)
                    }}
                    onChange={e => {
                      setDataInicio(e.target.value)
                    }}
                  />

                  <TextField
                    error={dataFimDirty && !formatarData(dataFim)}
                    helperText={
                      dataFimDirty && !formatarData(dataFim)
                        ? 'Data inválida'
                        : ''
                    }
                    id="dataFim"
                    label="Até"
                    type="date"
                    defaultValue={dataFim}
                    value={dataFim}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      setDataFimDirty(true)
                    }}
                    onChange={e => {
                      setDataFim(e.target.value)
                    }}
                  />
                </Grid>

              </Grid>
            </Grid>
          )}

          <Grid container style={{ display: 'flex', gap: 4, marginTop: 4, marginLeft: 8 }} >
            <Grid item xs={3} sm={3}>
              <Button
                loading={loading}
                disabled={loading}
                size="small"
                fullWidth={true}
                variant="contained"
                color="secondary"
                startIcon={<GoSearch />}
                onClick={e => {
                  carregarListagem({
                    resetarPaginacao: true,
                    dataInicio: dataInicio,
                    dataFim: dataFim,
                    status: statusSelecionado
                  })
                }}
              >
                Pesquisar (Enter)
              </Button>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Button
                size="small"
                fullWidth={true}
                variant="contained"
                color="primary"
                startIcon={<ClearIcon />}
                onClick={e => {
                  limparFiltroData()
                }}
              >
                Limpar filtros
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControlLabel
                value={mostrarInteligente}
                control={
                  <Checkbox
                    checked={mostrarInteligente}
                    onChange={(e, value) => {
                      setMostrarInteligente(value)
                    }}
                  />
                }
                label="Mostrar orçamentos instantâneos"
              />
            </FormGroup>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  value={mostrarCotacao}
                  control={
                    <Checkbox
                      checked={mostrarCotacao}
                      onChange={(e, value) => {
                        setMostrarCotacao(value)
                      }}
                    />
                  }
                  label="Mostrar orçamentos cotações"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {!!ultimaSolicitacaoVista
                  ? 'Última solicitação vista'
                  : qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} solicitações`
                      : '1 solicitação'
                    : '0 Solicitações'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!!ultimaSolicitacaoVista && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUltimaSolicitacao()}
                >
                  Mostrar todas as solicitações
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      {nenhumDado && allDados.length === 0 && (
        <Grid item xs={12} container justify="center">
          <Typography variant="h6" color="primary">
            Nenhuma solicitação de orçamento encontrada.
          </Typography>
        </Grid>
      )}

      {nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}

      {allDadosCarregados && !loadingPagina && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {ultimaSolicitacaoVista !== undefined && (
              <Grid item xs={12} md={6} lg={4} key={ultimaSolicitacaoVista.id}>
                <CardListagem
                  perfil="parceiro"
                  solicitacao={ultimaSolicitacaoVista}
                  navigate={history.push}
                  handleAbrirSolicitacao={handleAbrirSolicitacao}
                  handleMostrarOrcamento={handleMostrarOrcamento}
                  handleVisualizarComprovantes={handleVisualizarComprovantes}
                  handleMostrarDetalhes={handleMostrarDetalhes}
                  setClassToHighlight={setClassToHighlight}
                  setUltimaSolicitacao={setUltimaSolicitacao}
                  handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                  handleAlterarStatusProcedimentoRealizado={
                    handleAlterarStatusProcedimentoRealizado
                  }
                  handleVisualizarDetalhes={handleDetalhes}
                />
              </Grid>
            )}
            {allDados.map(item => {
              if (ultimaSolicitacaoVista === undefined) {
                return (
                  <Grid item xs={12} md={6} lg={4} key={item.id}>
                    <CardListagem
                      perfil="parceiro"
                      solicitacao={item}
                      navigate={history.push}
                      handleAbrirSolicitacao={handleAbrirSolicitacao}
                      handleMostrarOrcamento={handleMostrarOrcamento}
                      handleVisualizarComprovantes={
                        handleVisualizarComprovantes
                      }
                      handleMostrarDetalhes={handleMostrarDetalhes}
                      setClassToHighlight={setClassToHighlight}
                      setUltimaSolicitacao={setUltimaSolicitacao}
                      handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                      handleAlterarStatusProcedimentoRealizado={
                        handleAlterarStatusProcedimentoRealizado
                      }
                      handleVisualizarDetalhes={handleDetalhes}
                    />
                  </Grid>
                )
              } else {
                return <></>
              }
            })}
          </Grid>
        </div>
      )}

      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      {mostrarComprovantes && solicitacaoSelecionada && (
        <DrawerComprovanteDestinatario
          solicitacaoId={solicitacaoSelecionada.id}
          destinatarioId={solicitacaoSelecionada.parceiroId}
          open={true}
          handleOnClose={handleFecharComprovantes}
          tipo={
            user.tipoParceiro === SynTipoParceiro.OPME.id
              ? TipoAnexo.OPME.id
              : TipoAnexo.HOSPITAL.id
          }
        />
      )}

      <DrawerVisualizarDetalhesSolicitacao
        id={solicitacaoId}
        open={mostrarSolicitacao}
        handleOnClose={handleFecharOrcamento}
      />

      <DrawerVisualizarDetalhes
        id={solicitacaoId}
        open={mostrarDetalhes}
        handleOnClose={handleFecharDetalhes}
      />

      <DrawerVerOrcamentoEnviado
        solicitacaoId={solicitacaoId}
        open={mostrarOrcamento}
        handleOnClose={handleFecharOrcamento}
      />

      <DrawerVisualizarOcorrencias
        open={visualizarOcorrencias.show}
        handleOnClose={() =>
          setVisualizarOcorrencias({
            show: false,
            id: ''
          })
        }
        solicitacaoId={visualizarOcorrencias.id}
        parceiro={true}
      />
    </Box>
  )
}

export default listagemPacotes
