import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import {
  buscarAssistentesPorMedicoId,
  excluirAssistenteMedico
} from 'src/api/medico'
import Button from 'src/components/ui/Button'
import yup from 'src/components/yupCustom'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { ItemListagemAssistenteMedicoResponse } from 'src/types'
import Swal from 'sweetalert2'
import DrawerCadastrarAssistente from '../../secretaria/drawerCadastrarAssistente'

const schema = yup.object().shape({
  id: yup.string().label('Id'),
  nome: yup.string().required().min(3).max(100).label('Nome'),
  telefone: yup.string().required().label('Telefone'),
  email: yup.string().email().label('E-mail'),
  cpf: yup.string().required().label('CPF'),
  ehCriarSolicitacao: yup.bool().label('Pode criar Orçamentos'),
  ehReceberNotificacao: yup
    .bool()
    .label('Receber notificações das minhas solicitações')
})

interface Assistente {
  id: string
  nome: string
  telefone: string
  email: string
  cpf: string
  ehCriarSolicitacao: boolean
  ehReceberNotificacao: boolean
}

let defaultValues: Assistente = {
  id: '',
  nome: '',
  telefone: '',
  email: '',
  cpf: '',
  ehCriarSolicitacao: false,
  ehReceberNotificacao: false
}

export default function AssistentesMedico() {
  const [loading, setLoading] = useState(true)
  const [assistentes, setAssistentes] = useState<
    ItemListagemAssistenteMedicoResponse[]
  >()
  const [
    assistente,
    setAssistente
  ] = useState<ItemListagemAssistenteMedicoResponse>()
  const [assistenteId, setAssistenteId] = useState<string>()

  const [mostrarCadastrarAssistente, setMostrarCadastrarAssistente] = useState(
    false
  )

  useEffect(() => {
    obterAssistentes()
  }, [mostrarCadastrarAssistente])

  const obterAssistentes = () => {
    return buscarAssistentesPorMedicoId()
      .then(response => {
        setAssistentes(response.data.items)
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoading(false)
      })
  }
  async function handleEditar(item: ItemListagemAssistenteMedicoResponse) {
    setAssistente(item)
    setMostrarCadastrarAssistente(true)
  }

  async function handleExcluir(item: ItemListagemAssistenteMedicoResponse) {
    const assistenteId = item.id

    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja apagar este assistente?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Apagar'
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          await excluirAssistenteMedico(assistenteId)

          obterAssistentes()
        } catch (err) {
          showNotificationErrorAPI(err)
        }
      }
    })
  }
  function handleCadastrarAssistente() {
    setMostrarCadastrarAssistente(true)
  }

  return (
    <Box>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Assistentes</Typography>
        {/* <Typography variant="subtitle2">
          <Alert severity="info">
            Adicionar, editar ou excluir secretárias
          </Alert>
        </Typography> */}
        <Box textAlign="right">
          <Button
            onClick={() => handleCadastrarAssistente()}
            variant="contained"
            color="primary"
          >
            <AddIcon />
            Adicionar assistente
          </Button>
        </Box>
      </Box>
      {/* command: {JSON.stringify(command.ehCriarSolicitacao)}
      command: {JSON.stringify(command.ehReceberNotificacao)} */}

      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <CircularProgress />
          <div>Carregando...</div>
        </div>
      )}
      {!loading && (
        <>
          {assistentes?.length === 0 ? (
            <Typography variant="h6">Médico não possui assistentes</Typography>
          ) : (
            <>
              <Box mt={3} mb={2} textAlign="left" my={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      {assistentes && assistentes.length
                        ? assistentes.length > 1
                          ? `${assistentes.length} assistentes`
                          : '1 assistente'
                        : '0 assistentes'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <TableContainer component={Paper}>
                <Table style={{ overflowX: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>CPF</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>
                        Telefone
                      </TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Criar Orçamento</TableCell>
                      <TableCell>Receber Notificações</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assistentes?.map(item => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            {formatarCPF(item.cpf) || 'Não informado'}
                          </TableCell>
                          <TableCell>{item.nome}</TableCell>
                          <TableCell>
                            {formatarTelefone(item.telefone)}
                          </TableCell>
                          <TableCell>{item.email || 'Não informado'}</TableCell>
                          <TableCell>
                            {item.ehCriarSolicitacao === true ? 'Sim' : 'Não'}
                          </TableCell>
                          <TableCell>
                            {item.ehReceberNotificacao === true ? 'Sim' : 'Não'}
                          </TableCell>
                          <TableCell>
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-menu"
                            >
                              {popupState => (
                                <React.Fragment>
                                  <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    aria-label="mais"
                                    {...bindTrigger(popupState)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>

                                  <Menu
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right'
                                    }}
                                    {...bindMenu(popupState)}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleEditar(item)
                                      }}
                                    >
                                      Editar
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleExcluir(item)
                                      }}
                                      style={{ color: 'red' }}
                                    >
                                      Excluir
                                    </MenuItem>
                                    {/* <MenuItem
                                  onClick={() => {
                                    popupState.close()
                                    handleExcluir(item.id)
                                  }}
                                  className="danger"
                                >
                                  Excluir
                                </MenuItem> */}
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}

      <DrawerCadastrarAssistente
        assistente={assistente}
        open={mostrarCadastrarAssistente}
        handleOnClose={() => setMostrarCadastrarAssistente(false)}
        obterAssistentes={() => obterAssistentes}
      />
    </Box>
  )
}
