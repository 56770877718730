import { yupResolver } from '@hookform/resolvers/yup'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '@material-ui/lab'
import { J } from 'chart.js/dist/chunks/helpers.core'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RiArrowLeftLine } from 'react-icons/ri'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import Sticky from 'react-sticky-el'
import {
  obterOrcamentoParceiroPorId,
  obterOrcamentosParaSolicitacao
} from 'src/api/solicitacao-orcamento'
import ModalContemplaExame from 'src/components/hospital/ModalSubmitPropostaHospital'
import DadosOPMEModal from 'src/components/modals/DadosOPMEModal'
import ModalSubmitProposta from 'src/components/modals/ModalSubmitProposta'
import HeaderSolicitacao from 'src/components/solicitacao/HeaderSolicitacao'
import Button from 'src/components/ui/Button'
import TextFieldSyn from 'src/components/ui/TextField'
import Ubox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { useQuery } from 'src/hooks/useQuery'
import {
  IMedico,
  IOPMEResponse,
  IOrcamentoDetalhadoResponse,
  IOrcamentoLocal,
  IOrcamentoProposta,
  IPaciente,
  IPacoteDetalhadoResponse,
  IPacoteOrcado,
  IParceiroResponse,
  ItemExameLaboratorioResponse,
  ObjetoDescricaoGenerico,
  OPMEOrcado
} from 'src/types'
import Swal from 'sweetalert2'

const COR_STATUS = {
  NAO_CONFIGURADO: '#ea3f3f',
  CONFIGURADO: '#8bc34a'
}

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: '-60px',
    padding: '0px 20px'
  },
  boxResumo: {
    backgroundColor: theme.palette.grey[50]
  },
  accordion: {
    border: '1px solid #efefef',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '16px',
    background: theme.palette.grey[50]
  },
  accordionSemOPME: {
    border: '1px solid #efefef',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '16px',
    background: theme.palette.grey[20],
    color: '#717171',
    cursor: 'default !important'
  },

  accordionSummary: {
    paddingRight: '10px'
  },
  accordionSummarySemOPME: {
    cursor: 'default',
    paddingRight: '10px'
  },
  accordionDetails: {},
  disponivelQuestion: {
    marginRight: '20px',
    marginBottom: '20px'
  },
  inputLabel: {
    marginRight: '20px'
  },
  calculoContent: {
    marginTop: '50px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    maxHeight: 'calc(100vh - 30px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: '20px'
  },
  calculoContentXS: {
    marginTop: '50px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  divider: {
    margin: '10px 0'
  },
  headerDivider: {
    margin: '10px 0 30px 0'
  },
  calculoText: {
    fontSize: '10px'
  },
  submitButton: {
    backgroundColor: '#59F0D5',
    marginTop: '15px',
    color: '#455880',
    height: '50px',
    borderRadius: '10px'
  },
  cardItem: {
    margin: '20px 0'
  },
  statusOPME: {
    display: 'inline-block',
    minHeight: '16px',
    minWidth: '16px',
    maxHeight: '16px',
    maxWidth: '16px',
    borderRadius: '50%'
  },
  OPMECard: {
    width: '100%',
    height: '80px',
    margin: '4px 0',
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #ececec',
    justifyContent: 'space-between',
    padding: '10px'
  },
  OPMECardHospital: {
    width: '100%',
    height: '100px',
    margin: '4px 0',
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #dad7d7',
    justifyContent: 'space-between',
    backgroundColor: '#eaf1f7',
    padding: '10px'
  },
  OPMECardSmall: {
    gap: 8
  },
  hospitalCard: {
    color: '#465a81',
    padding: '12px 0 12px 16px',
    textAlign: 'left',
    border: '1px solid #465b82'
  },
  hospitalCardText: {
    margin: '3px 0'
  },
  examesCard: {
    backgroundColor: theme.palette.grey[50],
    padding: '15px'
  },
  numberFormat: {
    width: '160px',
    backgroundColor: 'rgba(255,255,255,0.1) ',
    border: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '2px solid white',
    outline: 'none',
    fontSize: '18px',
    padding: '8px'
  },
  hospitalGreyCard: {
    color: '#717171',
    padding: '12px 0 12px 16px',
    textAlign: 'center',
    border: '1px solid #717171'
  },
  hospitalRedCard: {
    color: 'red',
    padding: '12px 0 12px 16px',
    textAlign: 'center',
    border: '1px solid red'
  },
  orcamentoTitle: {
    marginBottom: '20px'
  },
  formGroup: {
    marginBottom: '5px'
  },
  inputObservacao: {
    margin: '10px 0',
    '& .MuiInputBase-root': {
      color: '#fff'
    },
    '& .MuiFormLabel-root': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff'
      },
      '&:hover fieldset': {
        borderColor: '#fff'
      }
    }
  },
  inputHospital: {
    padding: '8px',
    fontSize: '16px',
    borderBottom: '2px solid #38546e',
    '& .MuiInputBase-input': {
      fontWeight: 500
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  numberFormatHospital: {
    width: '150px',
    backgroundColor: 'transparent',
    border: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '2px solid #38546e',
    outline: 'none',
    fontSize: '16px',
    padding: '8px'
  },
  justificativaRecusar: {
    margin: '20px 0',
    width: '100%'
  },
  cardRecusar: {
    padding: '20px',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid #71717142'
  },
  recusarButton: {
    backgroundColor: '#e74c3c'
  }
}))

interface IOrcamentoForm {
  valorAnestesia: number
  valorSala: number
  valorPreAnestesica: number
  valorRiscoCirurgico: number
  valorBolsaSangue: number
  valorReservaSangue: number
  observacao: string
  ehContemplaExame: boolean
  observacaoExames: string
  perfilFuncionarioAtual?: string
}

const orcamentoFormSchema = yup.object().shape({
  valorAnestesia: yup.number().required().min(0).label('Valor anestesia'),
  valorSala: yup.number().required().min(0).label('Valor Sala'),
  valorPreAnestesica: yup
    .number()
    .min(0)
    .label('Valor avaliação pré-anestésica'),
  valorRiscoCirurgico: yup
    .number()
    .min(0)
    .label('Valor avaliação de risco cirúrgico'),
  valorBolsaSangue: yup.number().min(0).label('Valor bolsa de sangue'),
  valorReservaSangue: yup.number().min(0).label('Valor reserva de sangue'),
  observacao: yup.string().label('Observação')
})

export default function PrepararPropostaHospital() {
  const styles = useStyles()
  const query = useQuery()
  const history = useHistory()
  const { user } = useAuth()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  const [infoModal, setInfoModal] = useState(false)
  const [valorEquipeAnestesia, setValorEquipeAnestesia] = useState(null)
  const [dadosInfoModal, setDadosInfoModal] = useState<OPMEOrcado>()

  const [loading, setLoading] = useState(true)
  const [enviarLoading, setEnviarLoading] = useState(false)
  const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [orcamento, setOrcamento] = useState<IOrcamentoDetalhadoResponse>()
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string>('')
  const [valorTotal, setValorTotal] = useState<number>()
  const [valorTotalHospital, setValorTotalhospital] = useState<number>()
  const [propostaSemOPME, setPropostaSemOPME] = useState(false)
  const { setUltimaSolicitacaoEnviado } = useContext(UltimaSolicitacaoContext)
  const [
    propostaSemLocalAtendimento,
    setPropostaSemLocalAtendimento
  ] = useState(false)

  const [solicitacaoId, setSolicitacaoId] = useState<string>()
  const [medico, setMedico] = useState<IMedico>()
  const [paciente, setPaciente] = useState<IPaciente>()
  const [parceiro, setParceiro] = useState<IParceiroResponse>()
  const [solicitacaoSemOPME, setSolicitacaoSemOPME] = useState(false)
  const [contemplaExame, setContemplaExame] = useState(false)
  const [
    solicitacaoSemLocalAtendimento,
    setSolicitacaoSemLocalAtendimento
  ] = useState(false)

  const [pacoteProcedimento, setPacoteProcedimento] = useState<IPacoteOrcado>()
  const [listaOPME, setListaOPME] = useState<IOPMEResponse[]>()
  const [locaisAtendimento, setLocaisAtendimento] = useState<
    IOrcamentoLocal[]
  >()

  const [listaExames, setListaExames] = useState<
    ItemExameLaboratorioResponse[]
  >()
  const [
    labSelecionado,
    setLabSelecionado
  ] = useState<ItemExameLaboratorioResponse>()

  const [modalContemplaExame, setModalContemplaExame] = useState(false)
  const [submitModalEnvio, setSubmitModalEnvio] = useState(false)
  const [propostaFinaisDeSemana, setPropostaFinaisDeSemana] = useState(false)

  const orcamentoForm = useForm<IOrcamentoForm>({
    resolver: yupResolver(orcamentoFormSchema),
    defaultValues: {
      observacao: '',
      valorAnestesia: 0,
      valorBolsaSangue: 0,
      valorPreAnestesica: 0,
      valorReservaSangue: 0,
      valorRiscoCirurgico: 0,
      valorSala: 0,
      observacaoExames: '',
      ehContemplaExame: false,
      perfilFuncionarioAtual: ''
    }
  })

  const commandOrcar = orcamentoForm.watch()

  useEffect(() => {
    const id = query.get('id')
    obterOrcamentosParaSolicitacao(id)
      .then(response => {
        const orcamentoitem = response.data
        const novaListaOPME = orcamentoitem.listaOPME.map(opme => {
          return {
            ...opme,
            orcamentoOpmeHospital: {
              fabricante: '',
              valorUnitario: 0,
              selecionado: false,
              valido: false,
              nomeParceiro: 'OPME fornecido pelo hospital'
            }
          }
        })
        setSolicitacaoId(orcamentoitem.id)
        setValorEquipeAnestesia(orcamentoitem.valorEquipeAnestesia)
        setMedico(orcamentoitem.medico)
        setPacoteProcedimento(orcamentoitem.pacoteProcedimento)
        setPaciente(orcamentoitem.paciente)
        setListaOPME(novaListaOPME)
        setListaExames(orcamentoitem.listaExames)
        if (!orcamentoitem.listaOPME || orcamentoitem.listaOPME.length === 0) {
          setSolicitacaoSemOPME(true)
        }
        if (
          !orcamentoitem.listaLocalAtendimento ||
          orcamentoitem.listaLocalAtendimento.length === 0
        ) {
          setSolicitacaoSemLocalAtendimento(true)
        }

        setLocaisAtendimento(orcamentoitem.listaLocalAtendimento)
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
      })

    obterOrcamentoParceiroPorId(id)
      .then(response => {
        const dados = response.data
        setOrcamento(dados.solicitacaoOrcamento)
        setPacote(dados.pacoteProcedimento)
        setParceiro(dados.parceiro)

        setLoading(false)
      })
      .catch(erro => showNotificationErrorAPI(erro))
  }, [])

  useEffect(() => {
    var ehAnestesiaLocal =
      pacote?.tiposAnestesia?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacote?.tiposAnestesia?.length === 1
    setVerificaAnestesia(ehAnestesiaLocal)
  }, [pacote])

  useEffect(() => {
    let valor = 0

    listaOPME?.forEach(o => {
      o.listaOrcamentoOPME?.forEach(i => {
        if (i.selecionado) {
          valor += i.valorUnitario * o.quantidade
        }
      })
    })

    setValorTotal(valor)
  }, [listaOPME])

  useEffect(() => {
    let valor = 0

    listaOPME?.forEach(o => {
      o.orcamentoOpmeHospital?.selecionado &&
        o.orcamentoOpmeHospital?.valido &&
        (valor += o.orcamentoOpmeHospital.valorUnitario * o.quantidade)
    })
    setValorTotalhospital(valor)
  }, [listaOPME])

  /* --------------- handleChangeOPME --------------------- */

  function handleOpenAccordion(panel: string) {
    return (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '')
    }
  }
  const [verOpmeSelecionados, setVerOpmeSelecionados] = useState(false)
  function handleCheckOPME(itemId: string, opmeId: string) {
    const newListaOPME = listaOPME?.map(i => {
      if (i.pacoteProcedimentoOpmeId === opmeId) {
        i.orcamentoOpmeHospital.selecionado = false
      }
      if (i.pacoteProcedimentoOpmeId === opmeId) {
        const newOrcamentos = i.listaOrcamentoOPME?.map(o => {
          if (o.id === itemId) {
            o.selecionado = !o.selecionado
          } else {
            o.selecionado = false
          }

          return o
        })

        i.listaOrcamentoOPME = newOrcamentos
      }

      return i
    })

    setListaOPME(newListaOPME)
  }

  function handleFabricanteChange(opmeId, value) {
    let novaLista = listaOPME
    novaLista = novaLista.map((item, index) => {
      if (item.pacoteProcedimentoOpmeId === opmeId) {
        item.orcamentoOpmeHospital.fabricante = value
      }
      validarOpmeHospital(item)
      return item
    })
    setListaOPME(novaLista)
    // setTimeout(() => {
    // }, 3000)
  }
  function handleValorUnitarioChange(opmeId, value) {
    let novaLista = listaOPME
    novaLista = novaLista.map((item, index) => {
      if (item.pacoteProcedimentoOpmeId === opmeId) {
        item.orcamentoOpmeHospital.valorUnitario = value
      }
      validarOpmeHospital(item)
      return item
    })
    setTimeout(() => {
      setListaOPME(novaLista)
    }, 3000)
  }

  function validarOpmeHospital(itemOpme) {
    const estaValido =
      itemOpme.orcamentoOpmeHospital?.fabricante?.length !== 0 &&
      itemOpme.orcamentoOpmeHospital?.valorUnitario !== 0
    itemOpme.orcamentoOpmeHospital = {
      ...itemOpme.orcamentoOpmeHospital,
      valido: estaValido
    }
  }
  function obterItensSelecionados() {
    const itemSelecionados = listaOPME?.map(i => {
      var itemListaOrcamentoSelecionado = i.listaOrcamentoOPME?.find(
        o => o.selecionado
      )
      var itemHospitalSelecionado =
        i.orcamentoOpmeHospital.selecionado &&
        i.orcamentoOpmeHospital.fabricante?.length > 0 &&
        i.orcamentoOpmeHospital.valorUnitario > 0
      return {
        nome: i.nome,
        quantidade: i.quantidade,
        selecionado:
          !!itemListaOrcamentoSelecionado || !!itemHospitalSelecionado
      }
    })
    return itemSelecionados
  }
  function handleCheckOPMEHospital(opmeId?: string) {
    const newListaOPME = listaOPME?.map(i => {
      if (i.pacoteProcedimentoOpmeId === opmeId) {
        i.listaOrcamentoOPME?.map(o => {
          o.selecionado = false
        })
      }
      validarOpmeHospital(i)
      if (i.pacoteProcedimentoOpmeId === opmeId) {
        if (i.orcamentoOpmeHospital.selecionado) {
          i.orcamentoOpmeHospital = {
            ...i.orcamentoOpmeHospital,
            selecionado: false
          }
        } else {
          i.orcamentoOpmeHospital = {
            ...i.orcamentoOpmeHospital,
            selecionado: true
          }
        }
      }
      return i
    })
    setListaOPME(newListaOPME)
  }

  function handlePropostaSemOPME() {
    setPropostaSemOPME(!propostaSemOPME)

    const newListaOPME = listaOPME?.map(i => {
      const newOrcamentos = i.listaOrcamentoOPME?.map(o => {
        o.selecionado = false

        return o
      })

      i.listaOrcamentoOPME = newOrcamentos
      return i
    })

    setExpanded(undefined)
    setListaOPME(newListaOPME)
  }

  /* -------------------------------------------------- */
  function setContinuar(observacaoExames, ehContemplaExame) {
    // debugger
    setModalContemplaExame(false)
    if (observacaoExames.length > 0) {
      commandOrcar.observacaoExames = observacaoExames
    }
    setContemplaExame(ehContemplaExame)
    // commandOrcar.ehContemplaExame = ehContemplaExame

    onSubmit()
  }

  function validarSelecaoOpmes() {
    const opmeValid = listaOPME.map(o => {
      let valid = false
      if (o.listaOrcamentoOPME?.find(orcamento => orcamento.selecionado)) {
        valid = true
      }
      if (
        o.orcamentoOpmeHospital?.selecionado &&
        o.orcamentoOpmeHospital?.valido
      ) {
        valid = true
      }
      return valid
    })

    const canSubmit = opmeValid.find(o => !o)

    if (canSubmit === false && !propostaSemOPME) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um orçamento para cada OPME solicitado',
        icon: 'error',
        confirmButtonText: 'Fechar'
      })
      return false
    }

    return true
  }

  function abrirModal() {
    if (pacote?.examesPosOperatorio?.length > 0) {
      if (!validarSelecaoOpmes()) {
        setEnviarLoading(false)
        return
      }
      setModalContemplaExame(true)
    } else {
      commandOrcar.ehContemplaExame = false
      onSubmit()
    }
  }

  async function onSubmit() {
    setEnviarLoading(true)

    if (!validarSelecaoOpmes()) {
      setEnviarLoading(false)
      return
    }

    if (!labSelecionado && !!listaExames.length) {
      setEnviarLoading(false)
      return Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um laboratório para realizar os exames',
        icon: 'error',
        confirmButtonText: 'Fechar'
      })
    }

    setSubmitModalEnvio(true)
  }

  if (loading) {
    return (
      <Box minHeight="300px" textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function setStatusOPME(item: IOPMEResponse) {
    const selecionado = item.listaOrcamentoOPME?.find(i => i.selecionado)
    const selecionadoHospital =
      item.orcamentoOpmeHospital?.selecionado &&
      item.orcamentoOpmeHospital?.fabricante?.length > 0 &&
      item.orcamentoOpmeHospital?.valorUnitario > 0
    let background =
      selecionado || selecionadoHospital
        ? COR_STATUS.CONFIGURADO
        : COR_STATUS.NAO_CONFIGURADO

    return (
      <Box
        mr={2}
        className={styles.statusOPME}
        style={{ backgroundColor: background }}
      />
    )
  }

  return (
    <Box>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>
      <HeaderSolicitacao
        solicitacao
        paciente={paciente}
        pacoteProcedimento={pacoteProcedimento}
        medicoNome={medico?.nome}
        comorbidades={paciente?.comorbidades as ObjetoDescricaoGenerico[]}
        obsComorbidades={paciente?.observacaoComorbidades}
      />

      <Grid container spacing={1} className={styles.body}>
        {/* ------------------- Accordion ----------------- */}
        <Grid item xs={12} sm={7}>
          <Box mt={5}>
            {!solicitacaoSemOPME && (
              <Box my={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={propostaSemOPME}
                  onChange={() => handlePropostaSemOPME()}
                />
                <Typography variant="subtitle1">
                  Desejo gerar proposta sem OPME
                </Typography>
              </Box>
            )}
            <Box mb={1} hidden={!propostaSemOPME}>
              <Alert severity="info">
                <Typography variant="body2">
                  Essa proposta não irá conter lista de OPME.
                </Typography>
              </Alert>
            </Box>

            <Box hidden={propostaSemOPME || solicitacaoSemOPME}>
              <Box mb={1}>
                <Typography variant="h6">Lista de OPME solicitados</Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    mr={1}
                    className={styles.statusOPME}
                    style={{ backgroundColor: COR_STATUS.NAO_CONFIGURADO }}
                  />
                  <Typography variant="subtitle2">Não selecionado</Typography>

                  <Box
                    ml={2}
                    mr={1}
                    className={styles.statusOPME}
                    style={{ backgroundColor: COR_STATUS.CONFIGURADO }}
                  />
                  <Typography variant="subtitle2">Selecionado</Typography>
                </Box>
              </Box>
              {listaOPME?.map((item, index) => (
                <Accordion
                  expanded={
                    expanded === item.pacoteProcedimentoOpmeId &&
                    !propostaSemOPME
                  }
                  onChange={handleOpenAccordion(item.pacoteProcedimentoOpmeId)}
                  className={
                    propostaSemOPME ? styles.accordionSemOPME : styles.accordion
                  }
                  key={JSON.stringify(index)}
                >
                  <AccordionSummary
                    expandIcon={!propostaSemOPME ? <ExpandMoreIcon /> : null}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={propostaSemOPME ? { cursor: 'default' } : null}
                  >
                    <Grid
                      className={
                        propostaSemOPME
                          ? styles.accordionSummarySemOPME
                          : styles.accordionSummary
                      }
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {setStatusOPME(item)}
                        <Typography variant="body1">
                          {item?.nome}
                          <Typography component="span">
                            {` - ${item?.quantidade} ${
                              item?.quantidade === 1 ? 'unidade' : 'unidades'
                            }`}
                          </Typography>
                        </Typography>
                      </div>
                      {!propostaSemOPME && (
                        <Typography>
                          <Chip
                            size="small"
                            label={`${
                              item?.listaOrcamentoOPME
                                ? item?.listaOrcamentoOPME?.length
                                : 0
                            } ${
                              item?.listaOrcamentoOPME?.length === 1
                                ? 'orçamento'
                                : 'orçamentos'
                            }
                        `}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Grid container direction="column">
                      <Box mb={2}>
                        {item?.listaOrcamentoOPME?.length > 1 && (
                          <Alert severity="warning">
                            <Typography variant="body2">
                              <b>Observação: </b>
                              Os itens estão sendo exibidos do mais barato para
                              o mais caro.
                            </Typography>
                          </Alert>
                        )}
                        <Alert severity="info">
                          <Typography variant="body2">
                            <b>Marca preferida: </b>
                            {item?.marca ? item?.marca : 'Sem preferência'}
                            <br />
                            <b>Observação do médico: </b>
                            {item?.observacao
                              ? item?.observacao
                              : 'Sem Observação'}
                          </Typography>
                        </Alert>
                      </Box>

                      <Card className={styles.OPMECardHospital}>
                        <Grid container>
                          <Grid item md={1}>
                            <Checkbox
                              checked={
                                item.orcamentoOpmeHospital?.selecionado || false
                              }
                              onChange={() =>
                                handleCheckOPMEHospital(
                                  item.pacoteProcedimentoOpmeId
                                )
                              }
                            />
                          </Grid>
                          <Grid item md={11}>
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginLeft: '20px'
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-beetwen'
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    <strong>Fornecido pelo Hospital</strong>
                                  </Typography>
                                  {!item.orcamentoOpmeHospital?.valido &&
                                    item.orcamentoOpmeHospital?.selecionado &&
                                    (item.orcamentoOpmeHospital?.fabricante
                                      .length === 0 ||
                                      item.orcamentoOpmeHospital
                                        ?.valorUnitario === 0) && (
                                      <Typography
                                        variant="body2"
                                        style={{
                                          color: 'red',
                                          marginLeft: 20
                                        }}
                                      >
                                        Preencha os campos.
                                      </Typography>
                                    )}
                                </div>
                                <div style={{ display: 'flex', gap: 20 }}>
                                  <TextField
                                    autoFocus
                                    className={styles.inputHospital}
                                    size="small"
                                    name="fabricante"
                                    placeholder="Fabricante"
                                    type="text"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    defaultValue={
                                      item.orcamentoOpmeHospital?.fabricante
                                    }
                                    onChange={e => {
                                      // e.preventDefault()
                                      handleFabricanteChange(
                                        item.pacoteProcedimentoOpmeId,
                                        e.target.value
                                      )
                                      // e.target.focus()
                                    }}
                                  />
                                  <NumberFormat
                                    className={styles.numberFormatHospital}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowedDecimalSeparators={[',']}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    isNumericString
                                    prefix="R$ "
                                    type="tel"
                                    defaultValue={
                                      item.orcamentoOpmeHospital?.valorUnitario
                                    }
                                    onValueChange={({ floatValue }) =>
                                      handleValorUnitarioChange(
                                        item.pacoteProcedimentoOpmeId,
                                        floatValue
                                      )
                                    }
                                    placeholder="Valor hospital"
                                    onFocus={e => e.target.select()}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                      {!item?.listaOrcamentoOPME ? (
                        <Typography>
                          Nenhum orçamento de parceiros realizado para este
                          OPME.
                        </Typography>
                      ) : (
                        <>
                          {item?.listaOrcamentoOPME?.map(f => (
                            <Grid container key={f.id}>
                              <Grid item xs={12}>
                                <Card
                                  className={
                                    !ehXS
                                      ? styles.OPMECard
                                      : styles.OPMECardSmall
                                  }
                                >
                                  <Grid container>
                                    <Grid item md={1}>
                                      {f.disponivel && (
                                        <Checkbox
                                          key={f.id + f.selecionado}
                                          checked={f.selecionado}
                                          onChange={() =>
                                            handleCheckOPME(
                                              f.id,
                                              item?.pacoteProcedimentoOpmeId
                                            )
                                          }
                                        />
                                      )}
                                    </Grid>
                                    <Grid item md={11}>
                                      <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            marginLeft: '20px'
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            component="span"
                                          >
                                            {f.nomeParceiro}
                                          </Typography>
                                          <Link
                                            component="button"
                                            variant="subtitle2"
                                            onClick={() => {
                                              setDadosInfoModal(f)
                                              setInfoModal(true)
                                            }}
                                          >
                                            Ver mais
                                          </Link>
                                        </div>

                                        {f.disponivel && (
                                          <Typography variant="body2">
                                            <Box mr={1} component="span">
                                              <Typography
                                                variant="subtitle2"
                                                component="span"
                                              >
                                                Fabricante:
                                              </Typography>
                                            </Box>
                                            <br />
                                            {f.fabricante
                                              ? f.fabricante
                                              : 'Não informado'}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>

            {listaExames?.length !== 0 && (
              <Box width="100" mt="40px">
                <Typography variant="h6">Exames solicitados</Typography>
                <Box mt={2}>
                  {listaExames?.map(item => {
                    return (
                      <Accordion
                        key={item.laboratorioId}
                        className={styles.accordion}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Checkbox
                                checked={labSelecionado === item}
                                onChange={() => setLabSelecionado(item)}
                              />

                              <Typography variant="subtitle2">
                                {item.laboratorioNome}
                              </Typography>
                            </div>

                            <div>
                              <Typography variant="subtitle2">
                                <strong>Valor Total: </strong>
                                {formatarDinheiro(item.valorTotal)}
                              </Typography>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Código</TableCell>
                                <TableCell>Descricao</TableCell>
                                <TableCell>Valor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.exames.map(e => {
                                return (
                                  <TableRow key={e.id}>
                                    <TableCell>{e.codigo}</TableCell>
                                    <TableCell>{e.descricao}</TableCell>
                                    <TableCell>
                                      {formatarDinheiro(e.valor)}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Box>
              </Box>
            )}

            <Box
              style={{
                display: 'flex',
                alignItems: 'end'
              }}
              mb={2}
            >
              <Checkbox
                value={propostaFinaisDeSemana}
                checked={propostaFinaisDeSemana}
                onChange={(e, value) => setPropostaFinaisDeSemana(value)}
              />
              <Typography variant="subtitle1" style={{ lineHeight: '1rem' }}>
                Gerar proposta para finais de semana e feriados
                <br />
                <small>
                  <strong>
                    (sábado a partir de 12h até domingo 23h59 e feriados)
                  </strong>
                </small>
              </Typography>
            </Box>

            <Card>
              <Box px={5} py={5} className={styles.boxResumo}>
                <Box mb={1}>
                  <Typography variant="h6">
                    Informações da solicitação
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Anestesia:{' '}
                    </Typography>
                    <Typography>
                      {pacote?.tiposAnestesia?.length === 0 &&
                        'Procedimento sem necessidade de anestesia'}
                      {verificaAnestesia &&
                        'Procedimento terá anestesia Local realizada pelo próprio cirurgião'}
                      {!!pacote?.tiposAnestesia?.length &&
                        !verificaAnestesia &&
                        pacote?.tiposAnestesia
                          ?.map(e => e.descricao)
                          .join(', ')}
                    </Typography>
                    <Ubox
                      render={
                        orcamento?.valorEquipeAnestesia > 0 &&
                        !verificaAnestesia
                      }
                    >
                      <Alert severity="info">
                        Equipe anestésica disponibilizada pelo médico.
                      </Alert>
                    </Ubox>
                  </Grid>

                  <Grid item xs={12} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Equipamentos hospitalares:{' '}
                    </Typography>
                    <Typography>
                      {pacote?.equipamentos?.length === 0 &&
                        'Nenhum equipamento'}
                      {!!pacote?.equipamentos?.length &&
                        pacote?.equipamentos?.map(e => e.descricao).join(', ')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Materiais específicos:{' '}
                    </Typography>
                    <Typography>
                      {pacote?.materiais?.length === 0 && 'Nenhum material'}
                      {!!pacote?.materiais?.length &&
                        pacote?.materiais?.map(e => e.descricao).join(', ')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} className={styles.cardItem}>
                    {!!pacote?.dayClinic && (
                      <>
                        <Typography variant="h6" color="primary">
                          Day clinic (até 12h de internação)
                        </Typography>
                        <Typography>Sim</Typography>
                        <br />
                      </>
                    )}

                    {!!pacote?.pqa && (
                      <>
                        <Typography variant="h6" color="primary">
                          PQA - Cirúrgia de pequeno porte
                        </Typography>
                        <Typography variant="body1">Sim</Typography>
                        <Alert severity="info">
                          Cirurgias de pequeno porte em que o paciente tem alta
                          hospitalar do centro cirúrgico até 6h após o
                          procedimento.
                        </Alert>
                        <br />
                      </>
                    )}

                    {parseInt(pacote?.diariasUti) > 0 && (
                      <>
                        <Typography variant="h6" color="primary">
                          UTI
                        </Typography>
                        <Typography>
                          {`${pacote?.diariasUti || '0'}  diária(s) de UTI`}
                        </Typography>
                        <br />
                      </>
                    )}

                    {parseInt(pacote?.diariasEnfermaria) > 0 && (
                      <>
                        <Typography variant="h6" color="primary">
                          Enfermaria
                        </Typography>
                        <Typography>
                          {`${
                            pacote?.diariasEnfermaria || '0'
                          } diária(s) de enfermaria`}
                        </Typography>
                        <br />
                      </>
                    )}

                    {parseInt(pacote?.diariasApartamento) > 0 && (
                      <>
                        <Typography variant="h6" color="primary">
                          Apartamento
                        </Typography>
                        <Typography>
                          {`${
                            pacote?.diariasApartamento || '0'
                          } diária(s) de apartamento`}
                        </Typography>
                        <br />
                      </>
                    )}
                  </Grid>
                  {orcamento?.avaliacaoPreAnestesica && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary">
                        Avaliação pré-anestésica{' '}
                      </Typography>
                      <Typography>Sim</Typography>
                      <br />
                    </Grid>
                  )}
                  {orcamento?.riscoCirurgico && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary">
                        Avaliação de risco cirúrgico{' '}
                      </Typography>
                      <Typography>Sim</Typography>
                      <br />
                    </Grid>
                  )}
                  {orcamento?.observacaoExames && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary">
                        Observação dos exames{' '}
                      </Typography>
                      <Typography>
                        {orcamento?.observacaoExames
                          ? orcamento?.observacaoExames
                          : 'Não informado'}
                      </Typography>
                      <br />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Duração média{' '}
                    </Typography>
                    <Typography>
                      {pacote?.mediaTempoCirurgico
                        ? pacote?.mediaTempoCirurgico + 'h'
                        : 'Não informado'}
                    </Typography>
                  </Grid>

                  {!!orcamento?.qtdReservaSange && (
                    <>
                      <Grid item xs={12} md={6} className={styles.cardItem}>
                        <Typography variant="h6" color="primary">
                          Reserva de sangue
                        </Typography>
                        <Typography variant="body2">
                          {orcamento?.qtdReservaSange} concentrados de hemácias
                        </Typography>
                        <br />
                      </Grid>
                    </>
                  )}

                  {!!orcamento?.observacaoSangue && (
                    <>
                      <Grid item xs={12} md={6} className={styles.cardItem}>
                        <Typography variant="h6" color="primary">
                          Observação reserva de sangue
                        </Typography>
                        <Typography variant="body2">
                          {orcamento?.observacaoSangue}
                        </Typography>
                        <br />
                      </Grid>
                    </>
                  )}
                  {!!pacote?.examesPosOperatorio && (
                    <>
                      <Grid item xs={12} md={6} className={styles.cardItem}>
                        <Typography variant="h6" color="primary">
                          Exames durante internação{' '}
                        </Typography>
                        <Typography>
                          {pacote?.examesPosOperatorio || 'Não informado'}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {!!orcamento?.disponibilidade && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="h6" color="primary">
                        Disponibilidade para operar em baixo fluxo{' '}
                      </Typography>
                      <Typography>
                        {orcamento?.disponibilidade || 'Não informado'}
                      </Typography>
                    </Grid>
                  )}
                  {pacote?.observacoes && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="h6" color="primary">
                        Observações gerais do pacote{' '}
                      </Typography>
                      <Typography>{pacote?.observacoes}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Alert severity="info">
                      A taxa de opme será adicionada após a finalização do
                      orçamento
                    </Alert>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Sticky>
            <Box
              px={ehXS ? 1 : 5}
              py={2}
              className={ehXS ? styles.calculoContentXS : styles.calculoContent}
              width="100%"
            >
              <Box>
                {verOpmeSelecionados && (
                  <>
                    <Grid container justify="center">
                      <Typography
                        className={styles.orcamentoTitle}
                        variant="h6"
                      >
                        Lista de OPME solicitados:
                      </Typography>
                      <Divider className={styles.divider} />
                    </Grid>
                  </>
                )}
                {obterItensSelecionados()?.map(opme => {
                  return (
                    opme.selecionado && (
                      <Box key={opme.nome} mb={1}>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-end"
                        >
                          <Typography
                            variant="body2"
                            className={ehXS && styles.calculoText}
                            style={{ maxWidth: '95%' }}
                          >
                            {opme.nome}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={ehXS && styles.calculoText}
                          >
                            Qtd: {'   '}
                            {opme.quantidade}
                            {/* {formatarDinheiro(
                                opme.quantidade * item.valorUnitario
                              ) || ' R$ 0,00'} */}
                          </Typography>
                        </Grid>
                        <Divider className={styles.divider} />
                      </Box>
                    )
                  )
                })}
              </Box>
              <Box mb={1}>
                <Grid container justify="center">
                  <Typography className={styles.orcamentoTitle} variant="h5">
                    <strong>Orçamento: </strong>
                  </Typography>
                </Grid>
                {!!orcamento?.temPlanoDeSaude && (
                  <Box mb={2}>
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        Paciente possui plano de saúde!
                      </Alert>
                    </Grid>
                  </Box>
                )}
                <Grid container>
                  <Typography variant="body2">
                    Valores podem ser: <strong> R$ 0,00 </strong>
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={styles.formGroup}
                  justify="space-between"
                >
                  <Typography variant="body1">Valor do hospital: </Typography>
                  <Controller
                    render={({ onChange, value }) => (
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale
                        allowedDecimalSeparators={[',']}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString
                        prefix="R$ "
                        className={styles.numberFormat}
                        type="tel"
                        value={value}
                        onValueChange={values => onChange(values.floatValue)}
                        placeholder="Valor hospital"
                        onFocus={e => e.target.select()}
                      />
                    )}
                    name="valorSala"
                    control={orcamentoForm.control}
                  />
                </Grid>
                <Ubox
                  render={
                    !orcamento?.valorEquipeAnestesia &&
                    pacote?.tiposAnestesia?.length > 0 &&
                    !verificaAnestesia
                  }
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={styles.formGroup}
                  >
                    <Typography variant="body1">Anestesia: </Typography>
                    <Controller
                      render={({ onChange, value }) => (
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale
                          allowedDecimalSeparators={[',']}
                          decimalSeparator=","
                          thousandSeparator="."
                          isNumericString
                          prefix="R$ "
                          className={styles.numberFormat}
                          type="tel"
                          value={value}
                          onValueChange={values => onChange(values.floatValue)}
                          placeholder="Valor anestesia"
                          onFocus={e => e.target.select()}
                        />
                      )}
                      name="valorAnestesia"
                      control={orcamentoForm.control}
                    />
                  </Grid>
                </Ubox>

                {!!orcamento?.qtdReservaSange && (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={styles.formGroup}
                  >
                    <Typography variant="body1">Reserva de sangue: </Typography>
                    <Controller
                      render={({ onChange, value }) => (
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale
                          allowedDecimalSeparators={[',']}
                          decimalSeparator=","
                          thousandSeparator="."
                          isNumericString
                          prefix="R$ "
                          className={styles.numberFormat}
                          type="tel"
                          value={value}
                          onValueChange={values => onChange(values.floatValue)}
                          placeholder="Valor da reserva das bolsas de sangue"
                          onFocus={e => e.target.select()}
                        />
                      )}
                      name="valorReservaSangue"
                      control={orcamentoForm.control}
                    />
                  </Grid>
                )}

                {!!orcamento?.qtdReservaSange && (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={styles.formGroup}
                  >
                    <Typography variant="body1">Bolsa de sangue: </Typography>
                    <Controller
                      render={({ onChange, value }) => (
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale
                          allowedDecimalSeparators={[',']}
                          decimalSeparator=","
                          thousandSeparator="."
                          isNumericString
                          prefix="R$ "
                          className={styles.numberFormat}
                          type="tel"
                          value={value}
                          onValueChange={values => onChange(values.floatValue)}
                          placeholder="Plasma, concentrados de plaquetas ou outros"
                          onFocus={e => e.target.select()}
                        />
                      )}
                      name="valorBolsaSangue"
                      control={orcamentoForm.control}
                    />
                  </Grid>
                )}

                {orcamento?.avaliacaoPreAnestesica && (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={styles.formGroup}
                  >
                    <Typography variant="body1">
                      Avaliação pré-anestésica:{' '}
                    </Typography>
                    <Controller
                      render={({ onChange, value }) => (
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale
                          allowedDecimalSeparators={[',']}
                          decimalSeparator=","
                          thousandSeparator="."
                          isNumericString
                          prefix="R$ "
                          className={styles.numberFormat}
                          type="tel"
                          value={value}
                          onValueChange={values => onChange(values.floatValue)}
                          onFocus={e => e.target.select()}
                        />
                      )}
                      name="valorPreAnestesica"
                      control={orcamentoForm.control}
                    />
                  </Grid>
                )}

                {orcamento?.riscoCirurgico && (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={styles.formGroup}
                  >
                    <Typography variant="body1">
                      Avaliação Risco Cirúrgico:{' '}
                    </Typography>
                    <Controller
                      render={({ onChange, value }) => (
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale
                          allowedDecimalSeparators={[',']}
                          decimalSeparator=","
                          thousandSeparator="."
                          isNumericString
                          prefix="R$ "
                          className={styles.numberFormat}
                          type="tel"
                          value={value}
                          onValueChange={values => onChange(values.floatValue)}
                          onFocus={e => e.target.select()}
                        />
                      )}
                      name="valorRiscoCirurgico"
                      control={orcamentoForm.control}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextFieldSyn
                    className={styles.inputObservacao}
                    label="Observação"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      autoComplete: 'new-password'
                    }}
                    name="observacao"
                    inputRef={orcamentoForm.register}
                    erro={orcamentoForm.errors}
                  />
                </Grid>
              </Box>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={styles.submitButton}
                  // onClick={() => onSubmit()}
                  onClick={() => abrirModal()}
                  loading={enviarLoading}
                  disabled={enviarLoading}
                  secondaryLoading
                >
                  Enviar
                  {/* Gerar proposta */}
                </Button>
              </Grid>
            </Box>
          </Sticky>
        </Grid>
      </Grid>
      {infoModal && (
        <DadosOPMEModal
          open={infoModal}
          onClose={() => setInfoModal(false)}
          item={dadosInfoModal}
        />
      )}
      {modalContemplaExame && (
        <ModalContemplaExame
          open={modalContemplaExame}
          descricaoExame={pacote?.examesPosOperatorio}
          onClose={() => setModalContemplaExame(false)}
          onFuncao={(observacaoExame, ehContemplaExame) =>
            setContinuar(observacaoExame, ehContemplaExame)
          }
        />
      )}
      {submitModalEnvio && (
        <ModalSubmitProposta
          open={submitModalEnvio}
          onClose={() => {
            setSubmitModalEnvio(false)
            setEnviarLoading(false)
          }}
          listaOpme={!propostaSemOPME && !solicitacaoSemOPME && listaOPME}
          valorOpme={valorTotal}
          valorOpmeHospital={valorTotalHospital}
          valorEquipeAnestesia={valorEquipeAnestesia}
          laboratorio={labSelecionado}
          hospitais={locaisAtendimento
            .map(i => {
              if (i.selecionado) {
                return i
              }

              return
            })
            .filter(i => !!i)}
          honorarioMedico={pacoteProcedimento.honorarioEquipeMedica}
          solicitacaoId={solicitacaoId}
          orcamentoHospital={commandOrcar as IOrcamentoProposta}
          ehHospital={true}
          contemplaExame={contemplaExame}
          propostaFinaisDeSemana={propostaFinaisDeSemana}
          taxaOpmeHospital={parceiro.taxaOPME}
        />
      )}
    </Box>
  )
}
