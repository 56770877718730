import { useEffect, useState } from 'react'
import { obterPacotePorId, obterPacotePorIdPaciente } from 'src/api/pacote'
import { obterPacoteInteligentePorId } from 'src/api/pacoteInteligente'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { IPacoteDetalhadoResponse } from '../../../../types'
import { mapToForm } from '../../medico/solicitacao/formV2'
import { ResumoOrcamento } from '../../medico/solicitacao/resumo'

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
  dadosSensiveis?: boolean
  exibirValores?: boolean
}

function DrawerVisualizarDetalhes({
  id,
  open,
  handleOnClose,
  dadosSensiveis,
  exibirValores = false
}: Props) {
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [pacoteDetalhe, setPacoteDetalhe] = useState(null)
  const [pacoteInteligente, setPacoteInteligente] = useState(false)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)
  const [orcamentoSolicitacao, setOrcamentoSolicitacao] = useState(null)

  useEffect(() => {
    if (!id || !open) {
      return
    }
    setDetalheVisualizacao(null)
    setLoadingDetalhe(true)
    obterOrcamentoPorId(id)
      .then(async response => {
        const orcamento = response.data
        setOrcamentoSolicitacao(orcamento)

        setDetalheVisualizacao(mapToForm(orcamento))
        //Carregar pacote
        var data: IPacoteDetalhadoResponse | IPacoteInteligenteForm = {} as any
        setPacoteInteligente(orcamento.pacoteInstantaneo)
        if (orcamento.pacoteInstantaneo) {
          const response = await obterPacoteInteligentePorId(
            orcamento.pacoteInteligenteId
          )

          data = response.data
        } else if (dadosSensiveis) {
          // TODO: VERIFICAR DADOS Sensiveis
          const response = await obterPacotePorIdPaciente(
            orcamento.pacoteProcedimentoId
          )

          data = response.data
        } else {
          const response = await obterPacotePorId(
            orcamento.pacoteProcedimentoId
          )

          data = response.data
        }

        setPacoteDetalhe(data)
        setLoadingDetalhe(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        handleOnClose()
      })
  }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Ver solicitação"
      handleOnClose={handleOnClose}
    >
      {!!detalheVisualizacao && (
        <ResumoOrcamento
          exibirValores={exibirValores}
          pacoteInteligente={pacoteInteligente}
          orcamento={detalheVisualizacao}
          pacoteSelecionado={pacoteDetalhe}
          dadosSensiveis={dadosSensiveis || false}
          orcamentoSolicitacao={orcamentoSolicitacao}
        />
      )}
    </Drawer>
  )
}

export default DrawerVisualizarDetalhes
