import { Box, Checkbox, TextField, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
  alterarStatusSolicitacaoOrcamento,
  obterLoacaisAtendimentoAsync,
  obterMotivosSolicitacao,
  obterParceirosOPMEPorSolicitacao,
  obterStatusSolicitacaoOrcamento
} from 'src/api/solicitacao-orcamento'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import UBox from 'src/components/ui/Ubox'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import {
  AlterarStatusSolicitacaoOcamentoCommand,
  ObjetoDescricaoGenerico
} from 'src/types'
import Swal from 'sweetalert2'

type Props = {
  id: string
  status: ObjetoDescricaoGenerico
  open: boolean
  handleOnClose: (alterou?: boolean, abrirDrawerComprovante?: boolean) => void
  motivoCancelamento: ObjetoDescricaoGenerico
  hospitais?: ObjetoDescricaoGenerico[]
}

function DrawerAlterarStatus(props: Props) {
  const { perfilAtual } = useAuth()
  const perfisHospitalAdm =
    perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
    perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [listaStatus, setListaStatus] = useState([])
  const [statusSelecionado, setStatusSelecionado] = useState(undefined)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [observacao, setObservacao] = useState('')
  const [checkMedicoOcorrencia, setCheckMedicoOcorrencia] = useState(false)
  const [checkHospitalOcorrencia, setCheckHospitalOcorrencia] = useState(false)
  const [checkOpmeOcorrencia, setCheckOpmeOcorrencia] = useState(false)
  const [motivoSelecionado, setMotivoSelecionado] = useState(undefined)
  const [listaMotivos, setListaMotivos] = useState([])

  const [listaHospitaisSelecionado, setListaHospitaisSelecionado] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [listaHospitais, setListaHospitais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingHospitais, setLoadingHospitais] = useState<boolean>(true)

  const [listaOPMESelecionado, setListaOPMESelecionado] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [listaOPME, setListaOPME] = useState<ObjetoDescricaoGenerico[]>([])
  const [loadingOPME, setLoadingOPME] = useState<boolean>(true)

  useEffect(() => {
    if (!props.id) {
      return
    }
    carregarHospitais()
    carregarOPME()
    setListaHospitaisSelecionado(props.hospitais)
    setStatusSelecionado(props.status)
    setMotivoSelecionado(props.motivoCancelamento)
    popularStatus()
    popularMotivos()
    limparTodosCampos()
  }, [props.open])

  function limparTodosCampos() {
    setObservacao('')
    setCheckMedicoOcorrencia(false)
    setCheckHospitalOcorrencia(false)
    setCheckOpmeOcorrencia(false)
    setListaHospitaisSelecionado([])
    setListaOPMESelecionado([])
  }

  const carregarHospitais = async () => {
    setLoadingHospitais(true)
    return obterLoacaisAtendimentoAsync(props.id)
      .then(response => {
        setListaHospitais(response.data || [])
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  const carregarOPME = async () => {
    setLoadingOPME(true)
    return obterParceirosOPMEPorSolicitacao(props.id)
      .then(response => {
        setListaOPME(response.data || [])
      })
      .finally(() => {
        setLoadingOPME(false)
      })
  }

  async function popularMotivos() {
    try {
      setListaMotivos([])
      setLoadingStatus(true)
      var response = await obterMotivosSolicitacao()
      setListaMotivos(response?.data || [])
      setLoadingStatus(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  async function popularStatus() {
    try {
      setListaStatus([])
      setLoadingStatus(true)
      var response = await obterStatusSolicitacaoOrcamento()
      setListaStatus(response?.data || [])
      setLoadingStatus(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  function handleOnChange(event, newValue) {
    setStatusSelecionado(newValue)
  }

  function handleOnChangeMotivo(event, newValue) {
    setMotivoSelecionado(newValue)
  }

  async function handleSalvar() {
    try {
      var swalResult = {
        isConfirmed: false
      }

      if (statusSelecionado?.id === statusOrcamento.PROCESSO_CANCELADO.Id) {
        if (
          !motivoSelecionado ||
          motivoSelecionado.id === '00000000-0000-0000-0000-000000000000'
        ) {
          Swal.fire(
            'Ops...',
            'O motivo de cancelamento é obrigatório',
            'warning'
          )
          return
        }
      }

      if (checkHospitalOcorrencia && listaHospitaisSelecionado.length === 0) {
        Swal.fire(
          'Ops...',
          'Selecione o hospital para visualizar a ocorrência',
          'warning'
        )
        return
      }

      if (checkOpmeOcorrencia && listaOPMESelecionado.length === 0) {
        Swal.fire(
          'Ops...',
          'Selecione o OPME para visualizar a ocorrência',
          'warning'
        )
        return
      }

      if (statusSelecionado.id === statusOrcamento.EM_ANDAMENTO.Id) {
        swalResult = await Swal.fire({
          title: 'Atenção',
          text: 'Deseja notificar os parceiros da mudança de status?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não'
        })
      }

      setLoadingSalvar(true)
      var command: AlterarStatusSolicitacaoOcamentoCommand = {
        id: props.id,
        statusId: statusSelecionado.id,
        observacao,
        medicoVisualizaOcorrencia: checkMedicoOcorrencia,
        parceiroHospitalVisualizaOcorrencia: checkHospitalOcorrencia,
        parceiroOpmeVisualizaOcorrencia: checkOpmeOcorrencia,
        notificarParceiros: swalResult?.isConfirmed,
        motivoSelecionadoId: motivoSelecionado?.id,
        hospitaisVisualizarOcorrencia: listaHospitaisSelecionado,
        opmeVisualizarOcorrencia: listaOPMESelecionado
      }

      await alterarStatusSolicitacaoOrcamento(command)
      setLoadingSalvar(false)
      props.handleOnClose(
        true,
        command.statusId === statusOrcamento.PAGAMENTO_CONFIRMADO.Id
      )
    } catch (e) {
      setLoadingSalvar(false)
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
    setCheckMedicoOcorrencia(false)
    setCheckHospitalOcorrencia(false)
    setCheckOpmeOcorrencia(false)
  }

  const handleItemHospital = item => {
    setListaHospitaisSelecionado(item)
  }

  const handleItemOPME = item => {
    setListaOPMESelecionado(item)
  }

  return (
    <Drawer
      loading={loadingStatus}
      anchor="right"
      open={props.open}
      titulo="Alterar status da solicitaçao"
      handleOnClose={() => props.handleOnClose()}
    >
      {/* command: {JSON.stringify(props)} */}

      <Autocomplete
        erro={{}}
        name="pacote"
        label="Status"
        loading={loadingStatus}
        value={statusSelecionado}
        options={listaStatus}
        getOptionLabel={option => `${option.descricao}`}
        onChange={handleOnChange}
      />

      {statusSelecionado?.id === statusOrcamento.PROCESSO_CANCELADO.Id && (
        <Autocomplete
          erro={{}}
          name="motivo"
          label="Motivo de Cancelamento"
          loading={loadingStatus}
          value={motivoSelecionado}
          options={listaMotivos}
          getOptionLabel={option => `${option.descricao}`}
          onChange={handleOnChangeMotivo}
        />
      )}

      <Box mt={2}>
        <TextField
          fullWidth
          label="Ocorrência"
          multiline
          rows={6}
          placeholder="Digite alguma ocorrência sobre essa alteração de status..."
          variant="outlined"
          value={observacao}
          onChange={e => setObservacao(e.target.value)}
        />
      </Box>

      {observacao && (
        <Box mt={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={checkMedicoOcorrencia}
            onChange={() => setCheckMedicoOcorrencia(!checkMedicoOcorrencia)}
            defaultChecked={false}
          />
          <Typography> Médico pode visualizar a ocorrência</Typography>
        </Box>
      )}
      {observacao && (
        <>
          <UBox
            render={!perfisHospitalAdm}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Checkbox
              checked={checkHospitalOcorrencia}
              onChange={() =>
                setCheckHospitalOcorrencia(!checkHospitalOcorrencia)
              }
              defaultChecked={false}
            />
            <Typography>Hospital pode visualizar a ocorrência</Typography>
          </UBox>

          {checkHospitalOcorrencia && (
            <Autocomplete
              multiple
              erro={{}}
              name="hospitais"
              label="Selecione o Hospital"
              loading={loadingHospitais}
              value={listaHospitaisSelecionado}
              options={listaHospitais}
              getOptionSelected={option =>
                !!listaHospitaisSelecionado.find(l => l.id === option.id)
              }
              showCheck
              showAddButton
              getOptionLabel={option => `${option.descricao}`}
              onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                handleItemHospital(newValue as ObjetoDescricaoGenerico[])
              }}
            />
          )}
        </>
      )}
      {observacao && (
        <>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={checkOpmeOcorrencia}
              onChange={() => setCheckOpmeOcorrencia(!checkOpmeOcorrencia)}
              defaultChecked={false}
            />
            <Typography>
              {' '}
              Fornecedor OPME pode visualizar a ocorrência
            </Typography>
          </Box>

          {checkOpmeOcorrencia && (
            <Autocomplete
              multiple
              erro={{}}
              name="Fornecedores OPMEs"
              label="Selecione o Fornecedor OPME"
              loading={loadingOPME}
              value={listaOPMESelecionado}
              options={listaOPME}
              getOptionSelected={option =>
                !!listaOPMESelecionado.find(l => l.id === option.id)
              }
              showCheck
              showAddButton
              getOptionLabel={option => `${option.descricao}`}
              onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                handleItemOPME(newValue as ObjetoDescricaoGenerico[])
              }}
            />
          )}
        </>
      )}

      <Box mt={3}>
        <Button
          disabled={!statusSelecionado || loadingSalvar}
          fullWidth
          onClick={handleSalvar}
          loading={loadingSalvar}
          secondaryLoading
        >
          Salvar
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerAlterarStatus
