import { useContext } from 'react'

import PendenciasContext from '../contexts/Pendencias/context'

const usePendencias = () => {
  const auth = useContext(PendenciasContext)
  return auth
}

export default usePendencias
