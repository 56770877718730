import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  configurarPacoteInteligente,
  obterPacoteInteligentePorId,
  obterTemplatePacoteInteligentePorId,
  SalvarPacoteInteligenteCommand
} from 'src/api/pacoteInteligente'
import {
  anestesiaLocalId,
  IPacoteInteligenteForm,
  ItemMaterialInteligente,
  ItemOpmeInteligente
} from 'src/components/pacoteInteligente/FormPacoteInteligente'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQuery } from 'src/hooks/useQuery'
import {
  IExame,
  ObjetoDescricaoGenerico,
  ObjetoDescricaoGenericoValor,
  ObjetoQuantidade
} from 'src/types'
import Swal from 'sweetalert2'

import {
  buscarTodosMembrosEquipe,
  ItemListagemMembroEquipe
} from 'src/api/membroEquipe'
import { obterFornecedoresPorLocalAtendimentoId } from 'src/api/parceiro'
import InputQuantidade from 'src/components/pacoteInteligente/Form/inputQuantidade'
import ResumoPrePacoteInteligente from 'src/components/pacoteInteligente/resumo'
import ResumoPacoteInteligenteOps from 'src/components/pacoteInteligente/resumoOperacional'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import UBox from 'src/components/ui/Ubox'
import { INTERNACOES_OBJ } from 'src/core/consts/internacoes'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { Etapa, Steps } from '../../solicitacao/formUnificadoV2/formUnificadoV2'
import ListagemDeItensOpme from './listagemDeItensOpme'

const exibirValor = true

// let mock = {}

const useStyles = makeStyles(theme => ({
  container: {
    // padding: '30px 20px 40px '
    // margin: '20px auto',
    // borderRadius: '8px'
  },
  BtnCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.common.white,
    '@media (max-width: 1300px)': {
      marginBotton: '10px'
    }
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

const schema = yup.object().shape({
  origemPacote: yup.string().required().label('Origem do pacote'),
  hospital: yup.object().nullable().required().label('Hospital'),
  //step2
  especialidades: yup.array().min(1).required().label('Procedimentos'),
  //step3
  pacotePublico: yup.bool().required().label('Pacote público'),
  listaMedico: yup.array().required().label('Médicos'),
  //step4
  descricao: yup.string().required().label('Nome do pacote'),
  procedimentos: yup.array().min(1).required().label('Procedimentos'),
  mediaTempoCirurgico: yup
    .string()
    .nullable()
    .required()
    .label('Média de tempo')
    .test('media-tempo', 'Média de tempo', value => {
      if (!value) return false
      if (value.trim().length < 5 || value === '00:00') return false
      return true
    }),
  honorarioEquipeMedica: yup.string().required().label('Honorário da equipe'),
  //step5
  opmes: yup.array().label('Lista de OPME'),
  taxaOpme: yup.string().nullable().required().label('Taxa de OPME'),
  //step6
  tiposAnestesia: yup.array().min(0).label('Tipo de anestesia'),
  responsavelAnestesia: yup.string().label('Responsável pela anestesia'),
  //step7
  equipamentos: yup.array().label('Equipamentos hospitalares'),
  //step8
  materiais: yup.array().label('Materiais hospitalares'),
  //step9
  valoresInternacao: yup.array().label('Materiais hospitalares'),
  //step10
  dataExpiracao: yup.string().nullable().label('Data de expiração'),
  //step11
  membros: yup.array().label('Membros'),
  valorTaxaReservaSangue: yup
    .string()
    .nullable()
    // .required()
    .label('Taxa reserva de sangue'),
  valorUnidadeConcentradoHemacias: yup
    .string()
    .nullable()
    // .required()
    .label('Concentrado de hemácias'),
  examesDuranteInternacao: yup.array().min(0).label('Exames')
})

export interface IPacoteForm {
  id?: string
  descricao: string
  procedimentos: ObjetoDescricaoGenerico[]
  exames: ObjetoDescricaoGenerico[]
  tiposAnestesia: ObjetoDescricaoGenerico[]
  mediaTempoCirurgico: string
  opmes: ObjetoQuantidade[]
  equipamentos: ObjetoDescricaoGenerico[]
  materiais?: ObjetoDescricaoGenerico[]
  diariasUti: string
  diariasApartamento: string
  diariasEnfermaria: string
  honorarioEquipeMedica: number
  observacoes: string
  observacoesPaciente: string
  pqa: boolean
  dayClinic?: boolean
  examesPosOperatorio: string
}

export function mapearPacoteParaRequest(
  form: IPacoteInteligenteForm,
  id: string
) {
  let pacote: SalvarPacoteInteligenteCommand = Object.assign({}, form) as any

  pacote.hospitalId = form.hospital.id
  pacote.valorTaxaReservaSangue = form.valorTaxaReservaSangue / 100
  pacote.valorUnidadeConcentradoHemacias =
    form.valorUnidadeConcentradoHemacias / 100

  pacote.opmes = form.opmes.map(i => {
    i.valorUnitario = i.valorUnitario / 100
    i.fornecedorId = i?.fornecedor?.id
    return i
  })

  pacote.especialidades = form.especialidades.map(i => i.id)
  pacote.procedimentos = form.procedimentos.map(i => i.id)
  pacote.listaMedico = form.pacotePublico
    ? null
    : form.listaMedico.map(i => i.id)

  pacote.taxaOpme = form.taxaOpme / 100
  pacote.tiposAnestesia = form.tiposAnestesia.map(i => {
    i.valor = i.valor / 100
    return i
  })

  pacote.equipamentos = form.equipamentos.map(i => {
    i.valor = i.valor / 100
    return i
  })

  pacote.valoresInternacao = form.valoresInternacao.map(i => {
    i.valor = i.valor / 100
    return i
  })

  pacote.materiais = form.materiais.map(i => {
    i.valorUnitario = i.valorUnitario / 100
    return i
  })

  pacote.examesDuranteInternacao = form.examesDuranteInternacao.map(i => {
    i.valorUnitario = i.valorUnitario / 100
    return i
  })

  var membros = JSON.parse(JSON.stringify(form.membros))
  pacote.membros = membros.map(i => {
    i.valor = i.valor / 100
    return i
  })

  pacote.id = id
  pacote.pqa = form.pqa
  pacote.dayClinic = form.dayClinic
  pacote.diariasInternacao = form.diariasInternacao || null
  pacote.diariasUti = !!form.diariasUti ? form.diariasUti : null
  pacote.honorarioEquipeMedica = !!form.honorarioEquipeMedica
    ? form.honorarioEquipeMedica / 100
    : null
  pacote.mediaTempoCirurgico = form.mediaTempoCirurgico

  return pacote
}

type Props = {
  pacoteBase?: IPacoteInteligenteForm
  pacote?: IPacoteInteligenteForm
  setPacote?: (pacote: IPacoteInteligenteForm) => void
  etapaAjuste?: Steps
  handleSalvarAjuste?: (dados: IPacoteInteligenteForm) => void
  handleConfiguracao?: (pacoteId: string, dados: IPacoteInteligenteForm) => void
  stepsFormulario: Etapa<Steps>[]

  activeStep?: Steps
  setActiveStep?: (step: Steps) => void
  medicoId?: string
  medicoCpf?: string
  isback?: (boolean) => void
}

let primeiraVez = false

export const FormConfiguracaoV2 = ({
  stepsFormulario,
  pacote,
  activeStep,
  setActiveStep,
  setPacote,
  medicoCpf,
  medicoId,
  isback,
  ...props
}: Props) => {
  // stepsFormularioConfig = stepsFormulario

  const [stepsFormularioConfig, setStepsFormularioConfig] = useState<
    Etapa<Steps>[]
  >(stepsFormulario)
  // stepsFormularioConfig[1].ativo = false
  const query = useQuery()

  const classes = useStyles()
  const history = useHistory()
  const { user, perfilAtual } = useAuth()

  //#region Definicao de listas e loading
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [listaTiposAnestesia, setListaTiposAnestesia] = useState<
    ObjetoDescricaoGenericoValor[]
  >([])

  const [template, setTemplate] = useState<IPacoteInteligenteForm>()
  const [minDiariasInternacao, setMinDiariasInternacao] = useState<number>(0)

  const [possuiOpmeTerceiro, setPossuiOpmeTerceiro] = useState<boolean>(false)
  const [possuiSangue, setPossuiSangue] = useState<boolean>(false)
  const [esconderEtapaAnestesia, setEsconderEtapaAnestesia] = useState<boolean>(
    false
  )
  const [medicoInformaOpme, setMedicoInformaOpme] = useState<boolean>(false)

  const [listaInternacao, setListaInternacao] = useState<IExame[]>([])
  const [listaExames, setListaExames] = useState<ItemMaterialInteligente[]>([])

  const [listaOPMEInclusos, setListaOPMEInclusos] = useState<
    ItemOpmeInteligente[]
  >([])
  const [listaOPME, setListaOPME] = useState<ItemOpmeInteligente[]>([])
  const [filtro, setFiltro] = useState('');

  const [listaEquipamentos, setListaEquipamentos] = useState<
    ObjetoDescricaoGenericoValor[]
  >([])

  const [listaMembrosEquipe, setListaMembrosEquipe] = useState<
    ItemListagemMembroEquipe[]
  >([])
  const [loadingMembros, setLoadingMembros] = useState<boolean>(true)

  const [listaMateriais, setListaMateriais] = useState<
    ItemMaterialInteligente[]
  >([])
  const [loadingEquipamentos, setLoadingEquipamentos] = useState<boolean>(true)

  const [tiposInternacoes, setTiposInternacoes] = useState<string[]>([])
  const [mostrarDiarias, setMostrarDiarias] = useState<boolean>(false)

  const [listaFornecedores, setListaFornecedores] = useState<
    ObjetoDescricaoGenerico[]
  >([])

  async function obterFornecedoresOpme(localAtendimentoId) {
    return obterFornecedoresPorLocalAtendimentoId(
      localAtendimentoId
    ).then(res => setListaFornecedores(res.data))
    // .catch(err => showNotificationErrorAPI(err))
  }
  //#endregion
  // const [pacote, setPacote] = useState<IPacoteInteligenteForm>()
  const [ajustePacote, setAjustePacote] = useState(false)
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)

  const [showModalProcedimento, toggleModalProcedimento] = useState(false)
  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }

  const [defaultCheckedRadio, setDefaultCheckedRadio] = useState('')

  const handleShowModalProcedimento = (texto: string) => {
    setTextoModal(texto)
    toggleModalProcedimento(true)
  }

  //#region Formulário
  const defaultValues: IPacoteInteligenteForm = {
    origemPacote: 'H', //M- médico | H - hospital
    hospital: null,
    //step2
    especialidades: [], // ObjetoDescricaoGenerico[]
    //step3
    pacotePublico: null,
    listaMedico: [],
    //step4
    descricao: '',
    procedimentos: [],
    //step5
    opmesInclusos: [],
    taxaOpme: 0,
    medicoInformaOpme: false,
    //step6
    tiposAnestesia: [],
    responsavelAnestesia: '',

    //step7
    equipamentos: [],
    //step8
    materiais: [],
    //step9
    valoresInternacao: [],
    //step10
    dataExpiracao: '',
    //step11
    valorTaxaReservaSangue: 0,
    valorUnidadeConcentradoHemacias: 0,
    examesDuranteInternacao: [],
    //step12
    membros: [],

    //configuracao
    semHonorarioMedico: false,
    mediaTempoCirurgico: '',
    diariasUti: 0,
    diariasInternacao: 0,
    honorarioEquipeMedica: 0,
    pqa: false,
    dayClinic: false
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IPacoteInteligenteForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('descricao')
    register('id')
    register('origemPacote')
    register('hospital')
    register('especialidades')
    register('pacotePublico')
    register('listaMedico')
    register('procedimentos')

    register('valorBase')

    register('possuiOpmeTerceiro')
    register('possuiOpme')
    register('medicoInformaOpme')

    register('taxaOpme')
    register('opmes')
    register('tiposAnestesia')
    register('responsavelAnestesia')

    register('equipamentos')
    register('materiais')
    register('possuiMaterial')
    register('valoresInternacao')

    register('dataExpiracao')
    register('valorTaxaReservaSangue')
    register('valorUnidadeConcentradoHemacias')

    register('examesDuranteInternacao')
    register('possuiSangue')

    //config
    register('diariasUti')
    register('diariasInternacao')
    register('honorarioEquipeMedica')
    register('dayClinic')
    register('pqa')
    register('mediaTempoCirurgico')
    register('reservaSangue')
    register('membros')
    register('semHonorarioMedico')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (
    name: keyof IPacoteInteligenteForm | string,
    item
  ) => {
    // console.log(name, item)
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const handleItemValueMembros = item => {
    item.valor = 0
    //console.log(name, item)
    var membro = command?.membros?.find(e => e?.id === item?.id)

    var novaLista = command?.membros
    //remove
    if (membro) {
      novaLista = novaLista.filter(e => e.id !== item.id)
    } else {
      //add
      novaLista = [...novaLista, item]
    }

    setValue('membros', novaLista, {
      shouldValidate: true
    })
  }

  const handleChangeItemValueMembro = (item: any, key: string, value: any) => {
    var membro = command?.membros?.find(e => e?.id === item?.id)

    var novaLista = command?.membros
    //altera
    if (membro) {
      membro[key] = value
      novaLista = novaLista.filter(e => e.id !== item.id)

      novaLista = [...novaLista, membro]
      setValue('membros', novaLista, {
        shouldValidate: true
      })
    }
  }

  function handleChangeValorAnestesia(index: number, valor: number) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    var novoValor = command?.tiposAnestesia[index]
    novoValor['valor'] = valor
    command.tiposAnestesia[index] = novoValor
    var anestesias = command.tiposAnestesia
    // return valorFormatado
    setValue('tiposAnestesia', anestesias, { shouldValidate: true })
  }

  function handleRadiosItem(
    key: keyof IPacoteInteligenteForm,
    value: boolean,
    item?: any,
    keyParam?: string,
    index?: number
  ) {
    var lista = command[key] as any[]
    var novaLista = command[key] as any[]
    if (value) {
      //add
      item.quantidade = !!item.quantidadeIncluso ? item.quantidadeIncluso : 1
      item.selecionado = true
      novaLista = [...lista, item]
    } else {
      //remove
      novaLista = lista.filter((l, i) => l[keyParam] !== item[keyParam])
    }

    setValue(key, novaLista, { shouldValidate: true })
  }

  function handleChangeQuantidade(
    key: keyof IPacoteInteligenteForm,
    value: number,
    item?: ItemMaterialInteligente
  ) {
    var valor = value

    var index = (command[key] as any[]).findIndex(i => i.id === item.id)

    item.quantidade = valor
    item.selecionado = true

    let novoValor = item
    if (index < 0) {
      let lista = command[key] as any[]
      lista.push(novoValor)
    } else {
      command[key][index] = novoValor
      if (valor === 0) {
        var x = (command[key] as any[]).splice(index, 1)
      }
    }

    var lista = command[key]
    // return valorFormatado
    setValue(key, lista, { shouldValidate: true })
  }

  function handleChangeQuantidadeOpme(
    key: keyof IPacoteInteligenteForm,
    value: number,
    item?: ItemOpmeInteligente
  ) {
    var valor = value

    var index = command['opmes'].findIndex(i => i.id === item.id)

    item.quantidade = valor
    item.selecionado = true

    let novoValor = item
    if (index < 0) {
      command['opmes'].push(novoValor)
    } else {
      command['opmes'][index] = novoValor

      if (valor === 0) {
        command['opmes'].splice(index, 1)
      }
    }

    var lista = command['opmes']
    // return valorFormatado
    setValue(key, lista, { shouldValidate: true })
  }

  const handleRadios = e => {
    let valueRadio = e.target.value
    handleItemValue('diariasUti', 0)
    handleItemValue('diariasEnfermaria', 0)
    handleItemValue('diariasInternacao', 0)
    handleItemValue('diariasApartamento', 0)
    handleItemValue('pqa', false)
    handleItemValue('dayClinic', false)
    setMinDiariasInternacao(0)
    setMostrarDiarias(false)
    setDefaultCheckedRadio(valueRadio)
    //#region DIARIAS INTERNACAO
    if (valueRadio === 'withInternation') {
      verificarInternacaoDiaria(pacote)
      setMostrarDiarias(true)
    } else if (valueRadio === 'dayClinic') {
      handleItemValue('dayClinic', true)
    } else {
      handleItemValue('pqa', true)
    }
    //#endregion
  }

  function verificarInternacaoDiaria(
    pacoteInteligente: IPacoteInteligenteForm
  ) {
    var internacao = pacoteInteligente.valoresInternacao.find(
      i => i.id === INTERNACOES_OBJ.INT.id
    )
    if (internacao?.quantidadeIncluso === 0) {
      setMinDiariasInternacao(1)
      setValue('diariasInternacao', 1)
    }

    if (
      !!pacoteInteligente?.diariasInternacao &&
      pacoteInteligente?.diariasInternacao > 0
    ) {
      setValue('diariasInternacao', pacoteInteligente?.diariasInternacao)
    }
  }

  const getDefaultCheckedRadio = (pacote: IPacoteInteligenteForm) => {
    if (pacote.valoresInternacao.length === 1) {
      var internacao = pacote.valoresInternacao[0]
      switch (internacao.id) {
        case INTERNACOES_OBJ.DAY_CLINIC.id:
          setDefaultCheckedRadio('dayClinic')
          setValue('dayClinic', true)
          break
        case INTERNACOES_OBJ.PQA.id:
          setValue('pqa', true)
          setDefaultCheckedRadio('pqa')
          break
        // case INTERNACOES_OBJ.APT.id:
        case INTERNACOES_OBJ.INT.id:
          setDefaultCheckedRadio('withInternation')
          verificarInternacaoDiaria(pacote)
          setMostrarDiarias(true)
          // setValue('diariasInternacao', internacao.quantidadeIncluso)
          break
        case INTERNACOES_OBJ.UTI.id:
          setDefaultCheckedRadio('withInternation')
          setMostrarDiarias(true)
          // setValue('diariasUti', internacao.quantidadeIncluso)
          break
      }
    } else {
      // let existePqa = false
      // let existeDayClinic = false
      // let existeDiaria = false
      let tipos = []
      pacote?.valoresInternacao?.forEach(internacao => {
        switch (internacao.id) {
          case INTERNACOES_OBJ.DAY_CLINIC.id:
            tipos.push(INTERNACOES_OBJ.DAY_CLINIC.codigo)
            break
          case INTERNACOES_OBJ.PQA.id:
            tipos.push(INTERNACOES_OBJ.PQA.codigo)
            break
          case INTERNACOES_OBJ.INT.id:
          case INTERNACOES_OBJ.UTI.id:
            if (!tipos.includes('DIARIAS')) tipos.push('DIARIAS')
            break
        }
      })

      if (tipos.length === 1) {
        switch (tipos[0]) {
          case INTERNACOES_OBJ.DAY_CLINIC.codigo:
            setDefaultCheckedRadio('dayClinic')
            setValue('dayClinic', true)
            break
          case INTERNACOES_OBJ.PQA.codigo:
            setValue('pqa', true)
            setDefaultCheckedRadio('pqa')
            break
          case 'DIARIAS':
            setDefaultCheckedRadio('withInternation')
            verificarInternacaoDiaria(pacote)
            setMostrarDiarias(true)
            break
        }
      }

      setTiposInternacoes(tipos)
    }

    if (!!pacote.pqa) {
      setDefaultCheckedRadio('pqa')
      setValue('pqa', true)
    }

    if (!!pacote.dayClinic) {
      setDefaultCheckedRadio('dayClinic')
      setValue('dayClinic', true)
    }

    if (!!pacote.diariasInternacao) {
      setDefaultCheckedRadio('withInternation')
      verificarInternacaoDiaria(pacote)
      // setValue('dayClinic', true)
      setMostrarDiarias(true)
    }
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'equipamentos':
        setValue('equipamentos', [...command.equipamentos, item], {
          shouldValidate: true
        })
        break
      case 'materiais':
        setValue('materiais', [...command.materiais, item], {
          shouldValidate: true
        })
        break
      case 'tiposAnestesia':
        setValue('tiposAnestesia', [...command.tiposAnestesia, item], {
          shouldValidate: true
        })
        break
      case 'opmes':
        item.quantidade = 1
        setValue('opmes', [...command.opmesInclusos, item], {
          shouldValidate: true
        })
        break
      case 'procedimento':
        setValue('procedimento', item, {
          shouldValidate: true
        })
        break
    }
  }
  //#endregion

  //#region MOUNT
  useEffect(() => {
    carregarMembros()
    // carregarTiposAnestesia()
    // carregarOPME()
    // carregarEquipamentos()
    // carregarMateriais()
    // const ehAjuste = false
    const ehAjuste = verificaEhAjuste()
    if (!ehAjuste) verificaEhEdicao()
  }, [])

  useEffect(() => {
    if (
      command?.membros?.length > 0 &&
      listaMembrosEquipe?.length > 0 &&
      !primeiraVez
    ) {
      primeiraVez = true
      setTimeout(() => {
        var membros = command.membros
        membros = membros.filter(m =>
          listaMembrosEquipe.find(lm => lm.id === m.id)
        )
        setValue('membros', membros, { shouldValidate: false })
      }, 1000)
    }
  }, [listaMembrosEquipe, command.membros])

  async function carregarMembros() {
    var medicoId = query.get('medicoSolicitacaoId')
    var cpf = perfilAtual === SynPerfis.MEDICO ? user.cpf : medicoId
    var response = await buscarTodosMembrosEquipe(cpf)

    var { data } = response
    setLoadingMembros(false)
    setListaMembrosEquipe(data)
  }

  function verificaEhAjuste() {
    if (pacote !== undefined && props.etapaAjuste !== undefined) {
      // getDefaultCheckedRadio(props.pacote)

      // for (const key in props.pacote) {
      //   //console.log(key, pacote[key])
      //   setValue(key, props.pacote[key], { shouldValidate: false })
      // }

      montarPacoteAjuste(pacote)
      setLoading(true)
      obterPacoteInteligentePorId(pacote.id)
        .then(response => {
          const pacoteResponse = response.data

          carregarOpcoes(pacoteResponse)
          setLoading(false)
        })
        .catch(e => {
          showNotificationErrorAPI(e)
          history.goBack()
        })
      setAjustePacote(true)
      setActiveStep(props.etapaAjuste)

      return true
    }

    return false
  }

  function verificaEhEdicao(id?: string) {
    id = pacote?.id || query.get('idPacote')

    if (!id) {
      setLoading(false)
      return
    }

    obterTemplatePacoteInteligentePorId(
      !!medicoCpf ? medicoCpf : user.cpf,
      pacote.id
    )
      .then(response => {
        const pacoteResponse = response.data

        var ehTemplate = false
        if (pacote.id !== pacoteResponse.id) {
          setTemplate(pacoteResponse)
          ehTemplate = true
        }

        configurarPacote(pacoteResponse, ehTemplate)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  function configurarPacote(
    pacote: IPacoteInteligenteForm,
    ehTemplate = false
  ) {
    if (ehTemplate) {
      montarPacoteAjuste(pacote, ehTemplate)
    } else {
      montarPacote(pacote)
    }
    carregarOpcoes(pacote)
    setLoading(false)
  }

  function montarPacoteAjuste(
    pacote: IPacoteInteligenteForm,
    ehTemplate = false
  ) {
    setPacote(pacote)

    for (const key in pacote) {
      if (ehTemplate && key === 'honorarioEquipeMedica' && !!pacote[key]) {
        setValue('honorarioEquipeMedica', pacote[key] * 100, {
          shouldValidate: false
        })
      } else {
        setValue(key, pacote[key], { shouldValidate: false })
      }
    }

    //OPME
    let opmesInclusos = pacote?.opmesInclusos?.filter(i => i?.selecionado) || []
    let opmes = pacote?.opmes?.filter(i => i?.selecionado) || []
    setValue('opmes', [...opmes, ...opmesInclusos], { shouldValidate: false })
    //Material
    let materiais = pacote?.materiais?.filter(i => i?.selecionado)
    setValue('materiais', materiais || [], { shouldValidate: false })

    //Equipamentos
    let equipamentos = pacote?.equipamentos?.filter(i => i?.selecionado)
    setValue('equipamentos', equipamentos || [], { shouldValidate: false })

    //Anestesia
    let tiposAnestesia = pacote?.tiposAnestesia?.filter(i => i?.selecionado)
    setValue('tiposAnestesia', tiposAnestesia || [], { shouldValidate: false })

    //Exame
    let exames = pacote?.examesDuranteInternacao?.filter(i => i?.selecionado)
    setValue('examesDuranteInternacao', exames || [], { shouldValidate: false })

    // if (!!pacote?.membros) {
    //   var membros = pacote?.membros.map(i => {
    //     i.valor = i.valor
    //     return i
    //   })
    //   setValue('membros', membros, { shouldValidate: false })
    // }
    obterFornecedoresOpme(pacote?.hospital.id)
    getDefaultCheckedRadio(pacote)
  }

  function montarPacote(pacote: IPacoteInteligenteForm, template = false) {
    setPacote(pacote)

    for (const key in pacote) {
      //console.log(key, pacote[key])
      if (key === 'honorarioEquipeMedica' && !!pacote[key]) {
        setValue('honorarioEquipeMedica', pacote[key] * 100, {
          shouldValidate: false
        })
      } else {
        setValue(key, pacote[key], { shouldValidate: false })
      }
    }

    if (!!pacote?.membros) {
      var membros = pacote?.membros.map(i => {
        i.valor = parseInt((i.valor * 100).toFixed(0))
        return i
      })

      setValue('membros', membros, { shouldValidate: false })
    }

    if (!pacote?.honorarioEquipeMedica) {
      setValue('honorarioEquipeMedica', 0, { shouldValidate: false })
    }

    if (!pacote?.mediaTempoCirurgico) {
      setValue('mediaTempoCirurgico', '', { shouldValidate: false })
    }

    //OPME
    if (!template) {
      let novo = pacote?.opmesInclusos
        ?.filter(o => o?.quantidadeIncluso > 0)
        .map(o => {
          o.quantidade = o.quantidadeIncluso
          o.selecionado = true
          return o
        })
      setValue('opmes', novo || [], { shouldValidate: false })

      // else {
      // let opmes = pacote?.opmesInclusos
      //   ?.filter(o => o?.selecionado)
      //   .map(o => {
      //     o.selecionado = true
      //     return o
      //   })
      // setValue('opmes', opmes || [], { shouldValidate: false })
      // }

      //Material
      let materiais = pacote?.materiais
        ?.filter(i => i?.quantidadeIncluso > 0)
        .map(o => {
          o.quantidade = o.quantidadeIncluso
          o.selecionado = true
          return o
        })
      setValue('materiais', materiais || [], { shouldValidate: false })

      //Equipamentos
      let equipamentos = pacote?.equipamentos
        ?.filter(i => i?.valorIncluso)
        .map(i => {
          i.selecionado = true
          return i
        })
      setValue('equipamentos', equipamentos || [], { shouldValidate: false })

      //Anestesia
      if (
        pacote?.tiposAnestesia?.length === 1 &&
        pacote?.responsavelAnestesia === 'H'
      ) {
        let tiposAnestesia = pacote?.tiposAnestesia.map(i => {
          i.selecionado = true
          return i
        })
        setEsconderEtapaAnestesia(true)
        setValue('tiposAnestesia', tiposAnestesia || [], {
          shouldValidate: false
        })

        var index = stepsFormularioConfig.findIndex(
          s => s.step === Steps.anestesia
        )
        stepsFormularioConfig[index].ativo = false

        setStepsFormularioConfig(stepsFormulario)
      } else {
        let tiposAnestesia = pacote?.tiposAnestesia
          ?.filter(i => i?.valorIncluso)
          .map(i => {
            i.selecionado = true
            return i
          })
        setValue('tiposAnestesia', tiposAnestesia || [], {
          shouldValidate: false
        })
      }

      //Exame
      let exames = pacote?.examesDuranteInternacao
        ?.filter(i => i?.quantidadeIncluso > 0)
        ?.map(o => {
          o.quantidade = o.quantidadeIncluso
          o.selecionado = true
          return o
        })
      setValue('examesDuranteInternacao', exames || [], {
        shouldValidate: false
      })
    }
    obterFornecedoresOpme(pacote?.hospital.id)
    getDefaultCheckedRadio(pacote)
  }

  function carregarOpcoes(pacote: IPacoteInteligenteForm) {
    setListaEquipamentos(pacote.equipamentos)
    setListaMateriais(pacote.materiais)
    setListaTiposAnestesia(pacote.tiposAnestesia)
    setListaExames(pacote.examesDuranteInternacao)
    setListaInternacao(pacote.valoresInternacao as IExame[])

    setListaOPME(pacote.listaOpmesDisponiveis)
    setListaOPMEInclusos(pacote.opmesInclusos)
    // setListaOPME(pacote.opmes)
    setPossuiOpmeTerceiro(pacote.possuiOpmeTerceiro)
    setPossuiSangue(pacote.possuiSangue)
    setMedicoInformaOpme(pacote.medicoInformaOpme)

    // if (!pacote.possuiSangue) {
    //   var index = stepsFormulario.findIndex(s => s.step === Steps.reservaSangue)
    //   stepsFormulario[index].ativo = false
    // }

    if (pacote?.materiais?.length === 0 && pacote?.equipamentos?.length === 0) {
      var index = stepsFormularioConfig.findIndex(
        s => s.step === Steps.equipamentosMat
      )
      stepsFormularioConfig[index].ativo = false
    }

    if (pacote?.examesDuranteInternacao?.length === 0) {
      var index = stepsFormularioConfig.findIndex(s => s.step === Steps.exames)
      stepsFormularioConfig[index].ativo = false
    }

    if (pacote?.tiposAnestesia?.length === 0) {
      var index = stepsFormularioConfig.findIndex(
        s => s.step === Steps.anestesia
      )
      stepsFormularioConfig[index].ativo = false
    }

    if (
      pacote?.opmes?.length === 0 &&
      pacote?.opmesInclusos?.length === 0 &&
      !pacote.medicoInformaOpme
    ) {
      var index = stepsFormularioConfig.findIndex(s => s.step === Steps.opme)
      stepsFormularioConfig[index].ativo = false
    }

    setStepsFormularioConfig(stepsFormularioConfig)
  }
  //#endregion

  const onSubmit = async () => {
    setLoadingSubmit(true)
    const id = query.get('idPacote') || pacote?.id

    // let pacote = mapearPacoteParaRequest(mock, id)
    let request = mapearPacoteParaRequest(command, id)
    request.fluxoConfiguracaoSolicitacao = true
    if (perfilAtual === SynPerfis.MEDICO) {
      request['medicoCpf'] = user?.cpf
    } else {
      if (medicoCpf) {
        request['medicoCpf'] = medicoCpf
      }
    }

    return configurarPacoteInteligente(request)
      .then(response => {
        props.handleConfiguracao(response.data, pacote)
      })
      .catch(error => showNotificationErrorAPI(error))
      .finally(() => setLoadingSubmit(false))
  }

  function obterMembroSelecionadoPorId(id: string) {
    var x = command?.membros?.find(e => id === e?.id)
    return x
  }

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper

  // const [activeStep, setActiveStep] = React.useState<string>(
  //   Steps.resumo as string
  // )
  // Steps.membrosEquipe as string
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  const lastStep = obterStepsAtivos().length
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)
  const stepAtual = obterStepAtual() + 1

  const handleNext = async () => {
    var indexAtual = obterStepAtual()
    var ativos = obterStepsAtivos()
    var stepAtual = ativos[indexAtual]

    var campos = stepAtual.validacao

    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }

    if (stepAtual.step === Steps.honorarioMedico) {
      await onSubmit()
      return
    }

    setActiveStep(ativos[indexAtual + 1].step)
  }

  function obterStepsAtivos(): Etapa<Steps>[] {
    let stepsAtivos = stepsFormularioConfig.filter(step => step.ativo)
    return stepsAtivos
  }

  function obterStepAtual() {
    let stepsAtivos = obterStepsAtivos()
    let index = stepsAtivos.findIndex(step => step.step === activeStep)
    return index
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    let stepsAtivos = obterStepsAtivos()

    var indexAtual = obterStepAtual()

    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(stepsAtivos[indexAtual - 1].step)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  function handleSalvarAjuste() {
    if (props.handleSalvarAjuste) {
      var qtdDiariasInternacao = !!command.diariasInternacao
        ? command.diariasInternacao
        : 0

      var qtdDiariasUti = !!command.diariasUti ? command.diariasUti : 0
      if (
        !command.pqa &&
        !command.dayClinic &&
        obterQtdTotalDiarias() === 0 &&
        obterQtdTotalDiarias(true) === 0
      ) {
        Swal.fire({
          title: 'Atenção!',
          text:
            obterQtdTotalDiarias() === 0 && obterQtdTotalDiarias(true) === 0
              ? 'Informe a quantidade de diárias '
              : 'Selecione um tipo de internação',
          icon: 'warning',
          cancelButtonText: 'Ok'
        })
        return
      }
      props.handleSalvarAjuste(command)
    }
  }
  //#endregion

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function obterOpmePorItem(item: ItemOpmeInteligente) {
    return command?.opmes?.find(e => item?.id === e?.id)
  }

  function obterMaterialPorItem(item: ItemMaterialInteligente) {
    return command?.materiais?.find(e => item?.id === e?.id)
  }

  function obterExamesPorItem(item: ItemMaterialInteligente) {
    return command?.examesDuranteInternacao?.find(e => item?.id === e?.id)
  }

  function obterQtdTotalDiarias(ehUti = false) {
    if (ehUti) {
      return command.diariasUti + obterQtdDiariasIclusas(true)
    } else {
      return command.diariasInternacao + obterQtdDiariasIclusas()
    }
  }

  function obterQtdDiariasIclusas(ehUti = false) {
    if (!ehUti) {
      return command.valoresInternacao.find(
        i => i.id === INTERNACOES_OBJ.INT.id
      )?.quantidadeIncluso
    } else {
      return command.valoresInternacao.find(
        i => i.id === INTERNACOES_OBJ.UTI.id
      )?.quantidadeIncluso
    }
  }

  const obterListaFiltrada = () => {
    let novaLista = [...listaOPME];

    if (filtro === 'fornecedor') {
      // Agrupar por fornecedor e ordenar por valorUnitario
      return novaLista.sort((a, b) => {
        if (a.fornecedor.descricao < b.fornecedor.descricao) return -1;
        if (a.fornecedor.descricao > b.fornecedor.descricao) return 1;
        return a.valorUnitario - b.valorUnitario;
      });
    } else if (filtro === 'descricao') {
      // Agrupar por descrição e ordenar por valorUnitario
      return novaLista.sort((a, b) => {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return a.valorUnitario - b.valorUnitario;
      });
    } else if (filtro === 'valor') {
      // Ordenar diretamente por valorUnitario
      return novaLista.sort((a, b) => a.valorUnitario - b.valorUnitario);
    }
    return novaLista; // Se nenhum filtro, retorna a lista original
  };

  return (
    <>
      {(loading || loadingSubmit) && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}
      <Box
        component="main"
        hidden={loading || loadingSubmit}
        className={`fadeInTransition ${classes.container}`}
      // style={{ wordBreak: 'break-all' }}
      >
        <CssBaseline />
        {/* command: {JSON.stringify(command)} */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* locais: {JSON.stringify(locais)} */}
        {ehXS && !ajustePacote && (
          <Box
            px={4}
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="body1"
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {stepAtual} de {lastStep}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={normalise(stepAtual)}
            />
          </Box>
        )}
        {!ajustePacote && pacote?.configurado && (
          <Box mb={3}>
            <Alert severity="warning">
              <b>Este é um pacote compartilhado!</b>
              <br />
              Revise as informações de cada etapa e clique em "Continuar".
              <br />
              No último passo clique em "Salvar" para confirmar sua revisão.
            </Alert>
          </Box>
        )}
        <Grid container>
          {!ehXS && !ajustePacote && (
            <Grid item xs={12} sm={1}>
              <Box mt={5} pt={2} ml={4}>
                <Stepper
                  connector={<StepConnectorSyn />}
                  activeStep={obterStepAtual()}
                  style={{ margin: 0, padding: 0, background: 'none' }}
                  orientation="vertical"
                >
                  {getSteps(lastStep)}
                </Stepper>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={ajustePacote ? 12 : 10}>
            <Box mt={ehXS ? 2 : 5} pt={ehXS ? 0 : 2} p={ehXS ? 2 : 0}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.form}
                noValidate={true}
              >
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.resumo}
                  handleBackToStep={handleBackToStep}
                  hideBack
                  //hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleNext={handleNext}
                  textoTitulo={
                    'Agora vamos configurar o pacote selecionado. Fique atento às próximas etapas.'
                  }
                >
                  {pacote?.id && (
                    <ResumoPrePacoteInteligente
                      medico
                      adaptarValor={false}
                      pacote={pacote}
                    />
                  )}
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.opme}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Informe os itens de OPME necessários para realizar o procedimento.'
                  }
                >
                  <ListagemDeItensOpme
                    listaOPME={listaOPME}
                    command={command}
                    handleRadiosItem={handleRadiosItem}
                    handleChangeQuantidadeOpme={handleChangeQuantidadeOpme}
                    obterOpmePorItem={obterOpmePorItem}
                    exibirValor={exibirValor}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.anestesia}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Qual o tipo de anestesia será usada?'}
                >
                  <Grid container spacing={0}>
                    {listaTiposAnestesia.map((a, i) => (
                      <Grid key={'ta' + i} container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          sm={a.id === anestesiaLocalId ? 12 : 7}
                          style={{
                            padding: '10px',
                            display: 'flex',
                            flexDirection:
                              a.id === anestesiaLocalId ? 'column' : 'row',
                            alignItems:
                              a.id === anestesiaLocalId ? 'start' : 'center'
                          }}
                        >
                          <UBox
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <UBox width="36px">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        !!command?.tiposAnestesia.find(
                                          e => a?.id === e?.id
                                        )
                                      }
                                      onChange={(e, value) =>
                                        handleRadiosItem(
                                          'tiposAnestesia',
                                          value,
                                          a,
                                          'descricao'
                                        )
                                      }
                                      value={a.descricao}
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            </UBox>
                            <UBox>
                              <UBox>
                                <Typography variant="body1">
                                  <b>{a.descricao}</b>
                                </Typography>
                              </UBox>
                              <UBox>
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: '555',
                                    marginTop: 0,
                                    lineHeight: 0.95
                                  }}
                                >
                                  <small>
                                    <b>
                                      {!a.valorIncluso && exibirValor && (
                                        <MsgAcrescimo />
                                      )}
                                    </b>
                                  </small>
                                </Typography>
                              </UBox>
                            </UBox>
                          </UBox>
                          <UBox width="100%" render={a.id === anestesiaLocalId}>
                            <Alert severity="info">
                              Anestesia local é responsabilidade da equipe
                              médica.
                            </Alert>
                          </UBox>
                        </Grid>

                        <UBox
                          component={Grid}
                          item
                          xs={12}
                          sm={5}
                          render={
                            command?.responsavelAnestesia === 'M' &&
                            a.id !== anestesiaLocalId &&
                            !!command?.tiposAnestesia?.find(t => t.id === a.id)
                          }
                        >
                          <FormControl
                            fullWidth
                            size="medium"
                            variant="outlined"
                          >
                            <CurrencyInput
                              fullWidth
                              erro={errors}
                              size="small"
                              variant="outlined"
                              name="valorAnestesia"
                              label="Valor"
                              hiddenLabel
                              control={control}
                              type="tel"
                              InputLabelProps={{
                                shrink: true
                              }}
                              onValueChange={value =>
                                handleChangeValorAnestesia(i, value)
                              }
                              value={command?.tiposAnestesia[i]?.valor || 0}
                            />
                          </FormControl>
                        </UBox>
                      </Grid>
                    ))}
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.equipamentosMat}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Quais materiais ou equipamentos são utilizados no procedimento?'
                  }
                >
                  <Typography variant="h6" color="primary">
                    <b>
                      <small>Equipamentos</small>
                    </b>
                  </Typography>
                  {listaEquipamentos.map((equipamento, i) => (
                    <Grid key={'eq' + i} container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <UBox width="36px">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox

                                  checked={
                                    !!command?.equipamentos.find(
                                      e => equipamento?.id === e?.id
                                    )
                                  }
                                  onChange={(e, value) =>
                                    handleRadiosItem(
                                      'equipamentos',
                                      value,
                                      equipamento,
                                      'id'
                                    )
                                  }
                                  value={equipamento.descricao}
                                />
                              }
                              label=""
                            />
                          </FormGroup>
                        </UBox>
                        <UBox>
                          <UBox>
                            <Typography variant="body1">

                              {equipamento.descricao}

                            </Typography>
                          </UBox>
                          <UBox>
                            <Typography
                              variant="body2"
                              style={{
                                color: '555',
                                marginTop: 0,
                                lineHeight: 0.95
                              }}
                            >
                              <small>
                                <b>
                                  {

                                    exibirValor && !equipamento.valorIncluso && (
                                      <MsgAcrescimo />

                                    )
                                  }
                                </b>
                              </small>
                            </Typography>
                          </UBox>
                        </UBox>
                      </Grid>

                      <Grid item xs={12}>
                        <UBox my={1}>
                          <Divider />
                        </UBox>
                      </Grid>
                    </Grid>
                  ))}

                  <UBox render={!!command.possuiMaterial}>
                    <Typography variant="h6" color="primary">
                      <b>
                        <small>Materiais</small>
                      </b>
                    </Typography>
                    {listaMateriais.map((material, i) => (
                      <Grid key={'mat' + i} container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <UBox width="36px">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox

                                    checked={
                                      !!command?.materiais.find(
                                        e => material?.id === e?.id
                                      )
                                    }
                                    onChange={(e, value) =>
                                      handleRadiosItem(
                                        'materiais',
                                        value,
                                        material,
                                        'id'
                                      )
                                    }
                                    value={material.descricao}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </UBox>
                          <UBox>
                            <UBox>
                              <Typography
                                variant="body1"
                                style={{ textTransform: 'capitalize' }}
                              >

                                {material.descricao}

                              </Typography>
                            </UBox>
                            <UBox>
                              <Typography
                                variant="body2"
                                style={{
                                  color: '555',
                                  marginTop: 0,
                                  lineHeight: 0.95
                                }}
                              >
                                <small>
                                  <b>
                                    {material.quantidadeIncluso > 0 &&
                                      `${material.quantidadeIncluso} unid. fornecido pelo hospital`}
                                    {exibirValor &&
                                      (material.podeAcrescentar ||
                                        material.podeSubtrair) && (
                                        <MsgAcrescimo
                                          qtdMax={
                                            material.quantidadeIncluso || 0
                                          }
                                        />

                                      )}
                                  </b>
                                </small>
                              </Typography>
                            </UBox>
                          </UBox>
                        </Grid>

                        <UBox
                          render={
                            !!command?.materiais?.find(
                              e => material?.id === e?.id
                            ) &&
                            (material.podeAcrescentar ||
                              material.podeSubtrair ||
                              material.quantidadeIncluso === 0)
                          }
                          component={Grid}
                          item
                          xs={12}
                          sm={5}
                        >
                          <InputQuantidade
                            onValueChange={value =>
                              handleChangeQuantidade(
                                'materiais',
                                value,
                                material
                              )
                            }
                            max={
                              !!material?.podeAcrescentar
                                ? 999
                                : material?.quantidadeIncluso || 1
                            }
                            min={
                              !!material?.podeSubtrair
                                ? 0
                                : material?.quantidadeIncluso || 1
                            }
                            value={
                              obterMaterialPorItem(material)?.quantidade || 0
                            }
                          />
                        </UBox>

                        <Grid item xs={12}>
                          <UBox my={1}>
                            <Divider />
                          </UBox>
                        </Grid>
                      </Grid>
                    ))}
                  </UBox>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.exames}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Quais exames seu paciente deve realizar durante a internação?'
                  }
                >
                  <Typography variant="h6" color="primary">
                    <b>
                      <small>Exames</small>
                    </b>
                  </Typography>
                  {listaExames.map((exame, i) => (
                    <Grid key={'ex' + i} container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <UBox width="36px">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox

                                  checked={
                                    !!command?.examesDuranteInternacao.find(
                                      e => exame?.id === e?.id
                                    )
                                  }
                                  onChange={(e, value) =>
                                    handleRadiosItem(
                                      'examesDuranteInternacao',
                                      value,
                                      exame,
                                      'id'
                                    )
                                  }
                                  value={exame.id}
                                />
                              }
                              label=""
                            />
                          </FormGroup>
                        </UBox>
                        <UBox>
                          <UBox>
                            <Typography
                              variant="body1"
                              style={{ textTransform: 'uppercase' }}
                            >

                              {exame.descricao}

                            </Typography>
                          </UBox>
                          <UBox>
                            <Typography
                              variant="body2"
                              style={{
                                color: '555',
                                marginTop: 0,
                                lineHeight: 0.95
                              }}
                            >
                              <small>
                                <b>
                                  {exame.quantidadeIncluso > 0 &&
                                    `${exame.quantidadeIncluso} unid. fornecido pelo hospital`}
                                  {exibirValor &&
                                    (exame.podeAcrescentar ||
                                      exame.podeSubtrair) && (
                                      <MsgAcrescimo
                                        qtdMax={exame.quantidadeIncluso || 0}
                                      />

                                    )}
                                </b>
                              </small>
                            </Typography>
                          </UBox>
                        </UBox>
                      </Grid>

                      <UBox
                        render={
                          !!command?.examesDuranteInternacao?.find(
                            e => exame?.id === e?.id
                          ) &&
                          (exame.podeAcrescentar ||
                            exame.podeSubtrair ||
                            exame.quantidadeIncluso === 0)
                        }
                        component={Grid}
                        item
                        xs={12}
                        sm={5}
                      >

                        <InputQuantidade
                          onValueChange={value =>
                            handleChangeQuantidade(
                              'examesDuranteInternacao',
                              value,
                              exame
                            )
                          }
                          max={
                            !!exame?.podeAcrescentar
                              ? 999
                              : exame?.quantidadeIncluso || 1
                          }
                          min={
                            !!exame?.podeSubtrair
                              ? 0
                              : exame?.quantidadeIncluso || 1
                          }
                          value={obterExamesPorItem(exame)?.quantidade || 0}
                        />

                      </UBox>

                      <Grid item xs={12}>
                        <UBox my={1}>
                          <Divider />
                        </UBox>
                      </Grid>
                    </Grid>
                  ))}
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.internacao}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Qual o tipo de internação para esse procedimento?'
                  }
                  style={{ padding: '18px' }}
                >
                  <UBox
                    render={
                      !!command?.valoresInternacao.filter(
                        i => i.valorIncluso || i.quantidadeIncluso > 0
                      )?.length
                    }
                  >
                    <Typography variant="body1">
                      <b>Incluso no pacote:</b>
                    </Typography>

                    {command.valoresInternacao
                      .filter(i => i.valorIncluso || i.quantidadeIncluso > 0)
                      .map(
                        i =>
                          `${i.quantidadeIncluso > 0
                            ? i.quantidadeIncluso + ' diárias de '
                            : ''
                          }${i.descricao}`
                      )
                      .join(', ')}
                  </UBox>
                  <UBox component={Grid} container spacing={1}>
                    <UBox
                      render={tiposInternacoes.length > 1}
                      component={Grid}
                      item
                      xs={12}
                    >
                      <UBox mt={2} mb={2}>
                        <Typography variant="body1">
                          <b>Selecione o tipo de internação:</b>
                        </Typography>
                      </UBox>
                      <RadioGroup
                        onChange={handleRadios}
                        value={defaultCheckedRadio}
                      >
                        {!!tiposInternacoes.includes(
                          INTERNACOES_OBJ.PQA.codigo
                        ) && (
                            <>
                              <FormControlLabel
                                control={
                                  <Radio value="pqa" color="primary" name="pqa" />
                                }
                                label="PQA (até 6h de internação)"
                              />
                              <Alert severity="info">
                                Cirurgias de pequeno porte em que o paciente tem
                                alta hospitalar do centro cirúrgico até 6h após o
                                procedimento.
                              </Alert>
                            </>
                          )}
                        {!!tiposInternacoes.includes(
                          INTERNACOES_OBJ.DAY_CLINIC.codigo
                        ) && (
                            <FormControlLabel
                              control={
                                <Radio
                                  value="dayClinic"
                                  color="primary"
                                  name="dayClinic"
                                />
                              }
                              label="Day Clinic (até 12h de internação)"
                            />
                          )}
                        {!!tiposInternacoes.includes('DIARIAS') && (
                          <FormControlLabel
                            control={
                              <Radio
                                value="withInternation"
                                color="primary"
                                name="withInternation"
                              />
                            }
                            label="Cirurgia com diária"
                          />
                        )}
                      </RadioGroup>
                    </UBox>

                    <UBox
                      component={Grid}
                      item
                      xs={12}
                      hidden={!mostrarDiarias}
                    >
                      <UBox mt={2}>
                        <Typography variant="body1">
                          <b>
                            Você deseja adicionar diárias extras de unidade de
                            internação?
                          </b>
                        </Typography>
                        <Alert severity="warning">
                          Haverá acréscimo no valor do orçamento ao adicionar
                          diárias extras.
                        </Alert>
                      </UBox>
                      <Grid
                        container
                        spacing={1}
                        style={{ margin: 0, width: '100%' }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" color="primary">
                            <b>
                              <small>Diárias extras de internação</small>
                            </b>
                          </Typography>
                          <InputQuantidade
                            onValueChange={value =>
                              handleItemValue('diariasInternacao', value)
                            }
                            max={999}
                            min={minDiariasInternacao}
                            value={
                              command?.diariasInternacao || minDiariasInternacao
                            }
                          />
                        </Grid>
                        <UBox
                          render={
                            !!command.valoresInternacao.find(i =>
                              [INTERNACOES_OBJ.UTI.id].includes(i.id)
                            ) &&
                            !!command.valoresInternacao.find(i =>
                              [INTERNACOES_OBJ.UTI.id].includes(i.id)
                            ).podeAcrescentar
                          }
                          component={Grid}
                          item
                          xs={12}
                          md={6}
                        >
                          <Typography variant="body2" color="primary">
                            <b>
                              <small>Diárias UTI</small>
                            </b>
                          </Typography>
                          <InputQuantidade
                            onValueChange={value =>
                              handleItemValue('diariasUti', value)
                            }
                            max={999}
                            min={0}
                            value={command?.diariasUti || 0}
                          />
                        </UBox>
                      </Grid>
                      <UBox
                        my={2}
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '8px',
                          padding: '20px',
                          background: '#fff',
                          fontWeight: 400,
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="body1" color="secondary">
                          {!!obterQtdTotalDiarias() && (
                            <>
                              {'O orçamento irá contemplar '}
                              <b>
                                {`${obterQtdTotalDiarias()} ${obterQtdTotalDiarias() > 1
                                  ? ' diárias'
                                  : ' diária'
                                  } de internação`}
                              </b>
                            </>
                          )}
                          {!!obterQtdTotalDiarias(true) && (
                            <span style={{ display: 'block' }}>
                              {'O orçamento irá contemplar '}
                              <b>
                                {`${obterQtdTotalDiarias(true)} ${obterQtdTotalDiarias(true) > 1
                                  ? ' diárias'
                                  : ' diária'
                                  } de UTI`}
                              </b>
                            </span>
                          )}
                        </Typography>
                      </UBox>
                    </UBox>
                  </UBox>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.honorarioMedico}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Nesta etapa você preencherá somente o seu honorário. Os valores dos outros membros da sua equipe serão preenchidos na próxima etapa.'
                  }
                  style={{ padding: '18px' }}
                >
                  <Grid container spacing={1}>

                    <UBox
                      component={Grid}
                      hidden={!!command.semHonorarioMedico}
                      item
                      xs={12}
                      sm={6}
                    >
                      <UBox pt={2}>
                        <CurrencyInput
                          fullWidth
                          // hidePrefix
                          // noMoney
                          erro={errors}
                          // autoFocus
                          size="small"
                          variant="outlined"
                          // margin="normal"
                          name="honorario"
                          label="Honorário médico"
                          hiddenLabel
                          control={control}
                          type="tel"
                          InputLabelProps={{
                            shrink: true
                          }}
                          // InputProps={{
                          //   endAdornment: <>%</>
                          // }}
                          // max={999}
                          onValueChange={value =>
                            handleItemValue('honorarioEquipeMedica', value)
                          }
                          value={command?.honorarioEquipeMedica || 0}
                        />
                      </UBox>
                    </UBox>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        erro={errors}
                        name="mediaTempoCirurgico"
                        label="Média de tempo (hh:mm)"
                        mask="99:99"
                        type="tel"
                        maskPlaceholder="00:00"
                        value={command.mediaTempoCirurgico}
                        defaultValue={command.mediaTempoCirurgico}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <UBox>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={formState.isSubmitting}
                                checked={!!command.semHonorarioMedico}
                                onChange={(e, value) =>
                                  handleItemValue('semHonorarioMedico', value)
                                }
                                value={command.semHonorarioMedico}
                              />
                            }
                            label="Não cobrar honorário médico!"
                            style={{ fontSize: '12px' }}
                          />
                        </FormGroup>
                      </UBox>
                    </Grid>
                  </Grid>
                </StepContentSyn>

                {/* <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.reservaSangue}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Será necessário reserva de sangue? Caso não necessite clique em "Continuar"'
                  }
                >
                  <CurrencyInput
                    fullWidth
                    // hidePrefix
                    noMoney
                    erro={errors}
                    // autoFocus
                    size="small"
                    variant="outlined"
                    // margin="normal"
                    name="opmes"
                    label="Qtd. concentrados de hemácias"
                    hiddenLabel
                    placeholder="Ex: 2 concentrados de hemácias"
                    control={control}
                    type="tel"
                    InputLabelProps={{
                      shrink: true
                    }}
                    // InputProps={{
                    //   endAdornment: <>%</>
                    // }}
                    max={999}
                    onValueChange={value =>
                      handleItemValue('reservaSangue', value)
                    }
                    value={command?.reservaSangue || 0}
                  />
                </StepContentSyn> */}

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === Steps.membrosEquipe}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Quais membros da sua equipe participarão desse procedimento?'
                  }
                  style={{ padding: '18px' }}
                >
                  {/* <UBox style={{ wordBreak: 'break-all' }}>
                    c:{JSON.stringify(command.membros)}
                  </UBox> */}
                  <UBox render={!!props.etapaAjuste}>
                    <Alert severity="info">
                      Confirme sua equipe médica para o procedimento e salve a
                      configuração.
                    </Alert>
                  </UBox>
                  <UBox mt={2} mb={2}>
                    <Typography variant="body1">
                      <b>Membros da equipe:</b>
                    </Typography>
                  </UBox>
                  <Grid container spacing={0}>
                    {listaMembrosEquipe.map((membro, i) => (
                      <Grid key={i} container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          sm={9}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <UBox width="36px">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={formState.isSubmitting}
                                    checked={
                                      !!command?.membros.find(
                                        e => membro?.id === e?.id
                                      )
                                    }
                                    onChange={(e, value) =>
                                      handleItemValueMembros(membro)
                                    }
                                    value={membro.id}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </UBox>
                          <Typography variant="body1">
                            {membro?.descricao?.toUpperCase()}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <UBox
                            render={!!obterMembroSelecionadoPorId(membro?.id)}
                          >
                            <FormControl
                              // className={styles.formControl2}
                              fullWidth
                              size="medium"
                              variant="outlined"
                            >
                              <CurrencyInput
                                fullWidth
                                // hidePrefix
                                erro={errors}
                                // autoFocus
                                size="small"
                                variant="outlined"
                                // margin="normal"
                                name="valorAnestesia"
                                label="Valor a receber"
                                hiddenLabel
                                control={control}
                                type="tel"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                // InputProps={{
                                //   endAdornment: <>%</>
                                // }}
                                // max={10000}
                                onValueChange={value =>
                                  handleChangeItemValueMembro(
                                    obterMembroSelecionadoPorId(membro?.id),
                                    'valor',
                                    value
                                  )
                                }
                                value={
                                  obterMembroSelecionadoPorId(membro?.id)
                                    ?.valor || 0
                                }
                              />
                            </FormControl>
                          </UBox>
                        </Grid>
                        <Grid item xs={12}>
                          <UBox my={1}>
                            <Divider />
                          </UBox>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  // show={true}
                  show={activeStep === Steps.revisao}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleFinish={() => {
                    onSubmit()
                  }}
                  textoTitulo={
                    'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                  }
                  showFinish
                  loading={loadingSubmit}
                >
                  <Box mb={3}>
                    <Typography variant="subtitle1">
                      Caso precise alterar alguma informação, clique no botão
                      'Voltar' até chegar na etapa desejada.
                    </Typography>
                  </Box>

                  <ResumoPacoteInteligenteOps
                    medico
                    configuracao
                    selecionado
                    adaptarValor={false}
                    unificarOpmes
                    forcarHonorarios
                    esconderIncluso={true}
                    // exibirAjusteEtapa={true}
                    exibirDadosMedicos
                    pacote={command}
                  // pacote={mock}
                  />
                  {/* <RevisaoPacoteInteligente
                    medico
                    configuracao
                    adaptarValor={false}
                    // exibirAjusteEtapa={true}
                    exibirDadosMedicos
                    pacote={command}
                    // pacote={mock}
                  /> */}
                </StepContentSyn>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

type MsgProps = {
  ehExterno?: boolean
  qtdMax?: number
}

export const MsgAcrescimo = ({ ehExterno = false, qtdMax = 0 }: MsgProps) => {
  function obterTexto() {
    var msg = ''

    if (!ehExterno) {
      if (qtdMax > 0) {
        msg = `haverá acréscimo no orçamento se selecionado mais que ${qtdMax} unid.`
      } else {
        msg = 'haverá acréscimo no orçamento ao selecionar esse item'
      }
    } else {
      msg = 'haverá acréscimo no orçamento ao selecionar esse item'
    }

    return msg
  }
  return <div style={{ color: '#b39325' }}>{obterTexto()}</div>
}
