import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { MdEdit } from 'react-icons/md'
import { IAny } from 'src/types'
import { IPacoteForm } from './formPacote'

import clsx from 'clsx'
import { ItemListagemMembroEquipe } from 'src/api/membroEquipe'
import Ubox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import useAuth from 'src/hooks/useAuth'

interface IPacoteProps extends IAny {
  pacote: IPacoteForm
  telaOrcamento?: boolean
  exibirAjusteEtapa?: boolean
  //Filtra membros por lista de membros ativos informada
  listaMembros?: ItemListagemMembroEquipe[]
  handleAjusteEtapa?: (etapa: number) => void
  erro?: boolean
  isHospital?: boolean
  dadosSensiveis?: boolean
  valorEquipeAnestesia?: number
  formatarValorEquipe?: boolean
  temPlanoDeSaude?: boolean
}

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    '& h3': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  esconderEditar: {
    '& button': {
      display: 'none'
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

const ResumoPacote = (props: IPacoteProps) => {
  const {
    pacote,
    telaOrcamento,
    listaMembros = [],
    formatarValorEquipe = 0,
    temPlanoDeSaude,
    ...rest
  } = props
  const { user, perfilAtual } = useAuth()
  const classes = useStylesResumo()

  const blockUsers =
    perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
    perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL

  const ehPaciente = perfilAtual === SynPerfis.PACIENTE

  if (listaMembros?.length > 0) {
    var membros = pacote.membros
    //filtra membros inativos
    membros = membros.filter(m => listaMembros.find(lm => lm.id === m.id))
    pacote.membros = membros
  }
  type BtnAjustarProps = {
    onClick: () => void
    erro?: boolean
  }

  function BtnAjustar(props: BtnAjustarProps) {
    return (
      <IconButton
        color="primary"
        size="small"
        style={{ textTransform: 'none', opacity: 0.7 }}
        onClick={props.onClick}
        className={props.erro ? 'danger' : null}
      >
        <MdEdit /> <span style={{ fontSize: '0.9rem' }}>Ajustar</span>
      </IconButton>
    )
  }

  function obterValorHonorario() {
    var honorario = 0

    if (!!pacote.honorarioEquipeMedica) {
      honorario = parseInt(pacote.honorarioEquipeMedica.toString())
    }

    // var equipe = pacote?.membros?.reduce(
    //   (partialSum, i) => partialSum + i.valor,
    //   0
    // )
    // if (!!equipe) {
    //   equipe = parseInt(equipe.toFixed(0))

    //   if (formatarValorEquipe) {
    //     equipe = equipe / 100
    //   }
    // } else {
    //   equipe = 0
    // }
    return honorario
    // return honorario + equipe
  }

  function obterValorHonorarioEquipe() {
    var equipe = pacote?.membros?.reduce(
      (partialSum, i) => partialSum + i.valor,
      0
    )
    if (!!equipe) {
      equipe = parseInt(equipe.toFixed(0))

      if (formatarValorEquipe) {
        equipe = equipe / 100
      }
    } else {
      equipe = 0
    }
    return equipe
  }

  function formatarValor(valor: number) {
    let valorFormatado = valor / (formatarValorEquipe ? 100 : 1)

    // if (!exibirValor) {
    //   return ''
    // }

    return formatarDinheiro(valorFormatado)
  }
  return (
    <Box
      mt={2}
      p={2}
      className={clsx({
        [classes.boxResumo]: true,
        [classes.esconderEditar]: !props.exibirAjusteEtapa
      })}
      {...rest}
    >
      <Typography variant="subtitle2" component="h3" color="primary">
        Nome da cirurgia/procedimento
        <BtnAjustar onClick={() => props.handleAjusteEtapa(0)} />
      </Typography>
      <Typography variant="body2">{pacote.descricao}</Typography>
      <br />
      {(pacote?.temPlanoDeSaude === true || !!temPlanoDeSaude) && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Paciente possui plano de saúde?
          </Typography>
          <Typography variant="body2">
            {(pacote?.temPlanoDeSaude === true || !!temPlanoDeSaude) ? 'Sim' : ''}
          </Typography>
          <br />
        </>
      )}
      {pacote.podeFinalDeSemana === true && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Proposta para final de semana
          </Typography>
          <Typography variant="body2">
            {pacote.podeFinalDeSemana === true ? 'Sim' : ''}
          </Typography>
          <BtnAjustar onClick={() => props.handleAjusteEtapa(2)} />
          <br />
        </>
      )}
      {pacote.podeCentroCirurgicoAmbulatorial === true && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Proposta para centro cirúrgico ambulatorial
          </Typography>
          <Typography variant="body2">
            {pacote.podeCentroCirurgicoAmbulatorial === true ? 'Sim' : ''}
          </Typography>
          <BtnAjustar onClick={() => props.handleAjusteEtapa(2)} />
          <br />
        </>
      )}

      <Typography variant="subtitle2" component="h3" color="primary">
        Procedimentos
        <BtnAjustar onClick={() => props.handleAjusteEtapa(1)} />
      </Typography>
      <Typography variant="body2">
        {pacote.procedimentos?.map((p, index) => (
          <div key={'procedimento-' + index}>
            Cod. {p.detalhe} - {p.descricao}
          </div>
        ))}
      </Typography>
      <br />

      <Typography variant="subtitle2" component="h3" color="primary">
        Tipos de anestesia
        <BtnAjustar onClick={() => props.handleAjusteEtapa(3)} />
      </Typography>
      <Typography variant="body2">
        {pacote.tiposAnestesia?.length === 0 && 'Nenhuma anestesia'}
        {!!pacote.tiposAnestesia?.length &&
          pacote.tiposAnestesia?.map(e => e.descricao).join(', ')}
      </Typography>
      <br />

      <Typography variant="subtitle2" component="h3" color="primary">
        Lista de OPME
        <BtnAjustar onClick={() => props.handleAjusteEtapa(4)} />
      </Typography>
      {pacote.opmes?.length === 0 && (
        <Typography variant="body2">Nenhum item</Typography>
      )}
      {!!pacote.opmes?.length && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right" width="55px">
                  Qtd
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pacote.opmes?.map(opme => (
                <TableRow key={opme.id}>
                  <TableCell component="th" scope="row">
                    {opme.descricao}

                    {(!!opme.marca || !!opme.observacao) && (
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.fontMarca}
                      >
                        {opme.marca}
                        {opme.marca && opme.observacao ? '; ' : ''}
                        {opme.observacao}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">{opme.quantidade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <br />

      <Typography variant="subtitle2" component="h3" color="primary">
        Equipamentos hospitalares
        <BtnAjustar onClick={() => props.handleAjusteEtapa(5)} />
      </Typography>
      <Typography variant="body2">
        {pacote.equipamentos?.length === 0 && 'Nenhum equipamento'}
        {!!pacote.equipamentos?.length &&
          pacote.equipamentos?.map(e => e.descricao).join(', ')}
      </Typography>
      <br />

      <Typography variant="subtitle2" component="h3" color="primary">
        Materiais específicos
        <BtnAjustar onClick={() => props.handleAjusteEtapa(5)} />
      </Typography>
      <Typography variant="body2">
        {pacote.materiais?.length === 0 && 'Nenhum material'}
        {!!pacote.materiais?.length &&
          pacote.materiais?.map(e => e.descricao).join(', ')}
      </Typography>
      <br />

      {!!pacote.dayClinic && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Day clinic (até 12h de internação)
            <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} />
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}

      {!!pacote.pqa && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            PQA (até 6h de internação)
            <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} />
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}

      {parseInt(pacote.diariasUti) > 0 && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            UTI
            <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} />
          </Typography>
          <Typography variant="body2">
            {`${pacote.diariasUti || '0'}  diária(s) de UTI`}
          </Typography>
          <br />
        </>
      )}

      {parseInt(pacote.diariasEnfermaria) > 0 && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Enfermaria
            <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} />
          </Typography>
          <Typography variant="body2">
            {`${pacote.diariasEnfermaria || '0'} diária(s) de enfermaria`}
          </Typography>
          <br />
        </>
      )}

      {parseInt(pacote.diariasApartamento) > 0 && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Apartamento
            <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} />
          </Typography>
          <Typography variant="body2">
            {`${pacote.diariasApartamento || '0'} diária(s) de apartamento`}
          </Typography>
          <br />
        </>
      )}

      <Typography variant="subtitle2" component="h3" color="primary">
        Exames durante internação
        <BtnAjustar onClick={() => props.handleAjusteEtapa(7)} />
      </Typography>
      <Typography variant="body2">
        {pacote.examesPosOperatorio || 'Não informado'}
      </Typography>

      <br />

      {!props.isHospital && pacote.semHonorarioMedico && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Honorário médico
            {/* Honorário médico */}
            {/* + Equipe médica */}
            {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} /> */}
            {!blockUsers && (
              // <BtnAjustar onClick={() => props.handleAjusteEtapa(6)} />
              <BtnAjustar
                erro={props.erro}
                onClick={() => props.handleAjusteEtapa(6)}
              />
            )}
          </Typography>
          <Typography variant="body2">
            Solicitação sem honorário médico
          </Typography>
          <br />
        </>
      )}

      {!props.isHospital && !pacote.semHonorarioMedico && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Honorário médico
            {/* Honorário médico */}
            {/* + Equipe médica */}
            {!blockUsers && (
              <BtnAjustar
                erro={props.erro}
                onClick={() => props.handleAjusteEtapa(6)}
              />
            )}
          </Typography>

          {!props.dadosSensiveis && (
            <>
              {!pacote.semHonorarioMedico && (
                <>
                  <Typography variant="body2">
                    Solicitação
                    {!props.isHospital && !blockUsers ? (
                      <>{formatarDinheiro(obterValorHonorario())}</>
                    ) : (
                      <>
                        {obterValorHonorario() > 0
                          ? ' possui honorário médico'
                          : ' não possui honorário médico'}
                      </>
                    )}
                  </Typography>
                </>
              )}
              <Ubox render={props.valorEquipeAnestesia > 0}>
                <Typography variant="body2">
                  Honorário da equipe anestésica:{' '}
                  {formatarDinheiro(props.valorEquipeAnestesia)}
                </Typography>
              </Ubox>
            </>
          )}

          <br />
        </>
      )}

      {!props.isHospital && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            {'Tempo do procedimento'}
            <BtnAjustar
              erro={props.erro}
              onClick={() => props.handleAjusteEtapa(6)}
            />
          </Typography>
          <Typography variant="body2">
            Média de tempo do procedimento:{' '}
            {pacote.mediaTempoCirurgico ? (
              pacote.mediaTempoCirurgico + 'h'
            ) : (
              <span className={props.erro ? 'danger' : null}>
                Não informado
              </span>
            )}
          </Typography>
          <br />
        </>
      )}

      {!props.isHospital && !blockUsers && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Equipe
            <BtnAjustar onClick={() => props.handleAjusteEtapa(9)} />
          </Typography>
          {pacote?.membros?.length > 0 && (
            <Typography variant="body2">
              Honorário da equipe:{' '}
              {!props.isHospital && !blockUsers ? (
                <>{formatarDinheiro(obterValorHonorarioEquipe())}</>
              ) : (
                <>
                  {obterValorHonorario() > 0
                    ? 'Possui honorários'
                    : 'Não possui honorários '}
                </>
              )}
            </Typography>
          )}
          <Typography variant="body2">
            {pacote?.membros?.length > 0
              ? !!pacote.membros?.length &&
                pacote.membros?.map(e => (
                  <>
                    {`${e.descricao} (${formatarValor(e.valor)})`}
                    <br />
                  </>
                ))
              : 'Equipe não foi configurada'}
          </Typography>
          <br />
        </>
      )}

      {ehPaciente && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Considerações do procedimento para o paciente
            <BtnAjustar onClick={() => props.handleAjusteEtapa(8)} />
          </Typography>
          <Typography variant="body2">
            {pacote.observacoesPaciente || 'Nenhuma'}
          </Typography>
        </>
      )}

      {!props.isHospital && !ehPaciente && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            {!!telaOrcamento
              ? 'Considerações do pacote para paciente'
              : 'Considerações para o paciente'}
            <BtnAjustar onClick={() => props.handleAjusteEtapa(8)} />
          </Typography>
          <Typography variant="body2">
            {pacote.observacoesPaciente || 'Nenhuma'}
          </Typography>

          <br />

          <Typography variant="subtitle2" component="h3" color="primary">
            {!!telaOrcamento
              ? 'Considerações do pacote'
              : 'Considerações finais'}
            <BtnAjustar onClick={() => props.handleAjusteEtapa(8)} />
          </Typography>
          <Typography variant="body2">
            {pacote.observacoes || 'Nenhuma'}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default ResumoPacote
