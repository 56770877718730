import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as TextFieldMU,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import { ModalAddProcedimento } from 'src/components/modals/ModalAddProcedimento'
import AutocompleteOPME from 'src/components/OPME/AutocompleteOPME'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import AutocompleteProcedimentos from 'src/components/ui/AutocompleteProcedimentos'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQuery } from 'src/hooks/useQuery'
import {
  adicionarEquipamento,
  adicionarExame,
  adicionarMaterial,
  adicionarOPME,
  adicionarTipoAnestesia,
  obterEquipamentos,
  obterExames,
  obterListaOPME,
  obterMateriais,
  obterProcedimentos,
  obterTiposAnestesia
} from 'src/api/dominio'
import { obterPacotePorId, salvarPacote } from 'src/api/pacote'
import {
  IExame,
  IPacoteDetalhadoResponse,
  ObjetoDescricaoGenerico,
  ObjetoDescricaoGenericoValor,
  ObjetoQuantidade,
  OPME,
  SalvarPacoteCommand
} from 'src/types'
import Swal from 'sweetalert2'
import ResumoPacote from './resumo'
import UBox from 'src/components/ui/Ubox'
import {
  ItemListagemMembroEquipe,
  buscarTodosMembrosEquipe
} from 'src/api/membroEquipe'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'

const useStyles = makeStyles(theme => ({
  container: {
    // padding: '30px 20px 40px ',
    // margin: '20px auto',
    // borderRadius: '8px'
  },
  BtnCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.common.white,
    '@media (max-width: 1300px)': {
      marginBotton: '10px'
    }
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

const schema = yup.object().shape({
  descricao: yup.string().required().label('Nome do procedimento'),
  procedimentos: yup.array().min(1).required().label('Procedimentos'),
  exames: yup.array().min(0).label('Exames'),
  tiposAnestesia: yup.array().min(0).label('Tipo de anestesia'),
  mediaTempoCirurgico: yup
    .string()
    .required()
    .label('Média de tempo')
    .test('media-tempo', 'Média de tempo', value => {
      if (value.trim().length < 5 || value === '00:00') return false
      return true
    }),
  opmes: yup.array().label('Lista de OPME'),
  equipamentos: yup.array().label('Equipamentos hospitalares'),
  materiais: yup.array().label('Materiais hospitalares'),
  membros: yup.array().label('Membros'),
  diariasUti: yup.string().label('Diária de UTI'),
  diariasEnfermaria: yup.string().label('Diária de enfermaria'),
  diariasApartamento: yup.string().label('Diária de apartamento'),
  honorarioEquipeMedica: yup
    .string()
    .nullable()
    .required()
    .label('Honorário da equipe'),
  observacoes: yup.string().label('Observações'),
  observacoesPaciente: yup.string().label('ObservaçõesPaciente'),
  examesPosOperatorio: yup.string().label('Exames pós-operatório'),
  podeFinalDeSemana: yup.boolean().label('Pode final de semana'),
  podeCentroCirurgicoAmbulatorial: yup.boolean().label('Pode ambulatório')
})

export interface IPacoteForm {
  id?: string
  descricao: string
  procedimentos: ObjetoDescricaoGenerico[]
  exames: ObjetoDescricaoGenerico[]
  tiposAnestesia: ObjetoDescricaoGenerico[]
  mediaTempoCirurgico: string
  opmes: ObjetoQuantidade[]
  equipamentos: ObjetoDescricaoGenerico[]
  materiais?: ObjetoDescricaoGenerico[]
  membros?: ItemListagemMembroEquipe[]
  diariasUti: string
  diariasApartamento: string
  diariasEnfermaria: string
  honorarioEquipeMedica: number
  observacoes: string
  observacoesPaciente: string
  pqa: boolean
  dayClinic?: boolean
  examesPosOperatorio: string
  //Quando vem do ajuste
  medicoId?: string
  semHonorarioMedico?: boolean
  podeCentroCirurgicoAmbulatorial?: boolean
  podeFinalDeSemana?: boolean
  planoDeSaude?: boolean
  temPlanoDeSaude?: boolean
}

export function mapearPacoteParaRequest(
  form: IPacoteForm,
  ignorarValorMembro = false
) {
  const pacote: SalvarPacoteCommand = {
    pqa: form.pqa,
    id: form.id,
    podeFinalDeSemana: form.podeFinalDeSemana,
    podeCentroCirurgicoAmbulatorial: form.podeCentroCirurgicoAmbulatorial,
    descricao: form.descricao,
    observacoes: form.observacoes,
    observacoesPaciente: form.observacoesPaciente,
    diariasEnfermaria: !!form.diariasEnfermaria
      ? parseInt(form.diariasEnfermaria)
      : null,
    diariasApartamento: !!form.diariasApartamento
      ? parseInt(form.diariasApartamento)
      : null,
    dayClinic: form.dayClinic,
    diariasUti: !!form.diariasUti ? parseInt(form.diariasUti) : null,
    equipamentos: form.equipamentos?.map(e => e.id),
    materiais: form.materiais?.map(e => e.id),
    exames: form.exames?.map(e => e.id),
    examesPosOperatorio: form.examesPosOperatorio,
    tiposAnestesia: form.tiposAnestesia?.map(e => e.id),
    procedimentos: form.procedimentos?.map(p => p.id),
    honorarioEquipeMedica: form.honorarioEquipeMedica,
    mediaTempoCirurgico: form.mediaTempoCirurgico,
    semHonorarioMedico: form?.semHonorarioMedico,
    opmes: form.opmes?.map(o => {
      let opmes: OPME = {
        opmeId: o.id,
        marca: o.marca,
        observacao: o.observacao,
        quantidade: o.quantidade
      }
      return opmes
    })
  }

  if (!ignorarValorMembro) {
    var membros = JSON.parse(JSON.stringify(form.membros))
    pacote.membros = membros.map(i => {
      i.valor = i.valor / 100
      return i
    })
  } else {
    pacote.membros = form.membros
  }
  return pacote
}

type Props = {
  pacote?: IPacoteForm
  etapaAjuste?: number
  handleSalvarAjuste?: (dados: IPacoteForm, ehAjusteMembro: boolean) => void
  esconderHonorario?: boolean
  handleSubmit?: (dados) => void
  // isback: (value: boolean) => void
}

export const FormPacote = (props: Props) => {
  const { esconderHonorario } = props
  const query = useQuery()

  const classes = useStyles()
  const history = useHistory()
  const { user, perfilAtual, obterTenantAtual, ehTenantSyn } = useAuth()
  //#region Definicao de listas e loading
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [loadingMembros, setLoadingMembros] = useState<boolean>(true)
  const [listaMembrosEquipe, setListaMembrosEquipe] = useState<
    ItemListagemMembroEquipe[]
  >([])
  const [listaProcedimentos, setListaProcedimentos] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingProcedimentos, setLoadingProcedimentos] = useState<boolean>(
    true
  )

  const [listaTiposAnestesia, setListaTiposAnestesia] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingTiposAnestesia, setLoadingTiposAnestesia] = useState<boolean>(
    true
  )

  const [listaExames, setListaExames] = useState<IExame[]>([])
  const [loadingExames, setLoadingExames] = useState<boolean>(true)

  const [listaOPME, setListaOPME] = useState<ObjetoDescricaoGenerico[]>([])
  const [loadingOPME, setLoadingOPME] = useState<boolean>(true)

  const [listaEquipamentos, setListaEquipamentos] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [listaMateriais, setListaMateriais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingEquipamentos, setLoadingEquipamentos] = useState<boolean>(true)
  const [showUTIDays, setShowUTIDays] = useState<boolean>(false)

  //#endregion
  const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [ajustePacote, setAjustePacote] = useState(false)
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)

  const [showModalProcedimento, toggleModalProcedimento] = useState(false)
  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }

  const [defaultCheckedRadio, setDefaultCheckedRadio] = useState('')

  const handleShowModalProcedimento = (texto: string) => {
    setTextoModal(texto)
    toggleModalProcedimento(true)
  }

  const dadosModal = () => {
    if (!showModal) return
    let dados = null
    switch (showModal) {
      case 'exame':
        dados = {
          titulo: 'Adicionar exame',
          label: 'Exame',
          saveSubmit: adicionarExame,
          handleValue: valor => {
            handleNovoValor('exames', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'tiposAnestesia':
        dados = {
          titulo: 'Adicionar tipo de anestesia',
          label: 'Tipo de anestesia',
          saveSubmit: adicionarTipoAnestesia,
          handleValue: valor => {
            handleNovoValor('tiposAnestesia', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'opmes':
        dados = {
          titulo: 'Adicionar OPME',
          label: 'OPME',
          saveSubmit: async descricao => {
            await Swal.fire({
              title: 'OPME salvo!',
              text: 'O OPME foi adicionado à sua lista abaixo!'
            })
            return adicionarOPME(descricao)
          },
          handleValue: valor => {
            handleNovoValor('opmes', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'equipamento':
        dados = {
          titulo: 'Adicionar material/equipamento hospitalar',
          label: 'Material/Equipamento hospitalar',
          saveSubmit: adicionarEquipamento,
          handleValue: valor => {
            handleNovoValor('equipamentos', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'materiais':
        dados = {
          titulo: 'Adicionar material hospitalar',
          label: 'Material hospitalar',
          saveSubmit: adicionarMaterial,
          handleValue: valor => {
            handleNovoValor('materiais', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
    }
    return dados
  }

  //#region Formulário
  const defaultValues: IPacoteForm = {
    descricao: '',
    procedimentos: [],
    exames: [],
    tiposAnestesia: [],
    mediaTempoCirurgico: '',
    opmes: [],
    equipamentos: [],
    materiais: [],
    membros: [],
    diariasUti: '',
    diariasEnfermaria: '',
    diariasApartamento: '',
    honorarioEquipeMedica: null,
    observacoes: '',
    pqa: false,
    dayClinic: false,
    examesPosOperatorio: '',
    observacoesPaciente: '',
    semHonorarioMedico: false,
    podeFinalDeSemana: false,
    podeCentroCirurgicoAmbulatorial: false,
    planoDeSaude: false
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IPacoteForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    // register('descricao')
    register('procedimentos')
    register('exames')
    register('tiposAnestesia')
    register('equipamentos')
    register('materiais')
    register('opmes')
    register('honorarioEquipeMedica')
    register('pqa')
    register('dayClinic')
    register('membros')

    register('semHonorarioMedico')
    register('podeCentroCirurgicoAmbulatorial')
    register('podeFinalDeSemana')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const handleItemValueMembros = item => {
    item.valor = 0
    var membro = command?.membros?.find(e => e?.id === item?.id)

    var novaLista = command?.membros
    //remove
    if (membro) {
      novaLista = novaLista.filter(e => e.id !== item.id)
    } else {
      //add
      novaLista = [...novaLista, item]
    }

    setValue('membros', novaLista, {
      shouldValidate: true
    })
  }

  const handleChangeItemValueMembro = (item: any, key: string, value: any) => {
    var membro = command?.membros?.find(e => e?.id === item?.id)

    var novaLista = command?.membros
    //altera
    if (membro) {
      membro[key] = value
      novaLista = novaLista.filter(e => e.id !== item.id)

      novaLista = [...novaLista, membro]
      setValue('membros', novaLista, {
        shouldValidate: true
      })
    }
  }

  function obterMembroSelecionadoPorId(id: string) {
    var x = command?.membros?.find(e => id === e?.id)
    return x
  }

  const [tipoInternacao, setTipoInternacao] = useState<string>('apartamento')
  function handleTipoInternacao(tipo: string) {
    if (tipo === 'enfermaria') {
      setValue('diariasApartamento', '0')
    }
    if (tipo === 'apartamento') {
      setValue('diariasEnfermaria', '0')
    }
    setTipoInternacao(tipo)
  }

  const handleRadios = e => {
    let valueRadio = e.target.value
    handleItemValue('diariasUti', 0)
    handleItemValue('diariasEnfermaria', 0)
    handleItemValue('diariasApartamento', 0)
    handleItemValue('pqa', false)
    handleItemValue('dayClinic', false)
    setShowUTIDays(false)
    setDefaultCheckedRadio(valueRadio)
    if (valueRadio === 'withInternation') {
      setShowUTIDays(true)
    } else if (valueRadio === 'dayClinic') {
      handleItemValue('dayClinic', true)
    } else {
      handleItemValue('pqa', true)
    }
  }

  const getDefaultCheckedRadio = pacote => {
    pacote.pqa && setDefaultCheckedRadio('pqa')
    pacote.dayClinic && setDefaultCheckedRadio('dayClinic')
    !pacote.pqa &&
      !pacote.dayClinic &&
      setDefaultCheckedRadio('withInternation')

    !pacote.pqa && !pacote.dayClinic && setShowUTIDays(true)
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'equipamentos':
        carregarEquipamentos()
        setValue('equipamentos', [...command.equipamentos, item], {
          shouldValidate: true
        })
        break
      case 'materiais':
        carregarMateriais()
        setValue('materiais', [...command.materiais, item], {
          shouldValidate: true
        })
        break
      case 'tiposAnestesia':
        carregarTiposAnestesia()
        setValue('tiposAnestesia', [...command.tiposAnestesia, item], {
          shouldValidate: true
        })
        break
      case 'opmes':
        carregarOPME()
        item.quantidade = 1
        setValue('opmes', [...command.opmes, item], {
          shouldValidate: true
        })
        break
      case 'procedimento':
        carregarProcedimentos()
        setValue('procedimento', item, {
          shouldValidate: true
        })
        break
    }
  }
  async function carregarMembros() {
    //SE FOR MEDICO BUSCA PELO CPF
    //SE FOR ASSISTENTE BUSCA PELO MEDICO ID INFORMADO NO AJUSTE
    var medicoId = query.get('medicoSolicitacaoId')
    var cpf =
      perfilAtual === SynPerfis.MEDICO
        ? user.cpf
        : medicoId || props?.pacote?.medicoId

    const id = query.get('idPacote')

    var response = await buscarTodosMembrosEquipe(cpf)

    var { data } = response
    setLoadingMembros(false)
    setListaMembrosEquipe(data)
  }
  //#endregion

  //#region MOUNT
  useEffect(() => {
    carregarMembros()
    carregarProcedimentos()
    carregarTiposAnestesia()
    carregarOPME()
    carregarEquipamentos()
    carregarMateriais()
    const ehAjuste = verificaEhAjuste()
    if (!ehAjuste) verificaEhEdicao()
  }, [])

  useEffect(() => {
    verificaEhAjuste(true)
  }, [listaMembrosEquipe])

  function verificaEhAjuste(ehAjusteMembro = false) {
    if (props.pacote !== undefined && props.etapaAjuste !== undefined) {
      getDefaultCheckedRadio(props.pacote)

      for (const key in props.pacote) {
        setValue(key, props.pacote[key], { shouldValidate: false })
      }
      if (!!props?.pacote?.membros) {
        var listaMembros = JSON.parse(JSON.stringify(props?.pacote?.membros))
        var membros = listaMembros.map(i => {
          i.valor = parseInt((i.valor * 100).toFixed(0))
          return i
        })

        if (ehAjusteMembro) {
          membros = membros.filter(m =>
            listaMembrosEquipe.find(lm => lm.id === m.id)
          )
        }
        setValue('membros', membros, { shouldValidate: false })
      }

      ajustarTipoInternacao(props.pacote)

      setAjustePacote(true)
      setActiveStep(props.etapaAjuste)
      setLoading(false)

      return true
    }

    return false
  }

  function verificaEhEdicao() {
    const id = query.get('idPacote')

    if (!id) {
      setLoading(false)
      return
    }

    obterPacotePorId(id)
      .then(response => {
        const pacote = response.data
        setPacote(pacote)
        getDefaultCheckedRadio(pacote)

        for (const key in pacote) {
          setValue(key, pacote[key], { shouldValidate: false })
        }

        if (!!pacote?.membros) {
          var membros = pacote?.membros.map(i => {
            i.valor = parseInt((i.valor * 100).toFixed(0))
            return i
          })

          membros = membros.filter(m =>
            listaMembrosEquipe.find(lm => lm.id === m.id)
          )

          setValue('membros', membros, { shouldValidate: false })
        }
        ajustarTipoInternacao(pacote)

        setLoading(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }
  //#endregion
  function ajustarTipoInternacao(pacote) {
    let diariasApt = 0
    let diariasEnf = 0
    try {
      diariasApt = parseInt(pacote.diariasApartamento)
    } catch (error) { }
    try {
      diariasEnf = parseInt(pacote.diariasEnfermaria)
    } catch (error) { }

    if (diariasApt > 0) {
      handleTipoInternacao('apartamento')
    } else if (diariasEnf > 0) {
      handleTipoInternacao('enfermaria')
    }
  }

  //#region Carregar Opções do Sistema
  const carregarProcedimentos = async () => {
    return obterProcedimentos()
      .then(response => setListaProcedimentos(response.data || []))
      .finally(() => {
        setLoadingProcedimentos(false)
      })
    // return setListaProcedimentos(mockProcedimentos)
  }

  const carregarTiposAnestesia = async () => {
    return obterTiposAnestesia()
      .then(response => setListaTiposAnestesia(response.data || []))
      .finally(() => {
        setLoadingTiposAnestesia(false)
      })
  }

  const carregarOPME = async () => {
    return obterListaOPME()
      .then(response => setListaOPME(response.data || []))
      .finally(() => {
        setLoadingOPME(false)
      })
    // return setListaOPME(mockOPME)
  }

  const carregarEquipamentos = async () => {
    return obterEquipamentos()
      .then(response => setListaEquipamentos(response.data || []))
      .finally(() => {
        setLoadingEquipamentos(false)
      })
    // return setListaEquipamentos(mockEquipamentos)
  }

  async function carregarMateriais() {
    return obterMateriais()
      .then(res => setListaMateriais(res.data || []))
      .catch(err => showNotificationErrorAPI(err))
  }

  //#endregion

  const onSubmit = async () => {
    setLoadingSubmit(true)
    let pacote = mapearPacoteParaRequest(command)

    const medicoId = query.get('medicoSolicitacaoId')
    if (medicoId) {
      pacote.medicoId = medicoId
    }

    return salvarPacote(pacote)
      .then(response => {
        const fluxoV2 = query.get('flow')
        if (fluxoV2 === 'pctv2') {
          verificarRetorno(response.data)
          return
        }

        Swal.fire({
          title: 'Parabéns!',
          text: 'Seu pacote foi configurado com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          willClose: () => {
            //Verifica se tem url de retorno
            verificarRetorno(response.data)
            history.replace(SynRoutes.listagemPacotes)
          }
        })
      })
      .catch(error => showNotificationErrorAPI(error))
      .finally(() => setLoadingSubmit(false))
  }

  function verificarRetorno(idPacote: string) {
    if (query.has('returnUrl')) {
      const returnUrl = query.get('returnUrl')
      const medicoId = query.get('medicoSolicitacaoId')
      const doc = query.get('doc')
      const fluxoV2 = query.get('flow')
      var url = `${returnUrl}?idPacote=${idPacote}&descricaoPacote=${command.descricao}`

      if (medicoId) {
        url += '&medicoSolicitacaoId=' + medicoId
      }
      if (doc) {
        url += '&doc=' + doc
      }
      if (fluxoV2 === 'pctv2') {
        url += '&flow=' + fluxoV2
      }

      history.replace(url)
      return
    }
  }
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const ehSM = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper
  const [activeStep, setActiveStep] = React.useState(0)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  const lastStep = 11
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const formularioCamposDoStep = [
    ['descricao'],
    ['procedimentos'],
    /* ['exames'], */
    ['podeCentroCirurgicoAmbulatorial', 'podeFinalDeSemana'],
    ['tiposAnestesia'],
    ['opmes'],
    ['equipamentos', 'materiais'],
    [
      'pqa',
      'diariasUti',
      'diariasEnfermaria',
      'diariasApartamento',
      'honorarioEquipeMedica',
      'mediaTempoCirurgico'
    ],
    ['examesPosOperatorio'],
    ['observacoes', 'observacoesPaciente'],
    []
  ]

  const handleNext = async () => {
    var campos = formularioCamposDoStep[activeStep]
    if (!(await validarInteracao())) {
      return
    }
    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }
    if (!!command.semHonorarioMedico && activeStep === 8) {
      setActiveStep(prevActiveStep => prevActiveStep + 2)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const validarInteracao = async () => {
    var qtdDiariasEnfermaria = !!command.diariasEnfermaria
      ? parseInt(command.diariasEnfermaria)
      : 0
    var qtdDiariasApartamento = !!command.diariasApartamento
      ? parseInt(command.diariasApartamento)
      : 0
    var qtdDiariasUti = !!command.diariasUti ? parseInt(command.diariasUti) : 0
    if (
      activeStep === 6 &&
      !command.pqa &&
      !command.dayClinic &&
      !showUTIDays
    ) {
      await Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um tipo de internação',
        icon: 'warning',
        cancelButtonText: 'Ok'
      })
      return false
    } else if (
      activeStep === 6 &&
      showUTIDays &&
      qtdDiariasEnfermaria === 0 &&
      qtdDiariasApartamento === 0 &&
      qtdDiariasUti === 0
    ) {
      await Swal.fire({
        title: 'Atenção!',
        text: 'Informe a quantidade de diária',
        icon: 'warning',
        cancelButtonText: 'Ok'
      })
      return
    } else {
      return true
    }
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  function handleSalvarAjuste() {
    if (props.handleSalvarAjuste) {
      var qtdDiariasEnfermaria = !!command.diariasEnfermaria
        ? parseInt(command.diariasEnfermaria)
        : 0
      var qtdDiariasApartamento = !!command.diariasApartamento
        ? parseInt(command.diariasApartamento)
        : 0
      var qtdDiariasUti = !!command.diariasUti
        ? parseInt(command.diariasUti)
        : 0
      if (
        !command.pqa &&
        !command.dayClinic &&
        qtdDiariasEnfermaria === 0 &&
        qtdDiariasApartamento === 0 &&
        qtdDiariasUti === 0
      ) {
        Swal.fire({
          title: 'Atenção!',
          text:
            qtdDiariasEnfermaria === 0 &&
              qtdDiariasApartamento === 0 &&
              qtdDiariasUti === 0
              ? 'Informe a quantidade de diária '
              : 'Selecione um tipo de internação',
          icon: 'warning',
          cancelButtonText: 'Ok'
        })
        return
      }

      props.handleSalvarAjuste(command, props.etapaAjuste === 8)
    }
  }

  function handleCancel() {
    var url = SynRoutes.medicoAssistente
    if (query.has('returnUrl')) {
      url = query.get('returnUrl')

      if (query.get('medicoSolicitacaoId')) {
        url += '?medicoSolicitacaoId=' + query.get('medicoSolicitacaoId')
      }
      if (query.get('doc')) {
        url += '&doc=' + query.get('doc')
      }
    }

    history.replace(url)
  }

  //#endregion
  // if (loading) {
  //   return (
  //     <Box textAlign="center">
  //       <CircularProgress />
  //       <div>Carregando...</div>
  //     </Box>
  //   )
  // }

  const handlePropostaFinalDeSemana = e => {
    let valueRadio = e.target.value

    // handleItemValue('taxaSyn', null)
    handleItemValue('podeFinalDeSemana', valueRadio === 'true')
  }

  const handlePropostaCentroAmbulatorial = e => {
    let valueRadio = e.target.value

    // handleItemValue('taxaSyn', null)
    handleItemValue('podeCentroCirurgicoAmbulatorial', valueRadio === 'true')
  }

  return (
    <>
      {loading && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}
      <Box
        component="main"
        hidden={loading}
        className={`fadeInTransition ${classes.container}`}
      >
        <CssBaseline />
        {/* <UBox style={{ wordBreak: 'break-all' }}>
          command: {JSON.stringify(command.membros)}
          <br />
          command: {JSON.stringify(tipoInternacao)}
        </UBox> */}

        {/* <br /> */}
        {/* locais: {JSON.stringify(command)} */}
        {ehSM && !ajustePacote && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        {!ajustePacote && pacote?.revisarDados && (
          <Box mb={3}>
            <Alert severity="warning">
              <b>Este é um pacote compartilhado!</b>
              <br />
              Revise as informações de cada etapa e clique em "Continuar".
              <br />
              No último passo clique em "Salvar" para confirmar sua revisão.
            </Alert>
          </Box>
        )}
        <UBox
          render={!ajustePacote}
          mt={2}
          mb={-2}
          mr={1}
          className={`${classes.BtnCancel}`}
        >
          <Button color="secondary" variant="contained" onClick={handleCancel}>
            Cancelar
          </Button>
        </UBox>

        {ehSM && !ajustePacote && (
          <Box mt={4} pl={2} pr={2}>
            <LinearProgress
              variant="determinate"
              value={normalise(activeStep + 1)}
            />
          </Box>
        )}
        <Grid container>
          {!ehSM && !ajustePacote && (
            <Grid item xs={12} sm={1}>
              <Box mt={5} pt={2} ml={4}>
                <Stepper
                  connector={<StepConnectorSyn />}
                  activeStep={activeStep}
                  style={{ margin: 0, padding: 0, background: 'none' }}
                  orientation="vertical"
                >
                  {getSteps(lastStep)}
                </Stepper>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={ajustePacote ? 12 : 10}>
            <Box mt={ehSM ? 2 : 5} pt={ehSM ? 0 : 2} p={ehSM ? 2 : 0}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.form}
                noValidate={true}
              >
                {/* 0 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 0}
                  hideBack
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Digite o nome para o pacote que deseja configurar.'
                  }
                >
                  <TextField
                    erro={errors}
                    name="descricao"
                    value={command.descricao}
                    placeholder="Digite o nome..."
                    label="Nome da cirurgia"
                    inputRef={register}
                    inputProps={{ maxLength: 100 }}
                  />
                </StepContentSyn>
                {/* 1 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 1}
                  handleBackToStep={handleBackToStep}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleNext={handleNext}
                  textoTitulo={
                    'Informe os códigos que representam os procedimentos escolhidos.'
                  }
                >
                  <AutocompleteProcedimentos
                    erro={errors}
                    name="procedimentos"
                    label="Procedimentos"
                    value={command.procedimentos}
                    options={ehXS ? [] : listaProcedimentos}
                    getOptionLabel={option =>
                      `${option.detalhe || ''} - ${option.descricao}`
                    }
                    handleValue={newValue => {
                      handleItemValue('procedimentos', newValue)
                    }}
                    onClickAddButton={texto =>
                      handleShowModalProcedimento(texto)
                    }
                  />
                </StepContentSyn>
                {/* 2 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 2}
                  handleBackToStep={handleBackToStep}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleNext={handleNext}
                >
                  <UBox my={2}>
                    <Typography variant="h6">
                      O médico tem disponibilidade para realizar a cirurgia no
                      final de semana ou feriados?
                    </Typography>

                    <Grid item xs={12} style={{ padding: '10px' }}>
                      {/* {JSON.stringify(command?.propostaFinalDeSemana)} */}
                      <RadioGroup
                        style={{ flexDirection: 'row' }}
                        value={command?.podeFinalDeSemana}
                        onChange={handlePropostaFinalDeSemana}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              value={true}
                              color="primary"
                              name="podeFinalDeSemana"
                            />
                          }
                          label="Sim"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value={false}
                              color="primary"
                              name="podeFinalDeSemana"
                            />
                          }
                          label="Não"
                        />
                      </RadioGroup>
                    </Grid>
                  </UBox>

                  <UBox my={2}>
                    <Typography variant="h6">
                      O médico tem disponibilidade para realizar procedimento no
                      centro cirúrgico ambulatorial?
                    </Typography>

                    <Grid item xs={12} style={{ padding: '10px' }}>
                      {/* {JSON.stringify(command?.propostaCentroAmbulatorial)} */}
                      <RadioGroup
                        style={{ flexDirection: 'row' }}
                        value={command?.podeCentroCirurgicoAmbulatorial}
                        onChange={handlePropostaCentroAmbulatorial}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              value={true}
                              color="primary"
                              name="podeCentroCirurgicoAmbulatorial"
                            />
                          }
                          label="Sim"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value={false}
                              color="primary"
                              name="podeCentroCirurgicoAmbulatorial"
                            />
                          }
                          label="Não"
                        />
                      </RadioGroup>
                    </Grid>
                  </UBox>
                </StepContentSyn>
                {/* 3 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 3}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Qual o tipo de anestesia necessária?'}
                >
                  <Autocomplete
                    multiple
                    erro={errors}
                    name="tiposAnestesia"
                    label="Tipos de anestesia"
                    loading={loadingTiposAnestesia}
                    options={listaTiposAnestesia}
                    value={command.tiposAnestesia}
                    getOptionSelected={option =>
                      !!command.tiposAnestesia.find(l => l.id === option.id)
                    }
                    showCheck
                    showAddButton
                    onClickAddButton={texto =>
                      handleShowModal(texto, 'tiposAnestesia')
                    }
                    getOptionLabel={option => option.descricao}
                    onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                      handleItemValue(
                        'tiposAnestesia',
                        newValue as ObjetoDescricaoGenerico[]
                      )
                    }}
                  />
                </StepContentSyn>
                {/* 4 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 4}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Informe os materiais necessários para realizar o procedimento.'
                  }
                >
                  <Grid item xs={12}>
                    <AutocompleteOPME
                      tableTitle="Lista de OPME"
                      value={command.opmes}
                      name="opmes"
                      label="OPME"
                      handleValue={newValue => {
                        handleItemValue(
                          'opmes',
                          newValue as ObjetoDescricaoGenerico[]
                        )
                      }}
                      onClickAddButton={texto =>
                        handleShowModal(texto, 'opmes')
                      }
                      options={listaOPME}
                      loading={loadingOPME}
                      errors={errors}
                      showAddButton
                      getOptionSelected={option => {
                        return !!command.opmes.find(l => l.id === option.id)
                      }}
                      getOptionLabel={option => option.descricao}
                      getOptionId={option => option.id}
                    />
                  </Grid>
                </StepContentSyn>
                {/* 5 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 5}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Outros materiais ou equipamentos são necessários?'
                  }
                >
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      multiple
                      erro={errors}
                      name="equipamentos"
                      label="Equipamentos hospitalares "
                      loading={loadingEquipamentos}
                      options={listaEquipamentos}
                      value={command.equipamentos}
                      getOptionSelected={option =>
                        !!command.equipamentos.find(l => l.id === option.id)
                      }
                      showCheck
                      showAddButton
                      getOptionLabel={option => option.descricao}
                      onChange={(
                        event,
                        newValue: ObjetoDescricaoGenerico[]
                      ) => {
                        handleItemValue(
                          'equipamentos',
                          newValue as ObjetoDescricaoGenerico[]
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      multiple
                      erro={errors}
                      name="materiais"
                      label="Materiais específicos "
                      options={listaMateriais || []}
                      value={command.materiais}
                      getOptionSelected={option =>
                        !!command.materiais.find(l => l.id === option.id)
                      }
                      showCheck
                      showAddButton
                      addButtonProps={{
                        text: 'Não encontrou? Clique aqui'
                      }}
                      onClickAddButton={texto =>
                        handleShowModal(texto, 'materiais')
                      }
                      getOptionLabel={option => option.descricao}
                      onChange={(
                        event,
                        newValue: ObjetoDescricaoGenerico[]
                      ) => {
                        handleItemValue(
                          'materiais',
                          newValue as ObjetoDescricaoGenerico[]
                        )
                      }}
                    />
                  </Grid>
                </StepContentSyn>
                {/* 6 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 6}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Precisamos de mais algumas informações.'}
                >
                  <Grid container spacing={1}>
                    {/* <Grid item xs={12}>
                    <strong>Diárias</strong>
                  </Grid> */}

                    <Grid item xs={12}>
                      <RadioGroup
                        onChange={handleRadios}
                        value={defaultCheckedRadio}
                      >
                        <FormControlLabel
                          control={
                            <Radio value="pqa" color="primary" name="pqa" />
                          }
                          label="PQA (até 6h de internação)"
                        />
                        <Alert severity="info">
                          Cirurgias de pequeno porte em que o paciente tem alta
                          hospitalar do centro cirúrgico até 6h após o
                          procedimento.
                        </Alert>
                        <FormControlLabel
                          control={
                            <Radio
                              value="dayClinic"
                              color="primary"
                              name="dayClinic"
                            />
                          }
                          label="Day Clinic (até 12h de internação)"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value="withInternation"
                              color="primary"
                              name="withInternation"
                            />
                          }
                          label="Cirurgia com diária"
                        />
                      </RadioGroup>
                    </Grid>

                    <Box hidden={!showUTIDays}>
                      <Grid
                        container
                        style={{ margin: 0, width: '100%' }}
                        spacing={1}
                      >
                        <Grid item xs={12}>
                          <UBox mt={2}>
                            <Typography variant="body2" color="primary">
                              <b>Informe o tipo de internação</b>
                            </Typography>
                          </UBox>
                          <RadioGroup
                            style={{ flexDirection: 'row' }}
                            onChange={(e, value) => handleTipoInternacao(value)}
                            value={tipoInternacao}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  value="apartamento"
                                  color="primary"
                                  name="tipoInternacao"
                                />
                              }
                              label="Apartamento"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  value="enfermaria"
                                  color="primary"
                                  name="tipoInternacao"
                                />
                              }
                              label="Enfermaria"
                            />
                          </RadioGroup>
                        </Grid>

                        <UBox
                          hidden={tipoInternacao !== 'enfermaria'}
                          component={Grid}
                          item
                          xs={12}
                          md={6}
                        >
                          <Typography variant="body2" color="primary">
                            <b>Quantidade de diárias de enfermaria</b>
                          </Typography>
                          <TextField
                            erro={errors}
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder="Diárias"
                            type="tel"
                            value={command.diariasEnfermaria}
                            name="diariasEnfermaria"
                            disabled={command.pqa}
                            label="Diárias enfermaria"
                            control={control}
                            mask="9999"
                          />
                        </UBox>

                        <UBox
                          hidden={tipoInternacao !== 'apartamento'}
                          component={Grid}
                          item
                          xs={12}
                          md={6}
                        >
                          <Typography variant="body2" color="primary">
                            <b>Quantidade de diárias de apartamento:</b>
                          </Typography>
                          <TextField
                            erro={errors}
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder="Diárias"
                            type="tel"
                            value={command.diariasApartamento}
                            name="diariasApartamento"
                            disabled={command.pqa}
                            label="Diárias apartamento"
                            control={control}
                            mask="9999"
                          />
                        </UBox>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ margin: 0, width: '100%' }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" color="primary">
                            <b>Quantidade de diárias de UTI</b>
                          </Typography>
                          <TextField
                            erro={errors}
                            name="diariasUti"
                            placeholder="Diárias"
                            type="tel"
                            value={command.diariasUti}
                            disabled={command.pqa}
                            label="Diárias UTI"
                            control={control}
                            mask="9999"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <UBox render={!esconderHonorario}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="primary">
                          <b>Honorários da equipe</b>
                        </Typography>
                      </Grid>
                      <UBox
                        hidden={!!command.semHonorarioMedico}
                        component={Grid}
                        item
                        xs={12}
                        sm={6}
                      >
                        <TextFieldMU
                          data-cy="honorarioMedico"
                          error={!!errors.honorarioEquipeMedica}
                          helperText={errors.honorarioEquipeMedica?.message}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          label="Honorário da equipe médica"
                          value={command.honorarioEquipeMedica}
                          onFocus={e => e.target.select()}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value
                            handleItemValue('honorarioEquipeMedica', value)
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom as any
                          }}
                        />
                      </UBox>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          erro={errors}
                          name="mediaTempoCirurgico"
                          label="Média de tempo (hh:mm)"
                          mask="99:99"
                          type="tel"
                          maskPlaceholder="00:00"
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <UBox>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!command.semHonorarioMedico}
                                  onChange={(e, value) => {
                                    handleItemValue('semHonorarioMedico', value)
                                    handleItemValue('honorarioEquipeMedica', 0)
                                  }}
                                  value={command.semHonorarioMedico}
                                />
                              }
                              label="Não cobrar honorário médico!"
                              style={{ fontSize: '12px' }}
                            />
                          </FormGroup>
                        </UBox>
                      </Grid>
                    </Grid>
                  </UBox>
                  <UBox render={!!esconderHonorario}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="primary">
                          <b>Tempo de procedimento</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          erro={errors}
                          name="mediaTempoCirurgico"
                          label="Média de tempo (hh:mm)"
                          mask="99:99"
                          type="tel"
                          maskPlaceholder="00:00"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </UBox>
                </StepContentSyn>
                {/* 7 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 7}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Quais exames seu paciente deve realizar durante a internação?'
                  }
                >
                  <Alert severity="info">
                    Caso você precise de algum tipo de exame ou procedimento
                    específico durante a internação, informe no campo abaixo.{' '}
                    <br />
                    <br />
                    Exemplo: Exames de imagem de controle pós-operatório, exames
                    laboratoriais pós-operatório, histopatológico de peça
                    cirúrgica, entre outros.
                  </Alert>
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="examesPosOperatorio"
                    label="Exames durante a internação"
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>
                {/* 8 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 8}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Caso tenha alguma consideração a fazer, sinta-se à vontade para informar abaixo'
                  }
                >
                  <Typography variant="subtitle2">
                    Informações gerais para equipe interna (Paciente não
                    visualiza)
                  </Typography>
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacoes"
                    label="Observações"
                    inputProps={{ maxLength: 500 }}
                  />

                  <Typography variant="subtitle2" style={{ marginTop: 16 }}>
                    Informações gerais para o paciente
                  </Typography>
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacoesPaciente"
                    label="Observações para paciente"
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>
                {/* 9 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 9}
                  hideBack={ajustePacote}
                  showFinish={ajustePacote}
                  handleFinish={handleSubmit(handleSalvarAjuste)}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Quais membros da sua equipe participarão desse procedimento?'
                  }
                  style={{ padding: '18px' }}
                >
                  <UBox loading={loadingMembros}>
                    <UBox render={!!props.etapaAjuste}>
                      <Alert severity="info">
                        Confirme sua equipe médica para o procedimento e salve a
                        configuração.
                      </Alert>
                    </UBox>
                    <UBox mt={2} mb={2}>
                      <Typography variant="body1">
                        <b>Membros da equipe:</b>
                      </Typography>
                    </UBox>
                    <Grid container spacing={0}>
                      {listaMembrosEquipe.length === 0 && (
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Ainda não existe membro de equipe configurado.
                          </Typography>
                        </Grid>
                      )}
                      {listaMembrosEquipe.map((membro, i) => (
                        <Grid key={i} container spacing={0}>
                          <Grid
                            item
                            xs={12}
                            sm={9}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <UBox width="36px">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={formState.isSubmitting}
                                      checked={
                                        !!command?.membros.find(
                                          e => membro?.id === e?.id
                                        )
                                      }
                                      onChange={(e, value) =>
                                        handleItemValueMembros(membro)
                                      }
                                      value={membro.id}
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            </UBox>
                            <Typography variant="body1">
                              {membro?.descricao?.toUpperCase()}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <UBox
                              render={!!obterMembroSelecionadoPorId(membro?.id)}
                            >
                              <FormControl
                                // className={styles.formControl2}
                                fullWidth
                                size="medium"
                                variant="outlined"
                              >
                                <CurrencyInput
                                  fullWidth
                                  // hidePrefix
                                  erro={errors}
                                  // autoFocus
                                  size="small"
                                  variant="outlined"
                                  // margin="normal"
                                  name="valorAnestesia"
                                  label="Valor"
                                  hiddenLabel
                                  control={control}
                                  type="tel"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  // InputProps={{
                                  //   endAdornment: <>%</>
                                  // }}
                                  // max={10000}
                                  onValueChange={value =>
                                    handleChangeItemValueMembro(
                                      obterMembroSelecionadoPorId(membro?.id),
                                      'valor',
                                      value
                                    )
                                  }
                                  value={
                                    obterMembroSelecionadoPorId(membro?.id)
                                      ?.valor || 0
                                  }
                                />
                              </FormControl>
                            </UBox>
                          </Grid>
                          <Grid item xs={12}>
                            <UBox my={1}>
                              <Divider />
                            </UBox>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </UBox>
                </StepContentSyn>
                {/* 10 */}
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 10}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleFinish={() => {
                    onSubmit()
                  }}
                  textoTitulo={
                    'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                  }
                  showFinish
                  textFinish="Continuar"
                  loading={loadingSubmit}
                >
                  <Box mb={3}>
                    <Typography variant="subtitle1">
                      Caso precise alterar alguma informação, clique no botão
                      'Voltar' até chegar na etapa desejada.
                    </Typography>
                  </Box>

                  <ResumoPacote pacote={command} formatarValorEquipe />
                </StepContentSyn>
              </form>
            </Box>
          </Grid>
        </Grid>
        {!!showModal && <ModalAddGenerico open={true} {...dadosModal()} />}
        {!!showModalProcedimento && (
          <ModalAddProcedimento
            open={true}
            descricao={textoModal}
            handleClose={() => toggleModalProcedimento(false)}
            handleValue={valor => {
              handleNovoValor('procedimento', valor)
            }}
          />
        )}
      </Box>
    </>
  )
}
