import { AxiosResponse } from 'axios'
import {
  IAtividade,
  ISolicitacaoAtividade
} from 'src/contexts/Atividade/context'

import api from '../../services/api'
import { ListagemQuery } from 'src/types'

export interface IAddAtividadeCommand {
  id: string
  titulo: string
  descricao: string
  dataHoraLembrete: string
  solicitacaoId: string
  usuarios: string[]
}

export default {
  adicionarAtividade: (
    command: IAddAtividadeCommand
  ): Promise<AxiosResponse> => {
    return api.post('/atividades', command)
  },

  buscarAtividade: (id: string): Promise<AxiosResponse<IAtividade>> => {
    return api.get(`/atividades/${id}`)
  },

  removerAtividade: (id: string): Promise<AxiosResponse> => {
    return api.delete(`/atividades/${id}`)
  },

  concluirAtividade: (id: string): Promise<AxiosResponse> => {
    return api.post(`/atividades/concluidas/${id}`)
  },

  obterAtividades: (): Promise<AxiosResponse<any>> => {
    return api.get('/atividades')
  },

  obterQtdPendencias: (): Promise<AxiosResponse<number>> => {
    return api.get('/solicitacaoOrcamento/pendencias')
  },

  obterAtividadesPendentes: (
    query: ListagemQuery
  ): Promise<AxiosResponse<any>> => {
    return api.get('/atividades/pendentes')
  },

  obterAtividadesFuturas: (
    query: ListagemQuery
  ): Promise<AxiosResponse<any>> => {
    return api.get('/atividades/futuras')
  },

  obterAtividadesConcluidas: (
    query: ListagemQuery
  ): Promise<AxiosResponse<any>> => {
    return api.get(`/atividades/concluidas?${new URLSearchParams(query)}`)
  }
}
