import { useEffect, useState } from 'react'
import service from 'src/api/atividade'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { gerarGuid } from 'src/core/helpers/common'
import useAuth from 'src/hooks/useAuth'
import { NotificacaoMemo } from 'src/signalR/synHub'
import AtividadeContext from './context'

const PendenciasProvider = ({ children }) => {
  const { user, signed, perfilAtual } = useAuth()
  const [keyNotificacao, setKeyNotificacao] = useState<string>(gerarGuid())
  const [qtdPendencias, setQtdPendencias] = useState<number>(0)
  useEffect(() => {
    if (
      signed &&
      (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
        perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL)
    ) {
      var funcaoAtualizar = async () => {
        await atualizarQtdPendencias()
      }
      funcaoAtualizar()

      var intervalPendencies = setInterval(() => {
        atualizarQtdPendencias()
      }, 20 * 60 * 1000)

      // var intervalHub = setInterval(
      //   () => atualizarHubConnection,
      //   30 * 60 * 1000
      // )
    }

    return () => {
      clearInterval(intervalPendencies)
      // clearInterval(intervalHub)
    }
  }, [signed, perfilAtual])

  async function atualizarHubConnection() {
    setKeyNotificacao(gerarGuid())
  }

  async function atualizarQtdPendencias() {
    try {
      var response = await service.obterQtdPendencias()
      setQtdPendencias(response.data)
    } catch (error) {
      // showNotificationErrorAPI(error)
    }
  }

  return (
    <AtividadeContext.Provider
      value={{
        qtdPendencias,
        atualizarQtdPendencias,
        atualizarHubConnection
      }}
    >
      {!!user && !!perfilAtual && (
        <>
          <NotificacaoMemo key={keyNotificacao} />
        </>
      )}
      {children}
    </AtividadeContext.Provider>
  )
}

export default PendenciasProvider
