import {
  Box,
  Chip,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab'
import {
  excluirOrcamentoPorParceiroId,
  obterParceiros
} from 'src/api/orcamento'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { ItemParceiroResponse } from 'src/types'
import Swal from 'sweetalert2'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
  statusSolicitacao?: string
}

function DrawerVisualizarParceiros(props: Props) {
  const { user, perfilAtual } = useAuth()

  const ehComercial =
    perfilAtual === SynPerfis.COMERCIAL_B2B ||
    perfilAtual === SynPerfis.COMERCIAL_MEDICOS ||
    perfilAtual === SynPerfis.COMERCIAL_EXPANSAO
  const [loading, setLoading] = useState(true)
  const [loadingExcluir, setLoadingExcluir] = useState(false)
  const [itemSelecionado, setItemSelecionado] = useState<ItemParceiroResponse>(
    null
  )

  const [tab, setTab] = useState(1)
  const [parceiros, setParceiros] = useState<ItemParceiroResponse[]>([])

  useEffect(() => {
    if (!props.id) {
      return
    }
    setTab(1)
    popularParceiros()
  }, [props.open])

  async function popularParceiros() {
    try {
      setLoading(true)
      var response = await obterParceiros(props.id)
      setParceiros(response?.data || [])
      setLoading(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  async function handleExcluirOrcamento(item: ItemParceiroResponse) {
    try {
      setLoadingExcluir(true)
      setItemSelecionado(item)
      var response = await excluirOrcamentoPorParceiroId({
        parceiroId: item.id,
        solicitacaoId: props.id
      })

      await Swal.fire({
        title: 'Sucesso!',
        text: `Os orçamentos do parceiro "${item.nome}" foram excluidos`,
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      popularParceiros()
      setLoadingExcluir(false)
      setItemSelecionado(null)
    } catch (e) {
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  function handleChange(e, value) {
    setTab(value)
  }

  return (
    <Drawer
      loading={loading}
      anchor="right"
      open={props.open}
      titulo="Parceiros"
      handleOnClose={props.handleOnClose}
    >
      {parceiros.length === 0 && (
        <Box mt={2}>
          <Typography variant="subtitle1">
            Nenhum parceiro realizou orçamento
          </Typography>
        </Box>
      )}
      {props.statusSolicitacao !== statusOrcamento.EM_ANDAMENTO.Id && (
        <Alert severity="info">
          Não é permitido o reenvio de novos orçamentos pois a solicitação não
          está mais em andamento
        </Alert>
      )}
      {parceiros.length > 0 && (
        <Box mt={2}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab
              label={
                <Box display="flex" justifyContent="center">
                  <Box mr={1}>OPME</Box>
                  <Chip
                    variant="default"
                    size="small"
                    label={
                      parceiros.filter(
                        parceiro => parceiro.tipo === SynTipoParceiro.OPME.id
                      ).length
                    }
                  />
                </Box>
              }
              value={1}
            />
            {perfilAtual !== SynPerfis.ADMIN_HOSPITAL && perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL && (
              <Tab
                label={
                  <Box display="flex" justifyContent="center">
                    <Box mr={1}>Hospitais</Box>
                    <Chip
                      variant="default"
                      size="small"
                      label={
                        parceiros.filter(
                          parceiro =>
                            parceiro.tipo === SynTipoParceiro.HOSPITAL_CLINICA.id
                        ).length
                      }
                    />
                  </Box>
                }
                value={2}
              />
            )}
          </Tabs>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Parceiro</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parceiros
                    .filter(
                      parceiro =>
                        parceiro.tipo ===
                        (tab === 1
                          ? SynTipoParceiro.OPME.id
                          : SynTipoParceiro.HOSPITAL_CLINICA.id)
                    )
                    .map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          {item.nome}
                        </TableCell>
                        <TableCell align="right">
                          {props.statusSolicitacao ===
                            statusOrcamento.EM_ANDAMENTO.Id &&
                            !ehComercial &&
                            perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                              <Button
                                loading={
                                  loadingExcluir &&
                                  itemSelecionado?.id === item.id
                                }
                                disabled={
                                  loadingExcluir &&
                                  itemSelecionado?.id === item.id
                                }
                                variant="text"
                                onClick={() => handleExcluirOrcamento(item)}
                              >
                                Permitir reenvio
                              </Button>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Drawer>
  )
}

export default DrawerVisualizarParceiros
