import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { SynPerfis } from 'src/core/consts/synPerfis'
import {
  formatarData,
  formatarDiferencaData,
  formatarIdade
} from 'src/core/formatacoes/formatarData'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import useAuth from 'src/hooks/useAuth'
import { ObjetoDescricaoGenerico } from 'src/types'
import formatarCPF from 'src/core/formatacoes/formatarCPF'

interface Props {
  solicitacao?: boolean
  proposta?: boolean
  medicoNome?: string
  pacoteProcedimento: any
  paciente: any
  orcamentoParceiro?: boolean
  comorbidades?: ObjetoDescricaoGenerico[]
  obsComorbidades?: string
  isDrawer?: boolean
}

export interface IPacienteHeader {
  nome?: string
  cpf?: string
  dataNascimento?: string
  sexo?: number
  telefone?: string
  email?: string
  cpfResponsavel?: string
  nomeResponsavel?: string
}

export default function HeaderSolicitacao({
  solicitacao,
  proposta,
  medicoNome,
  pacoteProcedimento,
  paciente,
  orcamentoParceiro,
  comorbidades,
  obsComorbidades,
  isDrawer = false
}: Props) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const { signOut, user, perfilAtual } = useAuth()

  const parceiros = [
    SynPerfis.PARCEIRO,
    SynPerfis.ADMIN_HOSPITAL,
    SynPerfis.OPERACIONAL_HOSPITAL,
    SynPerfis.COTACAO_HOSPITAL,
    SynPerfis.TESOURARIA_HOSPITAL,
    SynPerfis.AGENDAMENTO
  ]

  return (
    <Box mb={5}>
      <Box mb={3}>
        {solicitacao && !parceiros.includes(perfilAtual) && (
          <Typography variant="h5">
            Dados da solicitação{' '}
            {orcamentoParceiro ? null : ` - ${pacoteProcedimento?.descricao}`}
          </Typography>
        )}
        {proposta && (
          <Typography variant="h5">
            Propostas para {pacoteProcedimento?.descricao}
          </Typography>
        )}
      </Box>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12} md={isDrawer ? 12 : 6}>
          {!parceiros.includes(perfilAtual) && (
            <Typography variant="h6">Procedimento</Typography>
          )}

          {orcamentoParceiro && (
            <>
              <Typography
                variant="body1"
                color="primary"
                style={{
                  // borderBottom: '3px solid #455880',
                  lineHeight: '.9rem',
                  borderRadius: '2px',
                  display: 'inline-block',
                  marginBottom: '8px'
                }}
              >
                <strong>Procedimento</strong>
              </Typography>

              <Typography variant="body2">
                {pacoteProcedimento?.descricao}
              </Typography>
            </>
          )}

          {pacoteProcedimento?.procedimentos.map(i => {
            return (
              <Typography variant="body2" key={i.id}>
                Cód. {`${!!i.detalhe ? i.detalhe : ''} - ${i.descricao}`}
              </Typography>
            )
          })}

          <Typography
            variant="body1"
            color="primary"
            style={{
              // borderBottom: '3px solid #455880',
              lineHeight: '.9rem',
              borderRadius: '2px',
              display: 'inline-block',
              margin: '12px 0 8px'
            }}
          >
            <strong>Médico solicitante </strong>
          </Typography>
          <Typography variant="body2">{`Dr(a). ${medicoNome}`}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          style={ehXS ? { margin: '10px 0' } : { margin: '10px 0' }}
        >
          <Typography
            variant="body1"
            color="primary"
            style={{
              // borderBottom: '3px solid #455880',
              lineHeight: '.9rem',
              borderRadius: '2px',
              display: 'inline-block',
              marginBottom: '8px'
            }}
          >
            <b>Paciente</b>
          </Typography>
          <Grid container>
            <Grid item xs={12} md={isDrawer ? 12 : 4}>
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Nome: </strong>{' '}
                {paciente?.nome}
              </Typography>
            </Grid>
            {paciente?.cpf && (
              <Grid item xs={12} md={isDrawer ? 12 : 4}>
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>CPF: </strong>{' '}
                  {formatarCPF(paciente?.cpf)}
                </Typography>
              </Grid>
            )}
            {paciente?.email && (
              <Grid item xs={12} md={isDrawer ? 12 : 4}>
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>E-mail: </strong>{' '}
                  {paciente?.email}
                </Typography>
              </Grid>
            )}
            {paciente?.telefone && (
              <Grid item xs={12} md={isDrawer ? 12 : 4}>
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>Telefone: </strong>{' '}
                  {paciente?.telefone
                    ? formatarTelefone(paciente?.telefone)
                    : 'Não informado'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={isDrawer ? 12 : 4}>
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Nascimento: </strong>{' '}
                {`${formatarData(paciente?.dataNascimento)} - ${formatarIdade(
                  paciente?.dataNascimento
                )} anos`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={isDrawer ? 12 : 4}>
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Sexo: </strong>{' '}
                {paciente?.sexo === 1 ? 'Masculino' : 'Feminino'}
              </Typography>
            </Grid>
            {!orcamentoParceiro && perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
              <Grid item xs={12} md={isDrawer ? 12 : 6}>
                {/* <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>Telefone: </strong>{' '}
                  {paciente?.telefone
                    ? formatarTelefone(paciente?.telefone)
                    : 'Não informado'}
                </Typography> */}
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>Email: </strong>{' '}
                  {paciente?.email ? paciente?.email : 'Não informado'}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Comorbidades: </strong>{' '}
                {comorbidades && comorbidades.length !== 0
                  ? comorbidades?.map(e => e.descricao).join(', ')
                  : 'Não possui comorbidade'}
              </Typography>
              {obsComorbidades && (
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>Observação: </strong>{' '}
                  {obsComorbidades}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {paciente?.nomeResponsavel && (
          <Grid item xs={12} sm={isDrawer ? 12 : 6}>
            <Typography variant="h6">
              Responsável pelo paciente{' '}
              {parseInt(formatarDiferencaData(paciente?.dataNascimento)) < 18 &&
                '(menor de idade)'}
            </Typography>
            <Typography variant="body2">
              <strong style={{ fontWeight: 600 }}>Nome: </strong>{' '}
              {paciente?.nomeResponsavel}
            </Typography>
            <Typography variant="body2">
              <strong style={{ fontWeight: 600 }}>CPF: </strong>{' '}
              {paciente?.cpfResponsavel}
            </Typography>
          </Grid>
        )}
        {pacoteProcedimento?.obsPacote && (
          <Grid item xs={12} sm={isDrawer ? 12 : 6}>
            <Typography variant="h6">Observações gerais do pacote </Typography>
            <Typography variant="body2">
              {pacoteProcedimento?.obsPacote}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider style={{ margin: '10px 0 10px' }} />
    </Box>
  )
}
