import * as signalR from '@microsoft/signalr'
import { useEffect, useState } from 'react'
import config from 'src/config'
import useAuth from 'src/hooks/useAuth'
import NotificacoesSignalR, { NOTIFICACOES_SYN } from './notificacoesSignalR'

const Notificacao = ({ key }) => {
  const {
    user,
    perfilAtual,
    hubConectado,
    setHubConectado,
    obterNome
  } = useAuth()
  const [conectando, setConectando] = useState<boolean>(false)

  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${config.baseAPI}/synHub`)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: retryContext => {
        if (retryContext.elapsedMilliseconds < 30 * 60 * 1000) {
          return Math.random() * 30 * 1000 + 5000
        } else {
          return null
        }
      }
    })
    .configureLogging(signalR.LogLevel.Information)
    .build()

  useEffect(() => {
    if (!conectando && !hubConectado) startHubConnection()
  }, [hubConnection])

  useEffect(() => {
    hubConnection.onclose(e => {
      setHubConectado(false)
    })

    hubConnection.onreconnecting(e => {
      setHubConectado(false)
    })

    hubConnection.onreconnected(connectionId => {
      setHubConectado(true)
    })

    return () => {
      if (!!hubConnection) {
        hubConnection
          .stop()
          .then(() =>
            console.log(
              ' destruindo ' + hubConnection.connectionId + ' foi desconectado'
            )
          )
        NOTIFICACOES_SYN.forEach(n => {
          hubConnection.off(n.evento)
        })
      }
    }
  }, [])

  async function startHubConnection() {
    if (!hubConnection) return

    setConectando(true)
    try {
      await hubConnection.stop()
      await hubConnection.start()

      console.log(
        '@Conexão iniciada',
        hubConnection.connectionId,
        hubConnection.state
      )

      // invokes sendConnectionId no nosso Hub na nossa aplicação
      var conexaoUsuario = {
        ConexaoId: hubConnection.connectionId,
        UsuarioId: user?.id,
        Nome: obterNome(),
        PerfilAtual: perfilAtual
      }
      if (hubConnection.connectionId) {
        try {
          setTimeout(async () => {
            await hubConnection.invoke('SendConnectionId', conexaoUsuario)
            setHubConectado(true)
            setConectando(false)
          }, 2000)
        } catch (error) {
          console.log('@HUB  error sen id', error)
        }
      }
    } catch (error) {
      console.log('Ocorreu um erro ao tentar iniciar conexão', error)
      setTimeout(startHubConnection, 10000)
    }
  }

  // if (!!hubConnection) {
  //   return
  // }
  if (!!hubConnection) return <NotificacoesSignalR hub={hubConnection} />

  return null
}

// export const NotificacaoMemo = React.memo(Notificacao)
export const NotificacaoMemo = Notificacao
