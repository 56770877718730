import { Chip, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { GoPackage } from 'react-icons/go'

import {
  BusinessCenter,
  ChromeReaderMode,
  Dashboard,
  DynamicFeed,
  LockOpenOutlined,
  LockOutlined,
  People,
  SdCardOutlined
} from '@material-ui/icons'
import { FaTasks } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import Ubox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAtividade from 'src/hooks/useAtividade'
import useAuth from 'src/hooks/useAuth'

function menuLateral() {
  const { perfilAtual, user } = useAuth()
  const history = useHistory()
  const { obterQtdePendente } = useAtividade()

  return (
    <List>
      <Ubox render={perfilAtual === SynPerfis.AGENDAMENTO}>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemOrcamentosHospital)}
        >
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Solicitações" />
        </ListItem>

        <ListItem button onClick={() => history.push(SynRoutes.alterarSenha)}>
          <ListItemIcon>
            <LockOutlined />
          </ListItemIcon>
          <ListItemText primary="Alterar senha" />
        </ListItem>
      </Ubox>

      <Ubox render={perfilAtual !== SynPerfis.AGENDAMENTO}>
        {!!user.verDashboard && (
          <ListItem button onClick={() => history.push(SynRoutes.dashboard)}>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        )}

        <ListItem button onClick={() => history.push(SynRoutes.listagemMedicos)}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="Médicos" />
        </ListItem>

        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemPacotesOperacional)}
        >
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <GoPackage />
          </ListItemIcon>
          <ListItemText primary="Pacotes" />
        </ListItem>

        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemOrcamentosOperacional)}
        >
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Solicitações" />
        </ListItem>

        <ListItem button onClick={() => history.push(SynRoutes.tarefas)}>
          <ListItemIcon style={{ fontSize: '1.3rem' }}>
            <FaTasks />
          </ListItemIcon>
          <ListItemText
            primary={
              <Ubox>
                Atividades{' '}
                {obterQtdePendente() > 0 && (
                  <Chip
                    variant="default"
                    color="secondary"
                    size="small"
                    label={obterQtdePendente()}
                  />
                )}
              </Ubox>
            }
          />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemFinanciamentos)}
        >
          <ListItemIcon>
            <ChromeReaderMode />
          </ListItemIcon>
          <ListItemText primary="Financiamentos" />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemParceirosOperacional)}
        >
          <ListItemIcon>
            <BusinessCenter />
          </ListItemIcon>
          <ListItemText primary="Parceiros" />
        </ListItem>

        <ListItem
          button
          onClick={() => history.push(SynRoutes.historicoAtualizacoes)}
        >
          <ListItemIcon>
            <DynamicFeed />
          </ListItemIcon>
          <ListItemText primary="Atualizações" />
        </ListItem>

        <ListItem button onClick={() => history.push(SynRoutes.resetSenha)}>
          <ListItemIcon>
            <LockOpenOutlined />
          </ListItemIcon>
          <ListItemText primary="Resetar senha" />
        </ListItem>
      </Ubox>
      <ListItem button onClick={() => history.push(SynRoutes.termos)}>
        <ListItemIcon>
          <SdCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItem>
    </List>
  )
}

export default menuLateral
