import { useEffect, useState } from 'react'
import service, { IAddAtividadeCommand } from 'src/api/atividade'
import { gerarGuid } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { NotificacaoMemo } from 'src/signalR/synHub'
import AtividadeContext, { IAtividade } from './context'

const AtividadeProvider = ({ children }) => {
  const { user, signed, perfilAtual } = useAuth()
  const [loading, setLoading] = useState(false)
  const [keyNotificacao, setKeyNotificacao] = useState<string>(gerarGuid())
  const [atividadesHoje, setAtividadesHoje] = useState<IAtividade[]>([])
  const [atividadesConcluidas, setAtividadesConcluidas] = useState<
    IAtividade[]
  >([])

  const [atividadesAtrasadas, setAtividadesAtrasadas] = useState<IAtividade[]>(
    []
  )
  const [atividadesFuturas, setAtividadesFuturas] = useState<IAtividade[]>([])

  // var listagemPendentes = useListagem(9, listagemAtividadesPendentes)

  // function listagemAtividadesPendentes(query, primeiraBusca?: boolean) {
  //   // if (!primeiraBusca) setUltimaSolicitacao()
  //   query['perfilAtual'] = perfilAtual
  //   return service.obterAtividadesPendentes(query)
  // }

  // var listagemFuturas = useListagem(9, listagemAtividadesFuturas)

  // function listagemAtividadesFuturas(query, primeiraBusca?: boolean) {
  //   // if (!primeiraBusca) setUltimaSolicitacao()
  //   query['perfilAtual'] = perfilAtual
  //   return service.obterAtividadesFuturas(query)
  // }

  useEffect(() => {
    if (signed && perfilAtual) {
      setTimeout(atualizarListaAtividades, 800)

      var intervalHub = setInterval(
        () => atualizarHubConnection,
        30 * 60 * 1000
      )
    }

    return () => {
      clearInterval(intervalHub)
    }
  }, [signed, perfilAtual])

  async function adicionarAtividade(atividade: IAddAtividadeCommand) {
    try {
      await service.adicionarAtividade(atividade)

      atualizarListaAtividades()
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  async function removerAtividade(atividadeId: string) {
    try {
      await service.removerAtividade(atividadeId)

      atualizarListaAtividades()
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  const concluirAtividade = async (atividadeId: string) => {
    try {
      await service.concluirAtividade(atividadeId)

      atualizarListaAtividades()
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  async function atualizarHubConnection() {
    setKeyNotificacao(gerarGuid())
  }

  async function atualizarListaAtividades() {
    try {
      // setLoading(true)
      var {
        data: { hoje, atrasadas, concluidas, futuras }
      } = await service.obterAtividades()
      setAtividadesAtrasadas(atrasadas)
      setAtividadesFuturas(futuras)
      setAtividadesHoje(hoje)
      // setAtividadesConcluidas(concluidas)
      setAtividadesConcluidas([])

      // setTimeout(() => setLoading(false), 2000)
    } catch (error) {
      // showNotificationErrorAPI(error)
    }
  }

  async function buscarAtividade(id: string) {
    try {
      let response = await service.buscarAtividade(id)

      return response.data

      // setTimeout(() => setLoading(false), 2000)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  function obterQtdePendente(solicitacaoId: string = null) {
    if (!!solicitacaoId) {
      return [...atividadesHoje, ...atividadesAtrasadas].filter(
        a => a.solicitacao.id === solicitacaoId
      ).length
    }
    return [...atividadesHoje, ...atividadesAtrasadas].length
  }

  function obterQtdeFutura(solicitacaoId: string = null) {
    if (!!solicitacaoId) {
      return atividadesFuturas?.filter(a => a.solicitacao.id === solicitacaoId)
        .length
    }
    return atividadesFuturas?.length
  }
  return (
    <AtividadeContext.Provider
      value={{
        loading,
        obterQtdePendente,
        obterQtdeFutura,
        adicionarAtividade,
        buscarAtividade,
        atividadesHoje,
        atividadesAtrasadas,
        atividadesFuturas,
        atividadesConcluidas,
        atualizarListaAtividades,
        atualizarHubConnection,
        concluirAtividade,
        removerAtividade
      }}
    >
      {!!user && !!perfilAtual && (
        <>
          {/* <div
            style={{
              background: 'green',
              color: 'white',
              textAlign: 'end'
            }}
          >
            Conectado
          </div> */}
          <NotificacaoMemo key={keyNotificacao} />
        </>
      )}
      {children}
    </AtividadeContext.Provider>
  )
}

export default AtividadeProvider
