import { Box, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import InputQuantidade from 'src/components/pacoteInteligente/Form/inputQuantidade';
import { ItemOpmeInteligente } from 'src/components/pacoteInteligente/FormPacoteInteligente';
import UBox from 'src/components/ui/Ubox';
import useAuth from 'src/hooks/useAuth';

interface IListagemProps {
  listaOPME: ItemOpmeInteligente[];
  handleRadiosItem: (tipo: string, value: boolean, item: ItemOpmeInteligente, campo: string) => void;
  handleChangeQuantidadeOpme: (tipo: string, value: number, item: ItemOpmeInteligente) => void;
  obterOpmePorItem: (item: ItemOpmeInteligente) => ItemOpmeInteligente;
  exibirValor: boolean;
  command: any;
}

export default function ListagemDeItensOpme({ command, listaOPME, handleRadiosItem, handleChangeQuantidadeOpme, obterOpmePorItem, exibirValor }: IListagemProps) {
  const [filtro, setFiltro] = useState('fornecedor');
  const [nomeUsuario, setNomeUsuario] = useState([])
  const {
    user,
    ehTenantSyn,
    tenantAtual
  } = useAuth()

  const agruparPorFornecedor = (lista) => {
    return lista.reduce((acc, item) => {
      const fornecedorId = item.fornecedorId;
      if (!acc[fornecedorId]) {
        acc[fornecedorId] = {
          fornecedor: item.fornecedor.descricao,
          itens: []
        };
      }
      acc[fornecedorId].itens.push(item);
      return acc;
    }, {});
  };

  const removerAcentuacao = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const normalizarDescricao = (descricao: string, numPalavras = 2) => {
    const stopwords = ['de'];
    let descricaoNormalizada = removerAcentuacao(descricao)
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, ' ')
      .trim();

    let palavras = descricaoNormalizada.split(' ');

    palavras = palavras.filter(palavra => !stopwords.includes(palavra));

    return palavras.slice(0, numPalavras).join(' ');
  };

  const agruparPorDescricao = (lista: ItemOpmeInteligente[], numPalavras = 2) => {
    return lista.reduce((acc, item) => {
      const descricaoNormalizada = normalizarDescricao(item.descricao, numPalavras);

      if (!acc[descricaoNormalizada]) {
        acc[descricaoNormalizada] = {
          descricao: item.descricao,
          itens: []
        };
      }

      acc[descricaoNormalizada].itens.push(item);
      return acc;
    }, {});
  };

  const obterListaFiltrada = () => {
    let novaLista = [...listaOPME];

    if (filtro === 'fornecedor') {
      const agrupado = agruparPorFornecedor(novaLista);
      Object.values(agrupado).forEach((grupo: { fornecedor: string; itens: ItemOpmeInteligente[] }) => {
        grupo.itens.sort((a, b) => a.valorUnitario - b.valorUnitario);
      });
      return agrupado;
    } else if (filtro === 'descricao') {
      const agrupado = agruparPorDescricao(novaLista, 2);
      Object.values(agrupado).forEach((grupo: { descricao: string; itens: ItemOpmeInteligente[] }) => {
        grupo.itens.sort((a, b) => a.valorUnitario - b.valorUnitario);
      });
      return agrupado;
    } else if (filtro === 'valor') {
      return novaLista.sort((a, b) => a.valorUnitario - b.valorUnitario);
    }
    return novaLista;
  };

  const listaFiltrada = obterListaFiltrada();
  useEffect(() => {
    setNomeUsuario(user.perfisPorTenant.filter(p => p.tenantId === tenantAtual))
  }, [user])

  return (
    <UBox render={listaOPME.length > 0}>

      <Box mt={2} mb={2}>
        <Typography variant="h6" color="primary">
          <b>
            <small>Escolha o tipo de visualização</small>
          </b>
        </Typography>
        <FormControl variant="outlined" fullWidth>
          <Select value={filtro} onChange={(e: React.ChangeEvent<{ value: unknown }>) => setFiltro(e.target.value as string)} displayEmpty>
            <MenuItem value={filtro}>Selecione o tipo de visualização desejada</MenuItem>
            <MenuItem value="fornecedor">Por nome do fornecedor</MenuItem>
            <MenuItem value="descricao">Por nome de OPME</MenuItem>
            <MenuItem value="valor">Por valor</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Typography variant="h6" color="primary">
        <b>
          <small>Selecione e informe a quantidade</small>
        </b>
      </Typography>

      <Box mb={2}>
        <Alert severity="info">
          Os itens estão dispostos do menor para o maior valor.
        </Alert>
      </Box>

      <UBox>
        {filtro === 'fornecedor' && (
          <Grid container spacing={2}>
            {Object.entries(listaFiltrada).map(([fornecedorId, { fornecedor, itens }]: [string, { fornecedor: string, itens: any[] }]) => (
              <Grid item xs={12} key={fornecedorId}>
                <Card>
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" color="primary">
                        Fornecedor:
                      </Typography>
                      <Typography variant="h6" color="secondary">
                        {/* {fornecedor.length > 0 ? fornecedor : 'Próprio Hospital'} */}
                        {fornecedor.length > 0 ? fornecedor : nomeUsuario[0]?.descricao}
                      </Typography>
                    </Box>
                    <Divider />
                    {itens.map((opme, i) => (
                      <Grid key={opme.id} container spacing={0} style={{ marginTop: '10px' }}>
                        <Grid item xs={12} sm={9} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <UBox width="36px">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!command?.opmes?.find(e => opme?.id === e?.id)}
                                    onChange={(e, value) => handleRadiosItem('opmes', value, opme, 'id')}
                                    value={opme}
                                    disabled={opme.quantidadeIncluso > 0}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </UBox>

                          <UBox>
                            <Typography variant="body1">
                              <b>{opme.descricao}</b>
                            </Typography>
                            <Typography variant="body2" style={{ color: '#555', marginTop: 0, lineHeight: 0.95 }}>
                              <small>
                                <b>
                                  {!opme.fornecedorId && (
                                    <>
                                      {opme.quantidadeIncluso > 0
                                        ? `${opme.quantidadeIncluso} unid. fornecido pelo hospital`
                                        : 'fornecido pelo hospital'}
                                      <br />
                                    </>
                                  )}

                                  {exibirValor &&
                                    (opme.podeAcrescentar ||
                                      opme.podeSubtrair ||
                                      opme.quantidadeIncluso === 0) && (
                                      <MsgAcrescimo
                                        ehExterno={!!opme.fornecedorId}
                                        qtdMax={opme.quantidadeIncluso || 0}
                                      />
                                    )}
                                </b>
                              </small>
                            </Typography>
                          </UBox>
                        </Grid>

                        <UBox
                          render={
                            !!command?.opmes?.find(e => opme?.id === e?.id) &&
                            (opme.podeAcrescentar || opme.podeSubtrair || opme.quantidadeIncluso === 0)
                          }
                          component={Grid}
                          item
                          xs={12}
                          sm={3}
                        >
                          <InputQuantidade
                            onValueChange={(value) => handleChangeQuantidadeOpme('opmes', value, opme)}
                            max={opme.quantidadeIncluso === 0 || !!opme?.podeAcrescentar ? 999 : opme?.quantidadeIncluso || 1}
                            min={opme.quantidadeIncluso === 0 || !!opme?.podeSubtrair ? 0 : opme?.quantidadeIncluso || 1}
                            value={obterOpmePorItem(opme)?.quantidade || 0}
                          />
                        </UBox>
                      </Grid>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {filtro === 'descricao' && (
          <Grid container spacing={2}>
            {Object.entries(listaFiltrada).map(([descricao, { descricao: descricaoNome, itens }]: [string, { descricao: string, itens: any[] }]) => (
              <Grid item xs={12} key={descricao}>
                <Card>
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" color="primary">
                        Descrição:
                      </Typography>
                      <Typography variant="h6" color="secondary">
                        {descricaoNome.split(/[-(]/)[0].trim()}
                      </Typography>
                    </Box>

                    <Divider />
                    {itens.map((opme, i) => (
                      <Grid key={opme.id} container spacing={0} style={{ marginTop: '10px' }}>
                        <Grid item xs={12} sm={9} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <UBox width="36px">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!command?.opmes?.find(e => opme?.id === e?.id)}
                                    onChange={(e, value) => handleRadiosItem('opmes', value, opme, 'id')}
                                    value={opme}
                                    disabled={opme.quantidadeIncluso > 0}
                                  />
                                }
                                label=""
                              />
                            </FormGroup>
                          </UBox>

                          <UBox>
                            <Typography variant="body1">
                              <b>{opme.descricao}</b>
                            </Typography>
                            <Typography variant="body2" style={{ color: '#555', marginTop: 0, lineHeight: 0.95 }}>
                              <small>
                                <b>
                                  {!opme.fornecedorId && (
                                    <>
                                      {opme.quantidadeIncluso > 0
                                        ? `${opme.quantidadeIncluso} unid. fornecido pelo hospital`
                                        : 'fornecido pelo hospital'}
                                      <br />
                                    </>
                                  )}
                                  {opme.fornecedorId && (
                                    <>
                                      {'fornecido por - ' + opme.fornecedor.descricao}
                                      <br />
                                    </>
                                  )}
                                  {exibirValor &&
                                    (opme.podeAcrescentar ||
                                      opme.podeSubtrair ||
                                      opme.quantidadeIncluso === 0) && (
                                      <MsgAcrescimo
                                        ehExterno={!!opme.fornecedorId}
                                        qtdMax={opme.quantidadeIncluso || 0}
                                      />
                                    )}
                                </b>
                              </small>
                            </Typography>
                          </UBox>
                        </Grid>

                        <UBox
                          render={
                            !!command?.opmes?.find(e => opme?.id === e?.id) &&
                            (opme.podeAcrescentar || opme.podeSubtrair || opme.quantidadeIncluso === 0)
                          }
                          component={Grid}
                          item
                          xs={12}
                          sm={3}
                        >
                          <InputQuantidade
                            onValueChange={(value) => handleChangeQuantidadeOpme('opmes', value, opme)}
                            max={opme.quantidadeIncluso === 0 || !!opme?.podeAcrescentar ? 999 : opme?.quantidadeIncluso || 1}
                            min={opme.quantidadeIncluso === 0 || !!opme?.podeSubtrair ? 0 : opme?.quantidadeIncluso || 1}
                            value={obterOpmePorItem(opme)?.quantidade || 0}
                          />
                        </UBox>
                      </Grid>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {filtro === 'valor' &&
          listaFiltrada.map((opme, i) => (
            <Grid key={'op' + i} container spacing={0}>
              <Grid item xs={12} sm={9} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <UBox width="36px">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!command?.opmes?.find(e => opme?.id === e?.id)}
                          onChange={(e, value) => handleRadiosItem('opmes', value, opme, 'id')}
                          value={opme}
                          disabled={opme.quantidadeIncluso > 0}
                        />
                      }
                      label=""
                    />
                  </FormGroup>
                </UBox>

                <UBox>
                  <Typography variant="body1">
                    <b>{opme.descricao}</b>
                  </Typography>
                  <Typography variant="body2" style={{ color: '#555', marginTop: 0, lineHeight: 0.95 }}>
                    <small>
                      <b>
                        {!opme.fornecedorId && (
                          <>
                            {opme.quantidadeIncluso > 0
                              ? `${opme.quantidadeIncluso} unid. fornecido pelo hospital`
                              : 'fornecido pelo hospital'}
                            <br />
                          </>
                        )}
                        {/* <span style={{ color: '#b39325' }}>
                          {opme?.valorUnitario} por unidade
                        </span> */}
                        {exibirValor &&
                          (opme.podeAcrescentar ||
                            opme.podeSubtrair ||
                            opme.quantidadeIncluso === 0) && (
                            <MsgAcrescimo
                              ehExterno={!!opme.fornecedorId}
                              qtdMax={opme.quantidadeIncluso || 0}
                            />
                          )}
                      </b>
                    </small>
                  </Typography>
                </UBox>
              </Grid>

              <UBox
                render={
                  !!command?.opmes?.find(e => opme?.id === e?.id) &&
                  (opme.podeAcrescentar || opme.podeSubtrair || opme.quantidadeIncluso === 0)
                }
                component={Grid}
                item
                xs={12}
                sm={3}
              >
                <InputQuantidade
                  onValueChange={(value) => handleChangeQuantidadeOpme('opmes', value, opme)}
                  max={opme.quantidadeIncluso === 0 || !!opme?.podeAcrescentar ? 999 : opme?.quantidadeIncluso || 1}
                  min={opme.quantidadeIncluso === 0 || !!opme?.podeSubtrair ? 0 : opme?.quantidadeIncluso || 1}
                  value={obterOpmePorItem(opme)?.quantidade || 0}
                />
              </UBox>

              <Grid item xs={12}>
                <UBox my={1}>
                  <Divider />
                </UBox>
              </Grid>
            </Grid>
          ))}
      </UBox>
      <br />
    </UBox>
  );
}

type MsgProps = {
  ehExterno?: boolean;
  qtdMax?: number;
}

export const MsgAcrescimo = ({ ehExterno = false, qtdMax = 0 }: MsgProps) => {
  function obterTexto() {
    var msg = '';

    if (!ehExterno) {
      if (qtdMax > 0) {
        msg = `haverá acréscimo no orçamento se selecionado mais que ${qtdMax} unid.`;
      } else {
        msg = 'haverá acréscimo no orçamento ao selecionar esse item';
      }
    } else {
      msg = 'haverá acréscimo no orçamento ao selecionar esse item';
    }

    return msg;
  }
  return <div style={{ color: '#b39325' }}>{obterTexto()}</div>;
};
