import {
  Box,
  // Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import { useContext, useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { obterPacotePorId } from 'src/api/pacote'
import {
  alterarStatusProcedimentoRealizado,
  listagemOrcamento,
  obterOrcamentoPorId,
  obterStatusSolicitacaoOrcamento
} from 'src/api/solicitacao-orcamento/index'
import DrawerVisualizarOcorrencias from 'src/components/drawers/medico/DrawerVisualizarOcorrencias'
import CardListagem from 'src/components/solicitacao/CardListagem'
import ButtonUI, { Button } from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarData } from 'src/core/formatacoes/formatarData'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import { useQuery } from 'src/hooks/useQuery'
import {
  IPacoteDetalhadoResponse,
  ItemListagemSolicitacaoOrcamentoResponse
} from 'src/types'
import useWindowInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'
import { mapToForm } from './formV2'
import NenhumOrcamento from './nenhumOrcamento'
import { ResumoOrcamento } from './resumo'
import { ResumoImpressaoOrcamento } from './resumoImpressao'

import ClearIcon from '@material-ui/icons/Clear'
import { FiFilter } from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import { listagemMedicoPorUsuario } from 'src/api/medico/index'
import { obterPacoteInteligentePorId } from 'src/api/pacoteInteligente'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import DrawerComprovanteDestinatario from 'src/components/solicitacao/drawerComprovanteDestinatario'
import TableSolicitacaoOrcamentoMedico from 'src/components/tables/medico/solicitacaoOrcamentoMedico'
import { SynPerfis } from 'src/core/consts/synPerfis'
import TipoAnexo from 'src/core/consts/tipoAnexo'
import useAuth from 'src/hooks/useAuth'
import DrawerHistoricoEditar from '../../operacional/solicitacao/drawerHistoricoEditar'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '8px',
    margin: '4px 4px',
    padding: '16px !important'
  }
}))

function ListagemOrcamento() {
  const style = useStyles()
  const history = useHistory()
  const query = useQuery()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [showFiltros, setShowFiltros] = useState(false)
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)

  const [pacoteDetalhe, setPacoteDetalhe] = useState(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)
  const [itemPrint, setItemPrint] = useState(null)
  const [lastSelectedCard, setLastSelectedCard] = useState(null)
  const [
    solicitacaoSelecionada,
    setSolicitacaoSelecionada
  ] = useState<ItemListagemSolicitacaoOrcamentoResponse>(null)
  const [mostrarComprovantes, setMostrarComprovantes] = useState<boolean>(false)

  const { signOut, user, perfilAtual, ehTenantSyn } = useAuth()
  const [showTable, setShowTable] = useState(false)
  /*filtro por data*/
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const [statusSelecionado, setStatusSelecionado] = useState('')
  const [dataInicioDirty, setDataInicioDirty] = useState(false)
  const [dataFimDirty, setDataFimDirty] = useState(false)
  const [diasFiltro, setDiasFiltro] = useState('periodo')
  const [orcamentoSolicitacao, setOrcamentoSolicitacao] = useState(null)
  const [abrirHistoricoEditar, setAbrirHistoricoEditar] = useState(false)

  const [visualizarOcorrencias, setVisualizarOcorrencias] = useState({
    show: false,
    id: ''
  })

  const { setUltimaSolicitacao, ultimaSolicitacaoVista } = useContext(
    UltimaSolicitacaoContext
  )

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem,
    allDados,
    allDadosCarregados,
    handleFiltroStatus,
    loadingFiltroStatus,
    filtroStatus,
    listaStatus,
    qtdAllDados,
    pagina,
    loadingPagina,
    handleProxPagina,
    handleCustomPagina,
    listaMedicos,
    filtroMedico,
    loadingMedicoFiltro,
    handleFiltroMedico,
    handleLimparFiltros,
    handleSetTermoPesquisar,
    handlePesquisarDados,
    handleFiltroData
  } = useListagem(
    9,
    listagemSolicitacoesOrcamento,
    obterStatusSolicitacaoOrcamento,
    listagemMedicoPorUsuario
  )

  const data = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  }))

  function listagemSolicitacoesOrcamento(query, primeiraBusca?: boolean) {
    if (!primeiraBusca) setUltimaSolicitacao()
    query['perfilAtual'] = perfilAtual
    return listagemOrcamento(query)
  }

  useWindowInfiniteScroll({
    data: allDados,
    loadMoreFunc: () => handleCustomPagina(pagina + 1),
    stopLoadingData: allDados.length >= qtdAllDados
  })

  function handleNovoOrcamento() {
    if (ehTenantSyn) history.push(SynRoutes.formOrcamentoUnificado)
    else history.push(SynRoutes.formOrcamentoUnificado2)
  }

  useEffect(() => {
    if (query.has('print')) {
      let print = query.get('print')
      setItemPrint(print)
      history.replace(SynRoutes.listagemOrcamentos)
    }
  }, [])

  useEffect(() => {
    handleFiltroPorData()
  }, [diasFiltro])

  const limparFiltroData = () => {
    setDiasFiltro('periodo')
    setDataInicio('')
    setDataFim('')
    setStatusSelecionado('')
    handleLimparFiltros()
  }

  const carregarDataAtualFiltro = () => {
    var dt = new Date()
    var year = dt.getFullYear()
    var month = (dt.getMonth() + 1).toString().padStart(2, '0')
    var day = dt.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + day)
    setDataFim(year + '-' + month + '-' + day)
    setDiasFiltro('hoje')
  }

  const carregarDataPodDiaFiltro = dias => {
    var dataFiltro = new Date()
    var day = dataFiltro.getDate().toString().padStart(2, '0')
    var year = dataFiltro.getFullYear()
    var month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    setDataFim(year + '-' + month + '-' + day)

    dataFiltro.setDate(dataFiltro.getDate() - dias)
    year = dataFiltro.getFullYear()
    month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    var dayModificado = dataFiltro.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + dayModificado)
  }

  const handleFiltroPorData = () => {
    if (diasFiltro === 'periodo') {
      limparFiltroData()
    } else if (diasFiltro === 'hoje') {
      carregarDataAtualFiltro()
    } else if (diasFiltro === '3Dias') {
      carregarDataPodDiaFiltro(3)
    } else if (diasFiltro === '7Dias') {
      carregarDataPodDiaFiltro(7)
    } else if (diasFiltro === '15Dias') {
      carregarDataPodDiaFiltro(15)
    } else if (diasFiltro === '30Dias') {
      carregarDataPodDiaFiltro(30)
    }
  }

  const handleFecharDetalhes = () => {
    setDetalheVisualizacao(null)
    setMostrarDetalhe(false)
  }

  function handleEditar(item: ItemListagemSolicitacaoOrcamentoResponse) {
    if (!!item.pacoteInteligenteId) return

    // idPacote
    history.push(
      `${SynRoutes.formOrcamentoUnificado}?id=${item.id}&medicoSolicitacaoId=${item.medicoId}&descricaoPacote=${item.descricaoPacoteProcedimento}&tipo=cotacao`
    )
  }

  function handleImprimir(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setItemPrint(item.id)
  }

  function handleFecharImprimir() {
    setItemPrint(null)
  }

  const handleVisualizarDetalhes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setDetalheVisualizacao(null)
    setLoadingDetalhe(true)
    setMostrarDetalhe(true)

    obterOrcamentoDetalhe(item.id, (orcamento, pacote) => {
      setDetalheVisualizacao(mapToForm(orcamento))
      setLastSelectedCard(item)
      setPacoteDetalhe(pacote)
      setLoadingDetalhe(false)
    })
  }

  const handleVisualizarComprovantes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setSolicitacaoSelecionada(item)
    setMostrarComprovantes(true)
  }

  const handleFecharComprovantes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setSolicitacaoSelecionada(null)
    setMostrarComprovantes(false)
  }

  const obterOrcamentoDetalhe = (id, callback) => {
    obterOrcamentoPorId(id)
      .then(async response => {
        const orcamento = response.data
        setOrcamentoSolicitacao(orcamento)

        // Carregar pacote

        var data: IPacoteDetalhadoResponse | IPacoteInteligenteForm = {} as any
        if (orcamento.pacoteInstantaneo) {
          const response = await obterPacoteInteligentePorId(
            orcamento.pacoteInteligenteId
          )

          data = response.data
        } else {
          const response = await obterPacotePorId(
            orcamento.pacoteProcedimentoId
          )

          data = response.data
        }

        callback(orcamento, data)
      })
      .catch(tratarErroApi)
  }

  const tratarErroApi = e => {
    setMostrarDetalhe(false)
    showNotificationErrorAPI(e)
  }

  function setClassToHighlight(item) {
    if (item?.id === lastSelectedCard?.id) return 'highlight'

    return null
  }

  function handleVisualizarOcorrencias(solicitacaoId: string) {
    setVisualizarOcorrencias({
      show: true,
      id: solicitacaoId
    })
  }

  async function handleAlterarStatusProcedimentoRealizado(
    idSolicitacao: string
  ) {
    await alterarStatusProcedimentoRealizado(idSolicitacao, perfilAtual)

    carregarListagem({
      resetarPaginacao: true,
      dataInicio: dataInicio,
      dataFim: dataFim
    })
  }

  function handleHistoricoEditar(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    setAbrirHistoricoEditar(true)
    setSolicitacaoSelecionada(item)
  }

  const handleSetDiaFiltro = e => {
    var valor = e.target.value
    setDiasFiltro(valor)
  }

  function handleKeyDownPesquisar(e) {
    if (e.key === 'Enter' && termoPesquisa) {
      handlePesquisarDados()
    }
  }

  function handleKeyDownSelectStatus(e, p0?: boolean) {
    if (e.key === 'Enter' && filtroStatus) {
      handleFiltroStatus(e) // Pass the 'e' argument to the function
    }
  }

  const classes = data()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Box>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {/* Médico */}
            <Typography variant="h5">Solicitações de Orçamentos</Typography>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Box textAlign="right">
              <Button
                fullWidth={isMobile}
                variant="contained"
                color="primary"
                onClick={handleNovoOrcamento}
                startIcon={<AddIcon />}
              >
                Solicitar novo orçamento
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              onKeyDown={e => {
                handleKeyDownPesquisar(e)
              }}
              fullWidth
              placeholder="Pesquise pelo nome do pacote ou do paciente..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: loading && (
                  <CircularProgress
                    style={{
                      fontSize: '20px',
                      width: '20px',
                      height: '20px'
                    }}
                  />
                )
              }}
              value={termoPesquisa}
              onChange={e => {
                if (!e.target.value) {
                  handleSetTermoPesquisar(e as any, true)
                } else {
                  handleSetTermoPesquisar(e as any, false)
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              color="default"
              variant="text"
              onClick={() => setShowFiltros(!showFiltros)}
              startIcon={<FiFilter />}
            >
              {showFiltros ? 'Esconder' : 'Mostrar'} filtros
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          {showFiltros && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}></Grid>
                {perfilAtual === SynPerfis.SECRETARIA && (
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={filtroMedico || ' '}
                        disabled={loadingMedicoFiltro}
                        fullWidth
                        endAdornment={
                          <InputAdornment
                            position="end"
                            style={{ marginRight: '16px' }}
                          >
                            {loadingMedicoFiltro ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <></>
                            )}
                          </InputAdornment>
                        }
                        onChange={handleFiltroMedico}
                      >
                        <MenuItem value=" ">Médicos</MenuItem>
                        {listaMedicos?.map((medico, i) => (
                          <MenuItem key={i} value={medico.id}>
                            {medico.descricao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} md={2}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={statusSelecionado || filtroStatus}
                      onChange={e => {
                        setStatusSelecionado(e.target.value as string)
                      }}
                      disabled={loadingFiltroStatus}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginRight: '16px' }}
                        >
                          {loadingFiltroStatus ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      }
                    >
                      <MenuItem value=" ">Todos os status</MenuItem>
                      {listaStatus.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={diasFiltro}
                      onChange={e => {
                        handleSetDiaFiltro(e)
                      }}
                    >
                      <MenuItem value="periodo">Período</MenuItem>
                      <MenuItem value="hoje">Hoje</MenuItem>
                      <MenuItem key="2" value="3Dias">
                        Últimos 3 dias
                      </MenuItem>
                      <MenuItem key="3" value="7Dias">
                        Últimos 7 dias
                      </MenuItem>
                      <MenuItem key="4" value="15Dias">
                        Últimos 15 dias
                      </MenuItem>
                      <MenuItem key="5" value="30Dias">
                        Últimos 30 dias
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField
                    id="dataInicio"
                    error={dataInicioDirty && !formatarData(dataInicio)}
                    helperText={
                      dataInicioDirty && !formatarData(dataInicio)
                        ? 'Data inválida'
                        : ''
                    }
                    label="De"
                    type="date"
                    defaultValue={dataInicio}
                    value={dataInicio}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      setDataInicioDirty(true)
                    }}
                    onChange={e => {
                      setDataInicio(e.target.value)
                    }}
                  />

                  <TextField
                    error={dataFimDirty && !formatarData(dataFim)}
                    helperText={
                      dataFimDirty && !formatarData(dataFim)
                        ? 'Data inválida'
                        : ''
                    }
                    id="dataFim"
                    label="Até"
                    type="date"
                    defaultValue={dataFim}
                    value={dataFim}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      setDataFimDirty(true)
                    }}
                    onChange={e => {
                      setDataFim(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container style={{ display: 'flex', gap: 4, marginTop: 8 }}>
          <Grid item xs={3} sm={3}>
            <Button
              loading={loading}
              disabled={loading}
              size="small"
              fullWidth={true}
              variant="contained"
              color="secondary"
              startIcon={<GoSearch />}
              onClick={e => {
                carregarListagem({
                  resetarPaginacao: true,
                  dataInicio: dataInicio,
                  dataFim: dataFim,
                  status: statusSelecionado
                })
              }}
            >
              Pesquisar (Enter)
            </Button>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Button
              size="small"
              fullWidth={true}
              variant="contained"
              color="primary"
              startIcon={<ClearIcon />}
              onClick={e => {
                limparFiltroData()
              }}
            >
              Limpar filtros
            </Button>
          </Grid>
        </Grid>
      </Box>
      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {!!ultimaSolicitacaoVista
                  ? 'Última solicitação vista'
                  : qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} solicitações`
                    : '1 solicitação'
                  : ''}
              </Typography>
            </Grid>
            {!!ultimaSolicitacaoVista && (
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUltimaSolicitacao()}
                >
                  Mostrar todas as solicitações
                </Button>
              </Grid>
            )}
            {!ehXS && nenhumResultado && (
              <Grid item xs={12} sm={6}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <ButtonGroup
                    size="small"
                    aria-label="small outlined button group"
                  >
                    <Button
                      onClick={() => setShowTable(false)}
                      color={!showTable ? 'primary' : 'default'}
                      variant={showTable ? 'outlined' : 'contained'}
                    >
                      Card
                    </Button>
                    <Button
                      onClick={() => setShowTable(true)}
                      color={showTable ? 'primary' : 'default'}
                      variant={!showTable ? 'outlined' : 'contained'}
                    >
                      Lista
                    </Button>
                  </ButtonGroup>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {nenhumDado && !nenhumResultado && allDados.length === 0 && (
        <NenhumOrcamento
          handleNovoOrcamento={handleNovoOrcamento}
          nenhumResultado={!!nenhumResultado}
        />
      )}
      {nenhumResultado && (
        <div style={{ marginTop: 20 }}>
          Nenhum resultado encontrado para sua pesquisa.
        </div>
      )}
      {allDadosCarregados && !loadingPagina && !showTable && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {ultimaSolicitacaoVista !== undefined && (
              <Grid item xs={12} md={6} lg={4} key={ultimaSolicitacaoVista.id}>
                <CardListagem
                  perfil={
                    perfilAtual === SynPerfis.MEDICO ? 'medico' : 'secretaria'
                  }
                  setClassToHighlight={setClassToHighlight}
                  handleEditar={handleEditar}
                  handleImprimir={handleImprimir}
                  handleVisualizarDetalhes={handleVisualizarDetalhes}
                  handleVisualizarComprovantes={handleVisualizarComprovantes}
                  navigate={history.push}
                  setUltimaSolicitacao={setUltimaSolicitacao}
                  solicitacao={ultimaSolicitacaoVista}
                  handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                  handleAlterarStatusProcedimentoRealizado={
                    handleAlterarStatusProcedimentoRealizado
                  }
                  handleHistoricoEditar={handleHistoricoEditar}
                />
              </Grid>
            )}

            {allDados.map(item => {
              if (ultimaSolicitacaoVista === undefined) {
                return (
                  <Grid item xs={12} md={6} lg={4} key={item.id}>
                    <CardListagem
                      perfil={
                        perfilAtual === SynPerfis.MEDICO
                          ? 'medico'
                          : 'secretaria'
                      }
                      setClassToHighlight={setClassToHighlight}
                      handleEditar={handleEditar}
                      handleImprimir={handleImprimir}
                      handleVisualizarDetalhes={handleVisualizarDetalhes}
                      handleVisualizarComprovantes={
                        handleVisualizarComprovantes
                      }
                      navigate={history.push}
                      setUltimaSolicitacao={setUltimaSolicitacao}
                      solicitacao={item}
                      handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                      handleAlterarStatusProcedimentoRealizado={
                        handleAlterarStatusProcedimentoRealizado
                      }
                      handleHistoricoEditar={handleHistoricoEditar}
                    />
                  </Grid>
                )
              } else {
                return <></>
              }
            })}
          </Grid>
        </div>
      )}

      {allDadosCarregados && showTable && (
        <TableSolicitacaoOrcamentoMedico
          data={allDados}
          ultimaSolicitacaoVista={ultimaSolicitacaoVista}
          setUltimaSolicitacao={setUltimaSolicitacao}
          handleVisualizarOcorrencia={handleVisualizarOcorrencias}
          handleImprimirGuia={handleImprimir}
          handleEditar={handleEditar}
          handleVisualizarDetalhes={handleVisualizarDetalhes}
        />
      )}

      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      {mostrarDetalhe && detalheVisualizacao && (
        <Drawer
          loading={loadingDetalhe}
          anchor="right"
          open={true}
          titulo="Visualizar solicitação de orçamento"
          handleOnClose={handleFecharDetalhes}
        >
          {!!detalheVisualizacao && (
            <ResumoOrcamento
              orcamento={detalheVisualizacao}
              forcarHonorarios={false}
              pacoteInteligente={detalheVisualizacao.pacoteInstantaneo}
              pacoteSelecionado={pacoteDetalhe}
              orcamentoSolicitacao={orcamentoSolicitacao}
              isMedico
            />
          )}
        </Drawer>
      )}

      {mostrarComprovantes && solicitacaoSelecionada && (
        <DrawerComprovanteDestinatario
          solicitacaoId={solicitacaoSelecionada.id}
          destinatarioId={solicitacaoSelecionada.medicoId}
          open={true}
          handleOnClose={handleFecharComprovantes}
          tipo={TipoAnexo.MEDICO.id}
        />
      )}

      {/* <ModalGenerico open={!!itemPrint} fullScreen={true}> */}
      <ResumoImpressaoOrcamento
        item={itemPrint}
        onClose={handleFecharImprimir}
      />
      {/* </ModalGenerico> */}

      <DrawerVisualizarOcorrencias
        open={visualizarOcorrencias.show}
        handleOnClose={() =>
          setVisualizarOcorrencias({
            show: false,
            id: ''
          })
        }
        solicitacaoId={visualizarOcorrencias.id}
      />

      {abrirHistoricoEditar && (
        <>
          <DrawerHistoricoEditar
            open={abrirHistoricoEditar}
            idItemSelecionado={solicitacaoSelecionada.id}
            handleOnClose={() => setAbrirHistoricoEditar(false)}
          />
        </>
      )}
    </Box>
  )
}

export default ListagemOrcamento
