import {
  Box,
  Card,
  CardHeader,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Send'
import { useEffect, useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import useAuth from 'src/hooks/useAuth'
import Swal from 'sweetalert2'
import {
  listagemOcorrencias,
  listagemOcorrenciasParceiro,
  responderOcorenciaSolicitacaoOrcamento
} from '../../../api/solicitacao-orcamento'
import { formatarDiferencaData } from '../../../core/formatacoes/formatarData'
import { showNotificationErrorAPI } from '../../../core/helpers/notification'
import {
  ItemListagemOcorrenciaResponse,
  ResponderOcorenciaCommand
} from '../../../types'

const useStyles = makeStyles({
  date: {
    color: '#717171',
    textAlign: 'right'
  },
  note: {
    padding: '0 10px'
  },
  comment: {
    margin: '0 10px',
    color: 'black'
  },
  drawer: {
    backgroundColor: '#e02424'
  }
})

type Props = {
  open: boolean
  handleOnClose: () => void
  solicitacaoId: string
  parceiro?: boolean
}

function DrawerVisualizarOcorrencias(props: Props) {
  const [listaOcorrencias, setListaOcorrencias] = useState<
    ItemListagemOcorrenciaResponse[]
  >([])

  const [qtdCaracteres] = useState(300)
  const [observacao, setObservacao] = useState('')
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [qtdObservacao, setQtdObservacao] = useState(qtdCaracteres)
  const styles = useStyles()
  const [loading, setLoading] = useState<boolean>(true)
  const { signOut, user, perfilAtual } = useAuth()

  useEffect(() => {
    if (props.solicitacaoId !== '') {
      if (!!props.parceiro) {
        listagemOcorrenciasParceiro(props.solicitacaoId, user.tipoParceiro)
          .then(res => {
            if (res?.data?.items) setListaOcorrencias(res?.data?.items)
            setLoading(false)
          })
          .catch(err => {
            showNotificationErrorAPI(err)
            setLoading(false)
          })
      } else {
        listagemOcorrencias(props.solicitacaoId)
          .then(res => {
            setListaOcorrencias(res.data.items)
            setLoading(false)
          })
          .catch(err => {
            showNotificationErrorAPI(err)
            setLoading(false)
          })
      }
    }
    setObservacao('')
    setQtdObservacao(300)
    setLoading(true)
  }, [props.solicitacaoId])

  async function handleSalvar() {
    if (observacao.length === 0) {
      Swal.fire('Ops...', 'A mensagem é obrigatória', 'warning')
      return
    }
    try {
      setLoadingSalvar(true)

      var command: ResponderOcorenciaCommand = {
        id: props.solicitacaoId,
        mensagem: observacao,
        perfilAtual: perfilAtual
      }
      await responderOcorenciaSolicitacaoOrcamento(command)
      setLoadingSalvar(false)
      Swal.fire('', 'Mensagem enviada com sucesso', 'success')

      props.handleOnClose()
    } catch (e) {
      setLoadingSalvar(false)
      Swal.fire('Ops...', 'A mensagem é obrigatória', 'warning')
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }
  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Ocorrências"
      handleOnClose={props.handleOnClose}
    >
      {loading ? (
        <LoadingGeral />
      ) : (
        <Box mt={3} style={{ whiteSpace: 'pre-line' }}>
          {listaOcorrencias.length === 0 && (
            <Typography variant="subtitle1">
              Ainda não existem ocorrências para esta solicitação
            </Typography>
          )}

          {/* <Grid> command: {JSON.stringify(observacao)}</Grid> */}
          {listaOcorrencias.map(item => (
            <Box key={`${item.id}`} mt={2}>
              {item.ehMedico || item.ehExterno ? (
                <Card
                  style={{
                    padding: 5,
                    boxShadow: 'none',
                    background: '#d1f4cc'
                  }}
                >
                  <CardHeader
                    title={
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={styles.note}
                      >
                        <Typography variant="subtitle2" component="span">
                          {item.criadoPor?.descricao}
                        </Typography>
                      </Grid>
                    }
                    subheader={
                      <>
                        <Typography className={styles.comment} variant="body2">
                          {item.ocorrencia}
                        </Typography>
                        <Typography
                          className={styles.date}
                          variant="subtitle2"
                          component="h3"
                        >
                          há {formatarDiferencaData(item.data)}
                        </Typography>
                      </>
                    }
                  />
                </Card>
              ) : (
                <Card
                  style={{
                    padding: 5,
                    boxShadow: 'none',
                    background: '#f6f6f6'
                  }}
                >
                  <CardHeader
                    title={
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={styles.note}
                      >
                        <Typography variant="subtitle2" component="span">
                          Time SYN
                        </Typography>
                      </Grid>
                    }
                    subheader={
                      <>
                        <Typography className={styles.comment} variant="body2">
                          {item.ocorrencia}
                        </Typography>
                        <Typography
                          className={styles.date}
                          variant="subtitle2"
                          component="h3"
                        >
                          há {formatarDiferencaData(item.data)}
                        </Typography>
                      </>
                    }
                  />
                </Card>
              )}
            </Box>
          ))}
          <UBox>
            <Box mt={5}>
              <TextField
                fullWidth
                required
                label="Enviar mensagem"
                multiline
                rows={6}
                placeholder="Digite alguma mensagem..."
                variant="outlined"
                value={observacao}
                onChange={e => {
                  setObservacao(e.target.value)
                  setQtdObservacao(qtdCaracteres - e.target.value.length)
                }}
                inputProps={{ maxLength: qtdCaracteres }}
              />
              {qtdObservacao <= 10 ? (
                <Typography variant="subtitle2" component="h3" color="error">
                  {qtdObservacao} caracteres restantes
                </Typography>
              ) : (
                <Typography variant="subtitle2" component="h3" color="primary">
                  {qtdObservacao} caracteres restantes
                </Typography>
              )}
            </Box>

            <Box mt={3}>
              <Button
                disabled={loadingSalvar}
                fullWidth
                onClick={handleSalvar}
                loading={loadingSalvar}
                secondaryLoading
                startIcon={<DeleteIcon />}
              >
                Enviar
              </Button>
            </Box>
          </UBox>
        </Box>
      )}
    </Drawer>
  )
}

export default DrawerVisualizarOcorrencias
