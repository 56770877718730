import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { GiAlarmClock } from 'react-icons/gi'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import UBox from 'src/components/ui/Ubox'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAtividade from 'src/hooks/useAtividade'
import { UserAuth } from 'src/types'

interface IProps {
  message: any
  user?: UserAuth
}

export const callbackLembreteAtividadeAtrasada = (message, user = null) => {
  if (message.from !== user.id) {
    toast.info(<LembreteAtividadeAtrasada message={message} user={user} />, {
      autoClose: false
    })
  }
}

export default function LembreteAtividadeAtrasada({ message, user }: IProps) {
  const { atualizarListaAtividades } = useAtividade()
  const history = useHistory()

  function play() {
    var audio = new Audio('sound/notification.mp3')
    audio.play()
  }

  useEffect(() => {
    atualizarListaAtividades()
    play()
  }, [])

  function handleOnClick() {
    history.push(SynRoutes.tarefas)
  }

  return (
    <UBox onClick={handleOnClick}>
      <UBox mb={2} display="flex">
        <GiAlarmClock style={{ marginRight: '8px', fontSize: '1.3rem' }} />
        <Typography variant="body2">
          <b>Atividade atrasada:</b>
        </Typography>
      </UBox>
      <Typography variant="body2">{message}</Typography>
    </UBox>
  )
}
