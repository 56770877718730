import { useState, useEffect, useRef, ReactInstance } from 'react'
import {
  Box,
  Card,
  Chip,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Link
} from '@material-ui/core'
import { FiClock, FiInfo } from 'react-icons/fi'
import {
  Alert,
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineDot,
  TimelineSeparator,
  TimelineConnector
} from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  obterDadosPropostaPorIdMedico,
  obterHistoricoPropostasPaciente,
  aprovadoPeloPaciente,
  recusadoPeloPaciente
} from 'src/api/proposta/index'
import { useQuery } from 'src/hooks/useQuery'
import {
  IPaciente,
  IPacoteOrcado,
  IMedico,
  DadosPropostaHistorico,
  ResumoPropostaResponse,
  ObjetoDescricaoGenerico
} from 'src/types'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import { useHistory } from 'react-router-dom'
import StatusProposta from 'src/core/consts/statusProposta'
import Button from 'src/components/ui/Button'
import ImpressaoProposta, {
  DadosImpressao,
  IOPMESelecionado
} from '../operacional/solicitacao/impressaoProposta'
import { useReactToPrint } from 'react-to-print'
import DetalhesPropostaMobile from 'src/components/propostas/DetalhesPropostaMobilePaciente'
import { TextField } from 'src/components/ui/TextField'
import ModalGenerico from 'src/components/modals/ModalGenerico'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import Swal from 'sweetalert2'
import HeaderSolicitacao from 'src/components/solicitacao/HeaderSolicitacao'
import { RiArrowLeftLine } from 'react-icons/ri'
import { primeiraLetraMaiusculo } from 'src/core/helpers/common'
import useAuth from 'src/hooks/useAuth'
import { SynRoutes } from 'src/core/consts/synRoutes'
import UBox from 'src/components/ui/Ubox'
import informarcaoAlert from 'src/core/informacoes/informacaoAlert'

const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const contestacaoSchema = yup.object().shape({
  contestacao: yup.string().required().min(4).label('Motivação')
})

const useStyles = makeStyles(theme => ({
  '.aprovadoDot': {
    backgroundColor: StatusProposta.APROVADA_PELO_MEDICO.Color
  },
  '.contestadoDot': {
    backgroundColor: StatusProposta.RECUSADA_PELO_MEDICO.Color
  },
  '.enviadoDot': {
    backgroundColor: StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
  },
  '.aguardandoPacienteDot': {
    backgroundColor: StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
  },
  '.aprovadaPacienteDot': {
    backgroundColor: StatusProposta.APROVADA_PELO_PACIENTE.Color
  },
  headerDivider: {
    margin: '10px 0 30px 0'
  },
  moreIcon: {
    position: 'absolute',
    right: 20,
    transform: 'rotateZ(-90deg) translateY(50%)'
  },
  containerCard: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 1300px)': {
      flexDirection: 'column'
    }
  },
  containerCardDetail: {
    minWidth: '540px',
    '@media (max-width: 1300px)': {
      minWidth: '700px'
    },
    '@media (max-width: 1005px)': {
      minWidth: '640px'
    }
  },
  historyCard: {
    cursor: 'pointer',
    background: theme.palette.grey[50],
    boxShadow: 'none',
    border: '1px solid #efefef',
    minWidth: '287px',
    position: 'relative',
    '@media (max-width: 1300px)': {
      minWidth: '665px'
    },
    '@media (max-width: 1005px)': {
      minWidth: '600px'
    },
    '@media (max-width: 955px)': {
      minWidth: '700px'
    },
    '@media (max-width: 760px)': {
      minWidth: '320px'
    }
  },
  boxPropostaDetalhe: {
    minHeight: 400,
    background: theme.palette.grey[50],
    boxShadow: 'none',
    border: '1px solid #efefef'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #465b82',
    margin: '20px 0'
  },
  hositalCardText: {
    margin: '1px 0'
  },
  cardDivider: {
    margin: '10px 0'
  },
  OPMECard: {
    padding: '20px',
    marginBottom: '20px',
    border: '1px solid #efefef',
    boxShadow: 'none',
    background: theme.palette.background.paper
  },
  timeline: {
    '&& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 'none'
    },
    '&& .MuiTimelineDot-root': {
      marginTop: 0,
      marginBottom: 0
    },
    '&& .MuiTimelineConnector-root': {
      backgroundColor: '#e4e4e4'
    }
  },
  aprovadoPacienteAlert: {
    backgroundColor: '#8d44ad39',
    color: '#8e44ad',
    '& .MuiAlert-icon': {
      color: '#8e44ad'
    }
  },
  aguardandoPacienteAlert: {
    backgroundColor: '#E67F2439',
    color: '#c26b20',
    '& .MuiAlert-icon': {
      color: '#c26b20'
    }
  },
  propostaVencedoraAlert: {
    backgroundColor: '#009431',
    color: '#fff',
    '& .MuiAlert-icon': {
      color: '#fff'
    }
  },
  boxAlert: {
    '& .MuiAlert-message': {
      width: '100%'
    }
  }
}))

export default function DetalhesPropostas() {
  const { signOut, user, perfilAtual } = useAuth()
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [showDetalhesMobile, setShowDetalhesMobile] = useState(false)

  const [showContestacao, setShowContestacao] = useState(false)
  const [contestacaoLoading, setContestacaoLoading] = useState(false)
  const [aprovacaoLoading, setAprovacaoLoading] = useState(false)

  const [loading, setLoading] = useState(true)
  const [card, setCard] = useState<number | undefined>(0)
  const [pacoteInteligente, setPacoteInteligente] = useState(false)

  const [
    propostaSelecionada,
    setPropostaSelecionada
  ] = useState<ResumoPropostaResponse>(null)
  const [loadingProposta, setLoadingProposta] = useState(true)

  const [medico, setMedico] = useState<IMedico>()
  const [paciente, setPaciente] = useState<IPaciente>()
  const [listaPropostas, setListaProposta] = useState<
    DadosPropostaHistorico[]
  >()
  const [pacoteProcedimento, setPacoteProcedimento] = useState<IPacoteOrcado>()

  const [valorExames, setValorExames] = useState(0)

  useEffect(() => {
    const id = query.get('id')

    obterHistoricoPropostasPaciente(id)
      .then(response => {
        setMedico(response.data.medico)
        setPaciente(response.data.paciente)
        setListaProposta(response.data.listaPropostas)
        setPacoteProcedimento(response.data.pacoteProcedimento)
        setPacoteInteligente(response.data.pacoteInteligente)

        handleSelecionarProposta(0, response.data.listaPropostas[0])
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        history.goBack()
      })
  }, [])

  const [dadosImpressao, setDadosImpressao] = useState<
    DadosImpressao | undefined
  >()
  const impressaoRef = useRef<HTMLElement>()
  const handlePrint = useReactToPrint({
    content: () => impressaoRef.current as ReactInstance
  })

  const { handleSubmit, errors, register } = useForm<{
    contestacao: string
  }>({
    defaultValues: {
      contestacao: ''
    },
    resolver: yupResolver(contestacaoSchema)
  })

  function setStatusColor(status: number) {
    if (status === 2) return StatusProposta.APROVADA_PELO_MEDICO.Color
    if (status === 3) return StatusProposta.RECUSADA_PELO_MEDICO.Color
    if (status === 1) return StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
    if (status === 4) return StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
    if (status === 5) return StatusProposta.APROVADA_PELO_PACIENTE.Color
    if (status === 6) return StatusProposta.VENCEDORA.Color
    if (status === 7) return StatusProposta.RECUSADA_PELO_PACIENTE.Color
  }

  function renderPalavraStatus(status: number) {
    for (const key in StatusProposta) {
      let statusProposta = StatusProposta[key]
      if (statusProposta.Id === status) return statusProposta.Descricao
    }
  }

  function handleSelecionarProposta(index, proposta) {
    setLoadingProposta(true)
    setCard(index)

    obterDadosPropostaPorIdMedico(proposta.id)
      .then(response => {
        const dados = response.data
        setPropostaSelecionada(dados)
        setLoadingProposta(false)
        const impressao: DadosImpressao = {
          medico: dados.medicoNome,
          paciente: dados.paciente,
          listaOPME: dados.listaOPME as IOPMESelecionado[],
          localAtendimento: dados.localAtendimento,
          pacoteProcedimento: dados.pacoteProcedimento,
          valorTotal: dados.valorTotal,
          examesLaboratorio: dados.examesLaboratorio,
          temAnestesia: dados.temAnestesia,
          temPreAnestesica: dados.temPreAnestesica,
          temRiscoCirurgico: dados.temRiscoCirurgico,
          temExames: dados.temExames,
          temReservaSangue: dados.temReservaSangue,
          propostaSemLocalAtendimento: dados.propostaSemLocalAtendimento,
          propostaSemHonorarioMedico: dados.propostaSemHonorarioMedico,
          propostaSemOpme: dados.propostaSemOpme,
          codigoSolicitacao: dados.codigoSolicitacao,
          codigoProposta: dados.codigoProposta,
          criado: dados.criado,
          temTaxaSyn: dados.temTaxaSyn,
          valorAnestesia: dados.valorAnestesia,
          valorTotalOPME: dados.valorTotalOPME,
          valorBancoSangue: dados.valorBancoSangue,
          valorReservaSangue: dados.valorReservaSangue,
          valorComissaoSyn: dados.valorComissaoSyn,
          temBolsaSangue: dados.temBolsaSangue,
          honorarioMedico: dados.honorarioMedico,
          valorSala: dados.valorSala,
          status: dados.status,
          descricaoAnestesia: dados.descricaoAnestesia,
          descricaoMaterial: dados.descricaoMaterial,
          descricaoEquipamento: dados.descricaoEquipamento,
          descricaoDiarias: dados.descricaoDiarias,
          tempoCirurgico: dados.tempoCirurgico,
          observacaoExame: dados.observacaoExame,
          observacaoPacotePaciente: dados.observacaoPacotePaciente,
          observacaoSangue: dados.observacaoSangue,
          statusSolicitacao: dados.statusSolicitacao,
          urlLogomarca: dados.urlLogomarca,
          propostaCentroCirurgicoAmbulatorial:
            dados.propostaCentroCirurgicoAmbulatorial,
          propostaFinalDeSemana: dados.propostaFinalDeSemana
        }

        var totalExames = 0
        dados.examesLaboratorio.exames.forEach(item => {
          totalExames += item.valor
        })

        setValorExames(totalExames)
        setDadosImpressao(impressao)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingProposta(false)
      })
  }

  async function submitContestacao(data: { contestacao: string }) {
    setContestacaoLoading(true)

    const body = {
      propostaId: propostaSelecionada.id,
      motivo: data.contestacao,
      perfilAtual: perfilAtual
    }

    try {
      await recusadoPeloPaciente(body)

      Swal.fire({
        title: 'Obrigado!',
        text: 'Proposta recusada com sucesso.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        willClose: () => {
          setContestacaoLoading(false)
          setShowContestacao(false)
        }
      })

      const response = await obterHistoricoPropostasPaciente(query.get('id'))
      setMedico(response.data.medico)
      setPaciente(response.data.paciente)
      setListaProposta(response.data.listaPropostas)
      setPacoteProcedimento(response.data.pacoteProcedimento)

      handleSelecionarProposta(0, response.data.listaPropostas[0])
      setLoading(false)
    } catch (err) {
      history.goBack()
      showNotificationErrorAPI(err)
      setContestacaoLoading(false)
      setShowContestacao(false)
    }
  }

  async function submitAprovacao() {
    setAprovacaoLoading(true)
    const body = {
      propostaId: propostaSelecionada.id,
      perfilAtual: perfilAtual
    }

    try {
      await aprovadoPeloPaciente(body)

      await Swal.fire({
        title: 'Sucesso!',
        text: 'A proposta foi aprovada',
        icon: 'success',
        confirmButtonText: propostaSelecionada.pagamentoHabilitado
          ? 'Seguir para pagamento'
          : 'Ok'
      }).then(result => {
        if (result.isConfirmed && propostaSelecionada.pagamentoHabilitado) {
          navegarParaPagamento()
        }
      })

      const response = await obterHistoricoPropostasPaciente(query.get('id'))
      setMedico(response.data.medico)
      setPaciente(response.data.paciente)
      setListaProposta(response.data.listaPropostas)
      setPacoteProcedimento(response.data.pacoteProcedimento)

      handleSelecionarProposta(0, response.data.listaPropostas[0])
      setAprovacaoLoading(false)
    } catch (err) {
      showNotificationErrorAPI(err)
      setAprovacaoLoading(false)
    }
  }

  function verificarSeExiste(item: boolean, texto: string) {
    if (!item) return ''
    return texto
  }

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function obterItensContemplados() {
    var itens = []

    var dados = propostaSelecionada

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemLocalAtendimento,
        'valor do hospital'
      )
    )
    itens.push(verificarSeExiste(dados?.temAnestesia, 'anestesia'))

    itens.push(
      verificarSeExiste(dados?.temPreAnestesica, 'avaliação pré-anestésica')
    )
    itens.push(
      verificarSeExiste(
        dados?.temRiscoCirurgico,
        'avaliação de risco cirúrgico'
      )
    )

    itens.push(verificarSeExiste(dados?.temExames, 'exames pré-operatórios'))
    itens.push(
      verificarSeExiste(dados?.listaOPME?.length !== 0, "OPME's, taxa de OPME")
    )
    itens.push(verificarSeExiste(dados?.temTaxaSyn, 'taxa de serviço'))
    itens.push(
      verificarSeExiste(
        dados?.temReservaSangue,
        'reserva de sangue, bolsa(s) de sangue'
      )
    )

    itens = itens.filter(i => !!i)

    if (itens.length > 0) {
      var texto = itens.join(', ')
      if (!dados.propostaSemHonorarioMedico) {
        return primeiraLetraMaiusculo(`${texto} e honorários equipe médica.`)
      } else {
        return primeiraLetraMaiusculo(`${texto}.`)
      }
    }
    if (!dados.propostaSemHonorarioMedico) {
      return 'Honorários equipe médica.'
    } else {
      return ''
    }
  }

  function navegarParaPagamento() {
    history.push(`${SynRoutes.resumoPagamento}?p=${propostaSelecionada.id}`)
  }

  function gerarDescricao(item) {
    let partes = []
    if (!!item.diasUteis) {
      partes.push('em qualquer dia da semana')
    }
    if (!!item.finalDeSemana) {
      partes.push('nos finais de semana')
    }
    if (!!item.centroCirurgicoAmbulatorial) {
      partes.push('no centro cirúrgico ambulatorial')
    }
    if (
      !item.diasUteis &&
      !item.finalDeSemana &&
      !item.centroCirurgicoAmbulatorial
    ) {
      partes.push('em qualquer dia e horário')
    }

    let descricao = 'Para realização '
    if (partes.length === 1) {
      descricao += partes[0] + '.'
    } else if (partes.length === 2) {
      descricao += partes.join(' ') + '.'
    }
    return descricao
  }

  return (
    <Box>
      {showDetalhesMobile && ehXS ? (
        <DetalhesPropostaMobile
          handlePrint={handlePrint}
          handleClose={() => setShowDetalhesMobile(false)}
          propostaSelecionada={propostaSelecionada}
          esconderDadosSensiveis
          contestacaoLoading={contestacaoLoading}
          aprovacaoLoading={aprovacaoLoading}
          submitContestacao={submitContestacao}
          submitAprovacao={submitAprovacao}
          valorExames={valorExames}
          verificarSeExiste={verificarSeExiste}
        />
      ) : (
        <>
          <Link
            component="button"
            color="primary"
            onClick={() => history.goBack()}
            style={{ marginBottom: '20px' }}
          >
            <RiArrowLeftLine />
            <Typography
              style={{ marginLeft: 10 }}
              variant="subtitle1"
              component="span"
            >
              Voltar
            </Typography>
          </Link>
          <HeaderSolicitacao
            proposta
            pacoteProcedimento={pacoteProcedimento}
            paciente={paciente}
            medicoNome={medico.nome}
            comorbidades={paciente.comorbidades as ObjetoDescricaoGenerico[]}
            obsComorbidades={paciente.observacaoComorbidades}
          />

          <Grid container className={styles.containerCard}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Propostas</Typography>
              <Timeline
                className={styles.timeline}
                align="left"
                style={{ transform: 'translateX(-40px)' }}
              >
                <Grid item>
                  {listaPropostas?.map((p, i) => {
                    return (
                      <TimelineItem key={p.codigo}>
                        <TimelineSeparator>
                          <TimelineDot
                            style={{
                              backgroundColor: setStatusColor(p.status)
                            }}
                          />
                          {i !== listaPropostas.length - 1 && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box
                            mb={2}
                            component={Card}
                            className={styles.historyCard}
                            style={
                              card === i
                                ? {
                                    border: '2px solid #000'
                                  }
                                : null
                            }
                            onClick={() => {
                              if (p.status !== StatusProposta.ENCERRADA.Id) {
                                handleSelecionarProposta(i, p)
                                setShowDetalhesMobile(true)
                              }
                            }}
                          >
                            <Box p={2}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    width: '100%',
                                    overflowX: 'hidden'
                                  }}
                                >
                                  <UBox maxWidth="90%" mb={2}>
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        textTransform: 'uppercase'
                                      }}
                                      color="primary"
                                    >
                                      <b>{p.internacao}</b>
                                    </Typography>

                                    <Box style={{ paddingRight: '10px' }}>
                                      <Typography
                                        variant="body2"
                                        color="secondary"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          fontSize: '16px',
                                          textTransform: 'capitalize'
                                        }}
                                      >
                                        <b>{p.localAtendimento}</b>
                                      </Typography>
                                    </Box>
                                    <UBox
                                      style={{
                                        background: '#21ebc7',
                                        width: '60px',
                                        height: '4px',
                                        borderRadius: '4px'
                                      }}
                                      display="block"
                                    ></UBox>
                                  </UBox>
                                  {p?.status === 6 ? (
                                    <Chip
                                      variant="default"
                                      size="small"
                                      label={renderPalavraStatus(p.status)}
                                      style={{
                                        color: '#fff',
                                        backgroundColor: setStatusColor(
                                          p.status
                                        )
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      variant="outlined"
                                      size="small"
                                      label={renderPalavraStatus(p.status)}
                                      style={{
                                        color: setStatusColor(p.status),
                                        borderColor: setStatusColor(p.status)
                                      }}
                                    />
                                  )}
                                  <Typography
                                    variant="subtitle2"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    <b>
                                      <small>Valor total:</small>
                                    </b>{' '}
                                    {formatarDinheiro(p.valorTotal)}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    <b>
                                      <small>Código:</small>
                                    </b>{' '}
                                    {p.codigo}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    <b>
                                      <small>
                                        {p?.centroCirurgicoAmbulatorial === true
                                          ? 'Centro cirúrgico ambulatorial'
                                          : 'Centro cirúrgico'}
                                      </small>
                                    </b>
                                  </Typography>

                                  <UBox style={{ marginBottom: '10px' }} render={!!pacoteInteligente || !!p.finalDeSemana}>
                                    <Alert
                                      severity="warning"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        padding: '8px'
                                      }}
                                      iconMapping={{
                                        warning: (
                                          <FiInfo style={{ color: 'orange' }} />
                                        )
                                      }}
                                    >
                                      {gerarDescricao(p)}
                                      <br />
                                      {p.diasUteis === true &&
                                        'Segunda a sábado até as 12h.'}
                                      {p.finalDeSemana === true &&
                                        'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                                    </Alert>
                                  </UBox>
                                  <Typography variant="body2">
                                    <UBox
                                      mt={2}
                                      component="small"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="end"
                                      mr={1}
                                    >
                                      <Box
                                        component={FiClock}
                                        style={{ marginRight: '4px' }}
                                      />
                                      <b>
                                        <i>
                                          {formatarDataHoraLocal(p.dataEnvio)}
                                        </i>
                                      </b>
                                    </UBox>
                                  </Typography>
                                </Grid>

                                <ExpandMoreIcon className={styles.moreIcon} />
                              </Grid>
                            </Box>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })}
                </Grid>
              </Timeline>
            </Grid>
            {!ehXS && (
              <Grid item xs={12} sm={8} className={styles.containerCardDetail}>
                <Box display="flex" justifyContent="start-between">
                  <Box width="100%">
                    <Typography variant="h6">Detalhes da proposta</Typography>
                  </Box>
                </Box>
                <Box
                  width="100%"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '8px'
                  }}
                >
                  {propostaSelecionada &&
                    propostaSelecionada?.status ===
                      StatusProposta.VENCEDORA.Id &&
                    propostaSelecionada.pagamentoHabilitado && (
                      <Button
                        variant="outlined"
                        onClick={() => navegarParaPagamento()}
                      >
                        Pagamento
                      </Button>
                    )}
                  {!loadingProposta &&
                    ![
                      StatusProposta.ENCERRADA.Id,
                      StatusProposta.RECUSADA_PELO_PACIENTE.Id,
                      StatusProposta.RECUSADA_PELO_MEDICO.Id
                    ].includes(propostaSelecionada?.status) && (
                      <Button variant="outlined" onClick={() => handlePrint()}>
                        Visualizar
                      </Button>
                    )}
                </Box>
                <Box
                  mt={2}
                  py={2}
                  px={2}
                  minHeight="400"
                  component={Card}
                  className={styles.boxPropostaDetalhe}
                >
                  <Box mt={4} hidden={!loadingProposta} textAlign="center">
                    <CircularProgress />
                    <div>Carregando...</div>
                  </Box>
                  {propostaSelecionada && (
                    <Box hidden={loadingProposta}>
                      <>
                        {propostaSelecionada?.status ===
                          StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="success"
                            className={styles.aprovadoPacienteAlert}
                          >
                            {StatusProposta.APROVADA_PELO_PACIENTE.Descricao}
                          </Alert>
                        )}
                        {propostaSelecionada?.status ===
                          StatusProposta.RECUSADA_PELO_PACIENTE.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="error"
                          >
                            {propostaSelecionada.motivo}
                          </Alert>
                        )}

                        {propostaSelecionada?.status ===
                          StatusProposta.VENCEDORA.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="success"
                            className={styles.propostaVencedoraAlert}
                          >
                            {StatusProposta.VENCEDORA.Descricao}
                          </Alert>
                        )}

                        <Box width="100%" textAlign="center" mb={5}>
                          <Card className={styles.hospitalCard}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <b>Valor total da proposta:</b>
                                </Typography>

                                <Box>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body1"
                                        style={{ fontSize: '18px' }}
                                      >
                                        {formatarDinheiro(
                                          propostaSelecionada?.valorTotal
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Alert
                                  style={{
                                    marginBottom: '10px',
                                    marginTop: '16px',
                                    width: '100%'
                                  }}
                                  className={styles.boxAlert}
                                  severity="info"
                                >
                                  <Typography variant="body1">
                                    <b>O valor total da proposta contempla:</b>
                                    <br />
                                    {obterItensContemplados()}
                                  </Typography>
                                </Alert>
                                <UBox
                                  render={
                                    propostaSelecionada?.informacaoExame
                                      ?.length > 0
                                  }
                                >
                                  <Alert
                                    style={{
                                      marginBottom: '10px',
                                      marginTop: '16px',
                                      width: '100%'
                                    }}
                                    className={styles.boxAlert}
                                    severity="info"
                                  >
                                    <Typography variant="body1">
                                      {propostaSelecionada?.informacaoExame}
                                    </Typography>
                                  </Alert>
                                </UBox>

                                {propostaSelecionada.observacaoHospital && (
                                  <Alert
                                    style={{
                                      marginBottom: '10px',
                                      marginTop: '16px',
                                      width: '100%'
                                    }}
                                    className={styles.boxAlert}
                                    severity="info"
                                  >
                                    <Typography variant="body1">
                                      <b>Observações:</b>
                                      <br />
                                      Hospital:{' '}
                                      {propostaSelecionada?.observacaoHospital}
                                      <br />
                                    </Typography>
                                  </Alert>
                                )}

                                {propostaSelecionada?.propostaSemHonorarioMedico && (
                                  <Box width="100%" textAlign="left" my={2}>
                                    <Box mt={2}>
                                      <Alert severity="info">
                                        <Typography variant="subtitle2">
                                          Proposta não contempla honorários da
                                          equipe médica.
                                        </Typography>
                                      </Alert>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </Card>
                        </Box>

                        <UBox
                          style={{ marginBottom: '10px' }}
                          render={
                            !!propostaSelecionada.pacoteInteligente ||
                            !!propostaSelecionada?.propostaFinalDeSemana
                          }
                        >
                          <Alert
                            severity="warning"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              padding: '8px'
                            }}
                            iconMapping={{
                              warning: <FiInfo style={{ color: 'orange' }} />
                            }}
                          >
                            {informarcaoAlert(propostaSelecionada)}
                            <br />
                            {propostaSelecionada.propostaDiasUteis === true &&
                              'Segunda a sábado até as 12h.'}
                            {propostaSelecionada?.propostaFinalDeSemana ===
                              true &&
                              'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                          </Alert>
                        </UBox>

                        <Box width="100%" textAlign="left" my={2}>
                          <Box mt={2}>
                            <Alert severity="info">
                              <p style={{ marginTop: 0 }}>
                                Não abrangem OPME's, materiais, perneiras, manta
                                térmica e exames não previstos na proposta, além
                                de intervenções de outras especialidades,
                                reoperações, hemoterapia, nutrição especial,
                                quimioterápicos e terapias renais.{' '}
                              </p>
                              <p>
                                Cobranças adicionais incluem diárias extras de
                                enfermaria por R$ 1.600 e de apartamento por R$
                                1.800, além de diárias de UTI não programadas,
                                que serão cobradas como conta aberta.{' '}
                              </p>
                              <p>
                                O orçamento é válido para cirurgias sem
                                intercorrências e para pacientes sem alergia a
                                látex; quaisquer complicações serão cobradas
                                como conta aberta (direto com o hospital).
                              </p>
                            </Alert>
                          </Box>
                        </Box>

                        {propostaSelecionada?.status ===
                          StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Button
                                style={{ ...btnRed }}
                                color="default"
                                variant="outlined"
                                fullWidth
                                onClick={() => setShowContestacao(true)}
                              >
                                Recusar
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                loading={aprovacaoLoading}
                                disabled={aprovacaoLoading}
                                onClick={() => submitAprovacao()}
                              >
                                Aprovar
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
          <ModalGenerico
            title="Por favor, conte-nos o motivo por ter recusado"
            open={showContestacao}
            onClose={() => setShowContestacao(false)}
            actions={
              <>
                <Button
                  color="default"
                  onClick={() => {
                    setShowContestacao(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleSubmit(submitContestacao)}
                  loading={contestacaoLoading}
                  disabled={contestacaoLoading}
                >
                  Enviar
                </Button>
              </>
            }
          >
            <Box>
              <TextField
                erro={errors}
                multiline
                rows={6}
                name="contestacao"
                label="Motivação"
                inputRef={register}
              />
            </Box>
          </ModalGenerico>
        </>
      )}

      <ImpressaoProposta ref={impressaoRef} dados={dadosImpressao} />
    </Box>
  )
}
