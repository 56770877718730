import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { alterarStatusProposta, alterarStatusPropostaEncerrada } from 'src/api/solicitacao-orcamento';
import Button from 'src/components/ui/Button';
import Drawer from 'src/components/ui/Drawer';
import TextField from 'src/components/ui/TextField';
import UBox from 'src/components/ui/Ubox';
import yup from 'src/components/yupCustom';
import StatusProposta from 'src/core/consts/statusProposta';
import { showNotificationErrorAPI } from 'src/core/helpers/notification';
import { AlterarStatusPropostaCommand, DadosPropostaHistorico } from 'src/types';
import Swal from 'sweetalert2';

type Props = {
  proposta: DadosPropostaHistorico
  open: boolean;
  ehAguardandoRespostaPaciente?: boolean
  isDisabled?: boolean
  handleOnClose: (boolean?) => void
};

export interface IObservacaoForm {
  motivo: string;
}

const schema = yup.object().shape({
  motivo: yup.string().required().label('Observação')
})

function DrawerAlterarStatusProposta({ open, handleOnClose, isDisabled, proposta, ehAguardandoRespostaPaciente }: Props) {
  const theme = useTheme();
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [loading, setLoading] = useState(false)
  const [qtdCaracteres] = useState(isDisabled ? 150 : 50)

  const { errors, watch, control, register, handleSubmit, setValue } = useForm<IObservacaoForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      motivo: ''
    }
  })

  const command = watch()
  function obterText(isDisabled: boolean) {
    if (!!isDisabled) {
      return 'Estamos desativando a proposta.'
    }

    return 'Estamos estornando a proposta.'
  }

  const onSubmit = async (data: IObservacaoForm) => {
    setLoading(true)
    Swal.fire({
      icon: 'info',
      title: 'Por favor, aguarde.',
      text: obterText(isDisabled),
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      var command: AlterarStatusPropostaCommand = {
        idProposta: proposta.id,
        observacao: data.motivo
      }

      if (isDisabled === true) {
        command.status = StatusProposta.DESATIVADA.Id
      }

      if (proposta?.status === StatusProposta.ENCERRADA.Id) {
        await alterarStatusPropostaEncerrada(command)
      } else {
        await alterarStatusProposta(command)
      }

      setLoading(false)
      handleOnClose(true)
    } catch (e) {
      setLoading(false)
      showNotificationErrorAPI(e)
      handleOnClose()
    } finally {
      // Fecha a modal de carregamento
      Swal.close();
    }
  }

  useEffect(() => {
    register('motivo')
  }, [register])

  return (
    <Drawer
      anchor="right"
      open={open}
      titulo={
        isDisabled ? 'Desativar a proposta' : 'Estornar a proposta'
      }
      handleOnClose={() => handleOnClose(true)}>
      <Box mt={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UBox>
            <TextField
              erro={errors}
              control={control}
              name="motivo"
              multiline={true}
              rows={4}
              value={command.motivo}
              defaultValue={command.motivo}
              label={
                isDisabled ? 'Motivo da desativação' : 'Motivo do estorno'
              }
              inputProps={{ maxLength: 500 }}
              InputLabelProps={{
                shrink: true
              }}
            />
            {qtdCaracteres <= 10 ? (
              <Typography variant="subtitle2" component="h3" color="error">
                {qtdCaracteres - command.motivo.length} caracteres restantes
              </Typography>
            ) : (
              <Typography variant="subtitle2" component="h3" color="primary">
                {qtdCaracteres - command.motivo.length} caracteres restantes
              </Typography>
            )}
          </UBox>
          <Box mt={1}>
            {proposta?.status !== StatusProposta.ENCERRADA.Id &&
              isDisabled === false && (
                <Alert severity="info">
                  {ehAguardandoRespostaPaciente ? (
                    <>
                      <Typography variant="subtitle2">
                        A proposta será estornada para o status Aguardando aprovação
                        médica.
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="subtitle2">
                      A proposta será estornada para o status Aguardando aprovação
                      paciente.
                    </Typography>
                  )}
                </Alert>
              )}

            {isDisabled === true && (
              <Alert severity="warning">
                <Typography variant="subtitle2">
                  A proposta será desativada, e não podera ser utilizada
                  posteriormente.
                </Typography>
              </Alert>
            )}
          </Box>
          <Box mt={3}>
            <Button
              fullWidth
              type="submit"
              loading={loading}
              disabled={loading}
              secondaryLoading
            >
              {isDisabled ? 'Desativar' : 'Salvar'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default DrawerAlterarStatusProposta;
