import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthContext, { AuthProvider } from './contexts/Auth'

import Routes from './router/routes'
import GlobalStyle from './styles/global'
import synTheme from './styles/synTheme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ModalAtualizacoesProvider from './contexts/ModalAtualizacoes'
import ModalAtualizacoes from 'src/components/modals/ModalAtualizacoes'
import ModalTutorial from 'src/components/modals/ModalTutorial'
import MobileContextProvider, { MobileContext } from 'src/contexts/Mobile'
import UltimaSolicitacaoProvider from 'src/contexts/UltimaSolicitacao'
import AtividadeProvider from 'src/contexts/Atividade/provider'
import PagamentoProvider from './contexts/Pagamento/provider'
import ModalPacotesSugeridos from './components/modals/ModalPacotesSugeridos'
import PendenciasProvider from './contexts/Pendencias/provider'

function App() {
  return (
    <ThemeProvider theme={synTheme}>
      <CssBaseline />
      <Router>
        <ModalAtualizacoesProvider>
          <AuthProvider>
            <AuthContext.Consumer>
              {({ signed }) => (
                <>
                  <AtividadeProvider>
                    <PendenciasProvider>
                      <PagamentoProvider>
                        <UltimaSolicitacaoProvider>
                          <MobileContextProvider>
                            <MobileContext.Consumer>
                              {({ autocompleteMobileOpen }) => (
                                <>
                                  <Routes />

                                  <GlobalStyle
                                    autocompleteMobileOpen={
                                      autocompleteMobileOpen
                                    }
                                  />
                                  {signed && (
                                    <ToastContainer
                                      position="top-right"
                                      autoClose={5000}
                                      hideProgressBar={false}
                                      newestOnTop={false}
                                      closeOnClick
                                      rtl={false}
                                      pauseOnFocusLoss
                                      draggable
                                      pauseOnHover
                                    />
                                  )}

                                  <ModalPacotesSugeridos />
                                  <ModalAtualizacoes />
                                  <ModalTutorial />
                                </>
                              )}
                            </MobileContext.Consumer>
                          </MobileContextProvider>
                        </UltimaSolicitacaoProvider>
                      </PagamentoProvider>
                    </PendenciasProvider>
                  </AtividadeProvider>
                </>
              )}
            </AuthContext.Consumer>
          </AuthProvider>
        </ModalAtualizacoesProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
