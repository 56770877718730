import { createContext } from 'react'

export interface IPendenciasContext {
  qtdPendencias: number
  atualizarQtdPendencias: () => void
  atualizarHubConnection: () => void
}

const AuthContext = createContext<IPendenciasContext>({} as any)

export default AuthContext
