import {
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useContext, useEffect, useState } from 'react'
import { GoPackage } from 'react-icons/go'
import { useHistory, useLocation } from 'react-router-dom'
import { MobileContext } from 'src/contexts/Mobile'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAuth from 'src/hooks/useAuth'
import MenuNotificacao from '../notificacoes/menu'
import Ubox from './Ubox'

const drawerWidth = 280
const drawerWidthDesktop = 280

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    toolbar: {},
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    boxBotoes: {
      '-webkit-justify-content': 'flex-end'
    },
    title: {
      flexGrow: 1,
      fontWeight: 500
    },
    content: {
      color: '#455880',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      borderImage:
        'linear-gradient(to right, #fff -5%,#6c7da1, rgb(44 235 199),#fff) 1 1 100%;',
      // 'linear-gradient(to right, #6c7da1, rgb(44 235 199),#fff) 1 1 100%',
      // 'linear-gradient(to right, #455880, rgb(44 235 199)) 1 1 100%;',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderTop: '0px !important',
      backdropFilter: 'blur(20px)',
      // border: 0,
      // borderBottom: '1px solid #eee !important',
      [theme.breakpoints.up('md')]: {
        // borderWidth: '3px',
        width: `calc(100% - ${drawerWidthDesktop}px)`,
        marginLeft: drawerWidthDesktop
      }
    },
    contentXS: {
      display: 'none'
    },
    mainContent: {
      [theme.breakpoints.up('md')]: {
        borderImage:
          'linear-gradient(to left, #6c7da1, rgb(44 235 199)) 1 1 100%',
        width: `calc(100% - ${drawerWidthDesktop}px)`,
        marginLeft: drawerWidthDesktop
      }
    },
    boxContent: {
      marginTop: '0 !important'
      // padding: `0 ${theme.spacing(2)}px 0`
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth,
      // backgroundImage: 'url(/background/back-2.jpg)',
      [theme.breakpoints.up('md')]: {
        width: drawerWidthDesktop,
        flexShrink: 0
      }
    }
  })
)

interface SynLayout {
  children: React.ReactNode
  titulo: string
  menuLateralCabecalho?: React.ReactNode
  menuLateral?: React.ReactNode
  menuTopo?: React.ReactNode
}

export default function MenuAppBar({
  children,
  titulo = '',
  menuTopo,
  menuLateral,
  menuLateralCabecalho
}: SynLayout) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = useStyles()
  const location = useLocation()

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [nomeUsuario, setNomeUsuario] = useState([])
  const [esconderBotoes, setEsconderBotoes] = React.useState<boolean>(true)

  const {
    signOut,
    user,
    perfilAtual,
    ehTenantSyn,
    obterTenantAtual,
    tenantAtual
  } = useAuth()

  const { autocompleteMobileOpen } = useContext(MobileContext)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const history = useHistory()

  const container =
    window !== undefined ? () => window.document.body : undefined

  function handleNovoPacote() {
    history.push(SynRoutes.formPacote)
  }

  function handleNovoOrcamento() {
    if (perfilAtual === SynPerfis.MEDICO) {
      if (ehTenantSyn) {
        history.push(SynRoutes.formOrcamentoUnificado)
      } else {
        history.push(SynRoutes.formOrcamentoUnificado2)
      }
    } else {
      history.push(SynRoutes.medicoAssistente)
    }
  }

  function verificarRota() {
    const rotasProibidas = [
      SynRoutes.formOrcamento,
      SynRoutes.formOrcamentoUnificado,
      SynRoutes.formOrcamentoUnificado2,
      SynRoutes.formPacote,
      SynRoutes.formConfiguracaoPacoteInteligente,
      SynRoutes.formOrcamentoInteligente,
      SynRoutes.listagemFinanciamentos,
      SynRoutes.medicoAssistente
    ]
    if (perfilAtual === SynPerfis.MEDICO) {
      rotasProibidas.push(SynRoutes.home)
    }
    let ehRotaProibida = rotasProibidas.includes(location.pathname)
    setEsconderBotoes(ehRotaProibida)
  }

  useEffect(() => {
    verificarRota()
  }, [location.pathname])
  useEffect(() => {
    setNomeUsuario(user.perfisPorTenant.filter(p => p.tenantId === tenantAtual))
  }, [user])

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        // position="fixed"
        className={
          !autocompleteMobileOpen ? classes.content : classes.contentXS
        }
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="subtitle1" className={classes.title}>
            <Chip
              label={nomeUsuario[0]?.descricao}
              variant="default"
              size="small"
            />{' '}
            {!ehXS && titulo}
          </Typography>
          <Ubox>{menuTopo}</Ubox>
          <Ubox
            render={
              !ehXS &&
              !ehTenantSyn &&
              !esconderBotoes &&
              (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
                perfilAtual === SynPerfis.SECRETARIA ||
                perfilAtual === SynPerfis.MEDICO) &&
              !(location.pathname === SynRoutes.detalhesPropostasMedico)
            }
            pr={0}
            display="flex"
            className={classes.boxBotoes}
            justifyContent="end"
            gridGap={12}
          >
            <Ubox render={perfilAtual === SynPerfis.MEDICO && ehTenantSyn}>
              <Button
                size="medium"
                fullWidth={false}
                variant="contained"
                color="secondary"
                startIcon={<GoPackage />}
                onClick={handleNovoPacote}
              >
                Novo pacote
              </Button>
            </Ubox>
            <Ubox
              render={
                (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                  perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
                  perfilAtual === SynPerfis.SECRETARIA ||
                  perfilAtual === SynPerfis.MEDICO) &&
                !(
                  location.pathname === SynRoutes.formOrcamentoUnificado2 ||
                  location.pathname === SynRoutes.formPacote
                )
              }
            >
              <Button
                size="medium"
                fullWidth={false}
                variant="contained"
                color="secondary"
                startIcon={<AssignmentOutlinedIcon />}
                onClick={handleNovoOrcamento}
              >
                Solicitar cirurgia
              </Button>
            </Ubox>
          </Ubox>
          {!!perfilAtual && <MenuNotificacao />}
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {menuLateralCabecalho}
            <div onClick={handleDrawerToggle}>{menuLateral}</div>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
            style={{ backgroundImage: 'url(/background/back-2.jpg)' }}
            open
          >
            {menuLateralCabecalho}
            {menuLateral}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.mainContent}>
        <Box
          className={`fadeInTransition  ${classes.boxContent} ${!esconderBotoes ? 'syn-container' : ''
            } ${!autocompleteMobileOpen ? '' : 'no-padding'}`}
        >
          <Ubox
            render={
              ehXS &&
              !ehTenantSyn &&
              !esconderBotoes &&
              (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
                perfilAtual === SynPerfis.SECRETARIA ||
                perfilAtual === SynPerfis.MEDICO) &&
              !(location.pathname === SynRoutes.detalhesPropostasMedico)
            }
            pb={4}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Ubox render={perfilAtual === SynPerfis.MEDICO && ehTenantSyn}>
                  <Button
                    size="small"
                    fullWidth={true}
                    variant="contained"
                    color="secondary"
                    startIcon={<GoPackage />}
                    onClick={handleNovoPacote}
                  >
                    Novo Pacote
                  </Button>
                </Ubox>
              </Grid>

              <Grid item xs={!ehTenantSyn ? 12 : 6}>
                <Button
                  size="small"
                  fullWidth={true}
                  variant="contained"
                  color="secondary"
                  startIcon={<AssignmentOutlinedIcon />}
                  onClick={handleNovoOrcamento}
                >
                  Nova Cirurgia
                </Button>
              </Grid>
            </Grid>
          </Ubox>

          {children}
        </Box>
      </main>
    </div>
  )
}
