import amber from '@material-ui/core/colors/amber'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import { blue, grey, purple, yellow } from '@material-ui/core/colors'

export const statusOrcamento = {
  SOLICITACAO_RECEBIDA: {
    Id: '5de07207-f8c8-4261-8b63-f5daae8827fe',
    Operacional: {
      Descricao: 'Solicitação recebida',
      Color: '#fff200',
      BackgroundColor: '#eb3b5a'
    },
    Parceiro: {
      Descricao: 'Solicitação recebida',
      Color: '#fff200',
      BackgroundColor: '#eb3b5a'
    },
    Paciente: {
      Descricao: 'Solicitação recebida',
      Color: '#fff200',
      BackgroundColor: '#eb3b5a'
    }
  },
  EM_ANDAMENTO: {
    Id: '01178ddd-e05e-4c1a-9824-cfd4d4417823',
    Operacional: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    },
    Parceiro: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    },
    Paciente: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    }
  },
  EM_REVISAO: {
    Id: 'c1d63f31-457b-428d-a761-7cb621bb9378',
    Operacional: {
      Descricao: 'Orçamento em revisão',
      Color: '#fff',
      BackgroundColor: '#beae25'
    },
    Parceiro: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    },
    Paciente: {
      Descricao: 'Orçamento em revisão',
      Color: '#fff',
      BackgroundColor: '#beae25'
    }
  },
  AGUARDANDO_RESPOSTA_PACIENTE: {
    Id: '60315da5-e574-4b09-9430-73083bf5bed2',
    Operacional: {
      Descricao: 'Aguardando resposta paciente',
      Color: '#fff',
      BackgroundColor: '#EA3F3F'
    },
    Parceiro: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    },
    Paciente: {
      Descricao: 'Aguardando aprovação Paciente',
      Color: '#fff',
      BackgroundColor: '#EA3F3F'
    }
  },
  EM_AGENDAMENTO: {
    Id: '214d12dc-8c14-4753-a157-a7aff9cd9a4d',
    Operacional: {
      Descricao: 'Agendamento autorizado',
      Color: '#fff',
      BackgroundColor: '#45aaf2'
    },
    Parceiro: {
      Descricao: 'Agendamento autorizado',
      Color: '#fff',
      BackgroundColor: '#45aaf2'
    },
    Paciente: {
      Descricao: 'Agendamento autorizado',
      Color: '#fff',
      BackgroundColor: '#45aaf2'
    }
  },
  AGENDADO: {
    Id: '87ed8fc2-0b90-4e09-b7cc-10a3fbcc67d5',
    Operacional: {
      Descricao: 'Agendado',
      Color: '#fff',
      BackgroundColor: '#f0f'
    },
    Parceiro: {
      Descricao: 'Agendado',
      Color: '#fff',
      BackgroundColor: '#f0f'
    },
    ParceiroRecusado: {
      Descricao: 'Orçamento finalizado',
      Color: '#fff',
      BackgroundColor: '#3867d6',
      EsconderVerOrcamento: true
    },
    Paciente: {
      Descricao: 'Agendado',
      Color: '#fff',
      BackgroundColor: '#f0f'
    }
  },
  PENDENTE_REVISAO: {
    Id: '9309ffd6-a1e0-49ee-8cf4-59bc422c3a38',
    Operacional: {
      Descricao: 'Pendente de revisão de valores',
      Color: '#fff',
      BackgroundColor: '#660099'
    },
    Parceiro: {
      Descricao: 'Pendente de revisão de valores',
      Color: '#fff',
      BackgroundColor: '#660099'
    },
    Paciente: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    }
  },
  REALIZADO: {
    Id: '189bfec6-c5e5-43c1-b928-5e91347161e2',
    Operacional: {
      Descricao: 'Procedimento realizado',
      Color: '#fff',
      BackgroundColor: green[400]
    },
    Parceiro: {
      Descricao: 'Procedimento realizado',
      Color: '#fff',
      BackgroundColor: green[400]
    },
    ParceiroRecusado: {
      Descricao: 'Orçamento finalizado',
      Color: '#fff',
      BackgroundColor: '#3867d6',
      EsconderVerOrcamento: true
    },
    Paciente: {
      Descricao: 'Procedimento realizado',
      Color: '#fff',
      BackgroundColor: green[400]
    }
  },
  AGUARDANDO_APROVACAO_MEDICO: {
    Id: '17872eea-5530-4b03-b9e5-0dd56bbf0f1d',
    Operacional: {
      Descricao: 'Aguardando aprovação do médico',
      Color: '#fff',
      BackgroundColor: purple[500]
    },
    Parceiro: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    },
    Paciente: {
      Descricao: 'Aguardando aprovação do médico',
      Color: '#fff',
      BackgroundColor: purple[500]
    }
  },
  AGUARDANDO_CONFIRMACAO_PAGAMENTO: {
    Id: 'dd90cfa0-1e0a-4801-85df-e7c17d702ef0',
    Operacional: {
      Descricao: 'Aguardando confirmação de pagamento',
      Color: '#fff',
      BackgroundColor: yellow[700]
    },
    Parceiro: {
      Descricao: 'Aguardando confirmação de pagamento',
      Color: '#fff',
      BackgroundColor: yellow[700]
    },
    ParceiroRecusado: {
      Descricao: 'Orçamento finalizado',
      Color: '#fff',
      BackgroundColor: '#3867d6',
      EsconderVerOrcamento: true
    },
    Paciente: {
      Descricao: 'Aguardando confirmação de pagamento',
      Color: '#fff',
      BackgroundColor: yellow[700]
    }
  },
  PAGAMENTO_PARCIAL: {
    Id: '038f7b46-b180-4489-b22d-d68a5fe4ab72',
    Operacional: {
      Descricao: 'Pagamento parcial',
      Color: '#fff',
      BackgroundColor: yellow[700]
    },
    // Parceiro: {
    //   Descricao: 'Pagamento parcial',
    //   Color: '#fff',
    //   BackgroundColor: yellow[700]
    // },
    Parceiro: {
      Descricao: 'Aguardando confirmação de pagamento',
      Color: '#fff',
      BackgroundColor: yellow[700]
    },
    ParceiroRecusado: {
      Descricao: 'Orçamento finalizado',
      Color: '#fff',
      BackgroundColor: '#3867d6',
      EsconderVerOrcamento: true
    },
    Paciente: {
      Descricao: 'Aguardando confirmação de pagamento',
      Color: '#fff',
      BackgroundColor: yellow[700]
    }
  },
  PROCESSO_CANCELADO: {
    Id: 'c0bcb9ed-0d2a-489b-a524-eaa746464e8a',
    Operacional: {
      Descricao: 'Processo cancelado',
      Color: '#fff',
      BackgroundColor: grey[800]
    },
    Parceiro: {
      Descricao: 'Processo cancelado',
      Color: '#fff',
      BackgroundColor: grey[800]
    },
    Paciente: {
      Descricao: 'Processo cancelado',
      Color: '#fff',
      BackgroundColor: grey[800]
    }
  },
  PAGAMENTO_CONFIRMADO: {
    Id: 'd1a69425-5ffc-4169-aa0d-0f804044ca20',
    Operacional: {
      Descricao: 'Pagamento confirmado',
      Color: '#fff',
      BackgroundColor: green[800]
    },
    Parceiro: {
      Descricao: 'Agendamento autorizado',
      Color: '#fff',
      BackgroundColor: '#45aaf2',
      EsconderVerOrcamento: false
    },
    ParceiroRecusado: {
      Descricao: 'Orçamento finalizado',
      Color: '#fff',
      BackgroundColor: '#3867d6',
      EsconderVerOrcamento: true
    },
    Paciente: {
      Descricao: 'Agendamento autorizado',
      Color: '#fff',
      BackgroundColor: '#45aaf2'
      // Descricao: 'Pagamento confirmado',
      // Color: '#fff',
      // BackgroundColor: green[800]
    }
  },
  PROPOSTA_APROVADA_MEDICO: {
    Id: 'd647171a-02c9-4ca6-8f4c-98d38547ffcd',
    Operacional: {
      Descricao: 'Proposta(s) aprovada(s) pelo médico',
      Color: '#fff',
      BackgroundColor: red[800]
    },
    Parceiro: {
      Descricao: 'Orçamento em andamento',
      Color: '#fff',
      BackgroundColor: '#e67e22'
    },
    Paciente: {
      Descricao: 'Proposta(s) aprovada(s) pelo médico',
      Color: '#fff',
      BackgroundColor: red[800]
      //BackgroundColor: '#e67e22'
    }
  },
  REVISAR_LIBERAR_COTACAO: {
    Id: '7e109120-1b96-4f54-8b3f-619ac4d1e63e',
    Operacional: {
      Descricao: 'Revisar e liberar para cotação',
      Color: '#fff',
      BackgroundColor: red[800]
    },
    Parceiro: {
      Descricao: 'Revisar e liberar para cotação',
      Color: '#fff',
      BackgroundColor: red[800]
    },
    Paciente: {
      Descricao: 'Solicitação recebida',
      Color: '#fff200',
      BackgroundColor: '#eb3b5a'
    },
    Medico: {
      Descricao: 'Solicitação recebida',
      Color: '#fff200',
      BackgroundColor: '#eb3b5a'
    }
  }
}

export const StatusFiltrosAgendamento = {
  PRE_AGENDAMENTO_AUTORIZADO: {
    id: '1',
    descricao: 'Pré agendamento autorizado'
  },
  PRE_AGENDADO: {
    id: '2',
    descricao: 'Pré agendado'
  },
  EM_AGENDAMENTO: {
    id: '3',
    descricao: 'Agendamento autorizado'
  },
  AGENDADO: {
    id: '4',
    descricao: 'Agendado'
  },
  REALIZADO: {
    id: '5',
    descricao: 'Procedimento realizado'
  }
}

export const StatusFiltrosAgendamentoArray = [
  {
    id: 1,
    descricao: 'Pré agendamento autorizado'
  },
  {
    id: 2,
    descricao: 'Pré agendado'
  },
  {
    id: 3,
    descricao: 'Agendamento autorizado'
  },
  {
    id: 4,
    descricao: 'Agendado'
  },
  {
    id: 5,
    descricao: 'Procedimento realizado'
  }
]

interface Props {
  statusId?: string
  perfil:
  | 'parceiro'
  | 'operacional'
  | 'paciente'
  | 'medico'
  | 'comercial'
  | 'secretaria'
  orcamentoAprovado?: boolean
  existePropostaAguardandoPaciente?: boolean
  ehAgendamento?: boolean
  preAgendamento?: boolean
}

export function obterDescricaoPorStatusId({
  statusId,
  perfil,
  orcamentoAprovado = false,
  existePropostaAguardandoPaciente = false,
  ehAgendamento = false,
  preAgendamento = false
}: Props) {
  var status: any = Object.values(statusOrcamento).find(
    item => item.Id === statusId
  )
  if (!status) {
    return 'Sem status'
  }

  switch (perfil) {
    case 'parceiro':
      if (
        ehAgendamento &&
        (statusId === statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ||
          statusId === statusOrcamento.PAGAMENTO_PARCIAL.Id)
      ) {
        if (preAgendamento) {
          return 'Pré agendado'
        } else {
          return 'Pré agendamento autorizado'
        }
      }
      if (
        !orcamentoAprovado &&
        (statusId === statusOrcamento.PAGAMENTO_CONFIRMADO.Id ||
          statusId === statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ||
          statusId === statusOrcamento.PAGAMENTO_PARCIAL.Id ||
          statusId === statusOrcamento.AGENDADO.Id ||
          statusId === statusOrcamento.REALIZADO.Id)
      ) {
        return status.ParceiroRecusado.Descricao
      }
      return status.Parceiro.Descricao
    case 'operacional':
      return status.Operacional.Descricao
    case 'paciente':
      if (existePropostaAguardandoPaciente) {
        return statusOrcamento.AGUARDANDO_RESPOSTA_PACIENTE.Paciente.Descricao
      }
      return status.Paciente.Descricao
    case 'medico':
      return status.Medico
        ? status.Medico.Descricao
        : status.Operacional.Descricao
    default:
      return status.Operacional.Descricao
  }
}

export function obterCorPorStatusId({
  statusId,
  perfil,
  orcamentoAprovado = false,
  ehAgendamento = false,
  preAgendamento = false
}: Props) {
  var status: any = Object.values(statusOrcamento).find(
    item => item.Id === statusId
  )
  if (!status) {
    return { color: '#fff', backgroundColor: '#bbb' }
  }

  if (
    ehAgendamento &&
    (statusId === statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ||
      statusId === statusOrcamento.PAGAMENTO_PARCIAL.Id)
  ) {
    if (preAgendamento) {
      return {
        color: statusOrcamento.REALIZADO.Operacional.Color,
        backgroundColor: statusOrcamento.REALIZADO.Operacional.BackgroundColor
      }
    } else {
      return {
        color: statusOrcamento.EM_ANDAMENTO.Operacional.Color,
        backgroundColor:
          statusOrcamento.EM_ANDAMENTO.Operacional.BackgroundColor
      }
    }
  }
  switch (perfil) {
    case 'parceiro':
      if (
        !orcamentoAprovado &&
        (statusId === statusOrcamento.PAGAMENTO_CONFIRMADO.Id ||
          statusId === statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ||
          statusId === statusOrcamento.PAGAMENTO_PARCIAL.Id ||
          statusId === statusOrcamento.AGENDADO.Id ||
          statusId === statusOrcamento.REALIZADO.Id)
      ) {
        return {
          color: status.ParceiroRecusado.Color,
          backgroundColor: status.ParceiroRecusado.BackgroundColor
        }
      }

      return {
        color: status.Parceiro.Color,
        backgroundColor: status.Parceiro.BackgroundColor
      }
    case 'operacional':
      return {
        color: status.Operacional.Color,
        backgroundColor: status.Operacional.BackgroundColor
      }
    case 'paciente':
      return {
        color: status.Paciente.Color,
        backgroundColor: status.Paciente.BackgroundColor
      }
    case 'medico':
      return status.Medico
        ? {
            color: status.Medico.Color,
            backgroundColor: status.Medico.BackgroundColor
          }
        : {
            color: status.Operacional.Color,
            backgroundColor: status.Operacional.BackgroundColor
          }
    default:
      return {
        color: status.Operacional.Color,
        backgroundColor: status.Operacional.BackgroundColor
      }
  }
}

export function esconderFooterParceiro(statusId, orcamentoAprovado) {
  var status: any = Object.values(statusOrcamento).find(
    item => item.Id === statusId
  )
  if (!status) {
    return false
  }

  if (
    !orcamentoAprovado &&
    (statusId === statusOrcamento.PAGAMENTO_CONFIRMADO.Id ||
      statusId === statusOrcamento.AGENDADO.Id)
  ) {
    return status.ParceiroRecusado.EsconderVerOrcamento
  } else if (statusId === statusOrcamento.PROCESSO_CANCELADO.Id) {
    return true
  }

  return false
}
