import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  listagemOrcamentoPaciente,
  obterStatusSolicitacaoOrcamento
} from 'src/api/solicitacao-orcamento/index'
import useListagem from 'src/hooks/useListagem'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'

import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'

import {
  formatarData
} from 'src/core/formatacoes/formatarData'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { MdKeyboardArrowDown } from 'react-icons/md'
import ButtonUI from 'src/components/ui/Button'

import {
  ItemListagemSolicitacaoOrcamentoResponse,
  ListagemSolicitacoesPacienteQuery
} from '../../../types'
import DrawerVisualizarDetalhes from '../operacional/solicitacao/drawerVisualizarDetalhes'

import CardListagem from 'src/components/solicitacao/CardListagem'
import LoadingGeral from 'src/components/ui/LoadingGeral'

const data = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

export default function ListagemSolicitacoesPaciente() {
  const history = useHistory()

  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemSolicitacaoOrcamentoResponse>()
  const [mostrarDetalhes, setMostrarDetalhes] = useState(false)
  const [filtrarDependentes, setFiltrarDependentes] = useState(false)

  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const [dataInicioDirty, setDataInicioDirty] = useState(false)
  const [dataFimDirty, setDataFimDirty] = useState(false)
  const [diasFiltro, setDiasFiltro] = useState('periodo')

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDados,
    qtdAllDados,
    handleProxPagina,
    filtroGeral,
    allDadosCarregados,
    loadingPagina,
    carregarListagem,
    handleFiltroData
  } = useListagem(9, listagemSolicitacoesPac, obterStatusSolicitacaoOrcamento)

  function listagemSolicitacoesPac(query: ListagemSolicitacoesPacienteQuery) {
    query.FiltrarSolicitacaoComResponsavel = filtrarDependentes
    return listagemOrcamentoPaciente(query)
  }
  useWindowInfiniteScroll({
    data: allDados,
    loadMoreFunc: handleProxPagina,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  useEffect(() => {
    handleFiltroPorData()
  }, [diasFiltro])

  const { setUltimaSolicitacao, ultimaSolicitacaoVista } = useContext(
    UltimaSolicitacaoContext
  )
  const handleVisualizarDetalhes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setItemSelecionado(item)
    setMostrarDetalhes(true)
  }

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  const handleFiltroPorData = () => {
    if (diasFiltro === 'periodo') {
      limparFiltroData()
    } else if (diasFiltro === 'hoje') {
      CarregarDataAtualFiltro()
    } else if (diasFiltro === '3Dias') {
      CarregarDataPodDiaFiltro(3)
    } else if (diasFiltro === '7Dias') {
      CarregarDataPodDiaFiltro(7)
    } else if (diasFiltro === '15Dias') {
      CarregarDataPodDiaFiltro(15)
    } else if (diasFiltro === '30Dias') {
      CarregarDataPodDiaFiltro(30)
    }
  }

  const CarregarDataPodDiaFiltro = dias => {
    var dataFiltro = new Date()
    var day = dataFiltro.getDate().toString().padStart(2, '0')
    var year = dataFiltro.getFullYear()
    var month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    setDataFim(year + '-' + month + '-' + day)

    var dataFim = year + '-' + month + '-' + day

    dataFiltro.setDate(dataFiltro.getDate() - dias)
    year = dataFiltro.getFullYear()
    month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    var dayModificado = dataFiltro.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + dayModificado)

    handleFiltroData(year + '-' + month + '-' + dayModificado, dataFim)
  }

  const CarregarDataAtualFiltro = () => {
    var dt = new Date()
    var year = dt.getFullYear()
    var month = (dt.getMonth() + 1).toString().padStart(2, '0')
    var day = dt.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + day)
    setDataFim(year + '-' + month + '-' + day)
    setDiasFiltro('hoje')

    handleFiltroData(
      year + '-' + month + '-' + day,
      year + '-' + month + '-' + day
    )
  }

  const handleSetDiaFiltro = e => {
    var valor = e.target.value
    setDiasFiltro(valor)
  }

  const limparFiltroData = () => {
    setDiasFiltro('periodo')
    setDataInicio('')
    setDataFim('')
    handleFiltroData('', '')
  }

  useEffect(() => {
    //Para atualizar a página
    setTimeout(
      () => carregarListagem({ resetarPaginacao: true, filtro: filtroGeral }),
      300
    )
  }, [filtrarDependentes])

  const classes = data()

  return (
    <Box>
      <Box mb={2}>
        {/* Paciente*/}
        <Typography variant="h5">Solicitações de orçamentos</Typography>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Pesquise pelo código, nome do médico ou dependente..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={termoPesquisa}
              onChange={handleTermoPesquisa}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filtrarDependentes}
                  onChange={() => setFiltrarDependentes(!filtrarDependentes)}
                  color="primary"
                />
              }
              label="Solicitações de meus dependentes"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                value={diasFiltro}
                onChange={e => {
                  handleSetDiaFiltro(e)
                }}
              >
                <MenuItem value="periodo">Período</MenuItem>
                <MenuItem value="hoje">Hoje</MenuItem>
                <MenuItem key="2" value="3Dias">
                  Últimos 3 dias
                </MenuItem>
                <MenuItem key="3" value="7Dias">
                  Últimos 7 dias
                </MenuItem>
                <MenuItem key="4" value="15Dias">
                  Últimos 15 dias
                </MenuItem>
                <MenuItem key="5" value="30Dias">
                  Últimos 30 dias
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="dataInicio"
              error={dataInicioDirty && !formatarData(dataInicio)}
              helperText={
                dataInicioDirty && !formatarData(dataInicio)
                  ? 'Data inválida'
                  : ''
              }
              label="De"
              type="date"
              defaultValue={dataInicio}
              value={dataInicio}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              onKeyDown={e => {
                setDataInicioDirty(true)
              }}
              onChange={e => {
                setDataInicio(e.target.value)
              }}
              onBlur={() => handleFiltroData(dataInicio, dataFim)}
            />

            <TextField
              error={dataFimDirty && !formatarData(dataFim)}
              helperText={
                dataFimDirty && !formatarData(dataFim) ? 'Data inválida' : ''
              }
              id="dataFim"
              label="Até"
              type="date"
              defaultValue={dataFim}
              value={dataFim}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              onKeyDown={e => {
                setDataFimDirty(true)
              }}
              onChange={e => {
                setDataFim(e.target.value)
              }}
              onBlur={() => handleFiltroData(dataInicio, dataFim)}
            />
          </Grid>
        </Grid>
      </Box>
      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {!!ultimaSolicitacaoVista
                  ? 'Última solicitação vista'
                  : qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} solicitações`
                    : '1 solicitação'
                  : '0 Solicitações'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!!ultimaSolicitacaoVista && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUltimaSolicitacao()}
                >
                  Mostrar todas as solicitações
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      {nenhumDado && allDados.length === 0 && (
        <div>Não há solicitações no momento.</div>
      )}
      {nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}

      {allDadosCarregados && !loadingPagina && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {ultimaSolicitacaoVista !== undefined && (
              <Grid item xs={12} md={6} lg={4} key={ultimaSolicitacaoVista.id}>
                <CardListagem
                  perfil="paciente"
                  setUltimaSolicitacao={setUltimaSolicitacao}
                  handleVisualizarDetalhes={handleVisualizarDetalhes}
                  navigate={history.push}
                  setClassToHighlight={setClassToHighlight}
                  solicitacao={ultimaSolicitacaoVista}
                />
              </Grid>
            )}
            {allDados.map(item => {
              if (ultimaSolicitacaoVista === undefined) {
                return (
                  <Grid item xs={12} md={6} lg={4} key={item.id}>
                    <CardListagem
                      perfil="paciente"
                      setUltimaSolicitacao={setUltimaSolicitacao}
                      handleVisualizarDetalhes={handleVisualizarDetalhes}
                      navigate={history.push}
                      setClassToHighlight={setClassToHighlight}
                      solicitacao={item}
                    />
                  </Grid>
                )
              } else {
                return <></>
              }
            })}
          </Grid>
        </div>
      )}

      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>
      <DrawerVisualizarDetalhes
        id={itemSelecionado?.id}
        open={mostrarDetalhes}
        handleOnClose={() => setMostrarDetalhes(false)}
        dadosSensiveis
      />
    </Box>
  )
}
